
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import ModalAddMember from "@/components/modalAddMember/index.vue";
import CollaboratorsTable from "@/components/collaboratorsTable/index.vue";

const InternalsTable = defineComponent({
  name: "Internals Table",
  components: {
    ModalAddMember,
    CollaboratorsTable,
  },
  props: {
    visible: Boolean,
  },
  setup() {
    const openModal = ref(false);
    return {
      openModal,
    };
  },
});
export default InternalsTable;
