
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import Header from "@/components/header/index.vue";
import HiringFees from "@/components/hiringFees/index.vue";

const HiringFeesComponent = defineComponent({
  name: "HiringFeesComponent",
  components: {
    Header,
    HiringFees,
  },
  setup() {
    const isVisible = ref(false);

    isVisible.value = true;

    return {
      isVisible,
    };
  },
});

export default HiringFeesComponent;
