<template>
  <form @submit="onSubmit">
    <div
      class="bg-white shadow w-full rounded-2xl px-8 py-3 justify-between items-start h-full"
    >
      <div class="sm:flex flex-row justify-end space-x-5">
        <div
          class="px-5 py-3 rounded-full bg-primary-light flex flew-row items-center justify-between w-full"
        >
          <span class="text-sm font-semibold"
            >You're now editing this candidate profile</span
          >
          <div class="flex space-x-3">
            <button
              v-on:click="closeEdit"
              class="border-primary border-2 px-5 py-2 text-primary rounded-full"
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              v-on:click="onSubmit"
              :disabled="!meta.valid"
              class="bg-primary px-5 py-2 text-white rounded-full hover:bg-primary-dark"
            >
              <span class="text-sm"> Done editing </span>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center py-3">
        <div class="flex-grow h-0.5 bg-gray-300 mx-4"></div>
      </div>
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="pb-3">
            <div
              class="flex justify-center items-center rounded-full bg-primary text-white text-4xl w-20 h-20 p-4"
            >
              {{
                getInitials(
                  `${userInfo.candidate.first_name} ${userInfo.candidate.last_name}`
                )
              }}
            </div>
          </div>
          <div class="block">
            <div class="flex flex-row px-3 py-1 space-x-5 w-full">
              <TextField
                v-for="(item, index) in buildNamesFields"
                :key="index"
                v-model="item.model"
                :label="item.label"
                typeInput="text"
                :name="item.name"
              />
            </div>
            <div class="flex flex-row px-3 py-3 space-x-4 w-full">
              <div class="w-11/12">
                <CurrencyField
                  v-model="salaryExpectation"
                  label="Salary expectation"
                  name="salary_expectation"
                />
              </div>
              <div class="w-full">
                <DropDown
                  :options="optionCurrencies"
                  v-model="currencySalary"
                  label="Select the currency..."
                  name="salary_currency"
                />
              </div>
            </div>
            <div class="flex flex-row px-3 py-3 space-x-4 w-full">
              <div class="w-11/12">
                <CurrencyField
                  v-model="currentSalary"
                  label="Current salary"
                  name="current_salary"
                />
              </div>
              <div class="w-full">
                <DropDown
                  :options="optionCurrencies"
                  v-model="currencyCurrentSalary"
                  label="Select the currency..."
                  name="current_salary_currency"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-2" v-if="false">
        <span class="block text-base font-bold py-3">Tags</span>
        <Chips :chips="tags" placeholder="Add tag" />
      </div>
      <div class="space-y-3 p-3 w-full">
        <span class="block text-2xl font-bold">Candidate Profile</span>
        <span class="block text-base font-bold pt-3">Summary</span>
        <div class="w-full">
          <TextArea
            v-model="summary"
            label="Write here"
            rows="3"
            className="pt-5 pb-2 pl-4 pr-10 block w-full px-0 mt-0 bg-transparent border-2 rounded-lg border-solid appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200"
            :isLabel="false"
            placeholder="Write here"
            name="summary"
          />
        </div>
        <span class="block text-base font-bold pt-5">Work experience</span>
        <WorkExperienceArrayField />
        <span class="block text-base font-bold pt-5">Education</span>
        <EducationArrayField />
        <span class="block text-base font-bold pt-5">Skills</span>
        <Chips :chips="skills" placeholder="Add skill" />
        <span class="block text-base font-bold pt-5">Contact details</span>
        <div v-for="(item, index) in buildTextFields" :key="index">
          <div class="flex flex-row w-full space-x-8">
            <span class="text-sm font-extralight text-gray-400 p-3 w-16">{{
              item.label
            }}</span>
            <div class="w-full">
              <TextField
                v-model="item.model"
                :label="item.label"
                :isLabel="false"
                placeholder="Write here"
                typeInput="text"
                className="pt-5 pb-2 pl-4 pr-10 block w-full px-0 mt-0 bg-transparent border-2 rounded-full border-solid appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200"
                :name="item.name"
              />
            </div>
          </div>
          <div class="flex flex-row items-center py-2">
            <div class="flex-grow h-0.5 bg-gray-200"></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import * as yup from "yup";
import { useStore } from "vuex";
import { useForm, useField } from "vee-validate";
import { getInitials } from "../../utils/functions";
import TextField from "../textField/index.vue";
import Chips from "../chips/index.vue";
import TextArea from "../textArea/index.vue";
import WorkExperienceArrayField from "../workExperienceFieldArray/index.vue";
import EducationArrayField from "../educationFieldArray/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import { useRoute } from "vue-router";
import CurrencyField from "../currencyField/index.vue";
import DropDown from "../selectDropdown/index.vue";
import { createToastAxelerate } from "../../utils/toast";

const EditCandidateForm = defineComponent({
  name: "EditCandidateForm",
  emits: ["closeEdit", "reloadCandidate"],
  components: {
    CurrencyField,
    DropDown,
    TextField,
    Chips,
    TextArea,
    WorkExperienceArrayField,
    EducationArrayField,
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const userInfo = computed(() => store.state.candidateInfo.data);
    const skills = ref(
      userInfo.value &&
        userInfo.value.candidate &&
        userInfo.value.candidate.skills &&
        userInfo.value.candidate.skills.length > 0
        ? userInfo.value.candidate.skills
        : []
    );
    const tags = ref([]);
    const file = ref("");
    const uploadInfo = computed(() => {
      return file.value && file.value?.name;
    });
    const schema = yup.object({
      first_name: yup.string().label("First name").nullable(),
      last_name: yup.string().label("Last name").nullable(),
      address: yup.string().label("Address").nullable(),
      summary: yup.string().label("Summary").nullable(),
      phone: yup.string().label("Phone").nullable(),
      email: yup.string().label("Email").nullable(),
      salary_currency: yup.string().nullable().label("Currency"),
      candidate_work_histories: yup.array().of(
        yup.object().shape({
          start_date: yup.date().nullable().label("Start date"),
          end_date: yup.date().nullable().label("End date"),
          job_title: yup.string().nullable().label("Job title"),
          company_name: yup.string().label("Company").nullable(),
          is_current: yup.boolean().nullable(),
        })
      ),
      candidate_education_histories: yup.array().of(
        yup.object().shape({
          start_date: yup.date().nullable().label("Start date").nullable(),
          end_date: yup.date().nullable().label("End date"),
          learning_center: yup.string().nullable().label("School"),
          degree: yup.string().nullable().label("Education"),
          is_current: yup.boolean().nullable(),
        })
      ),
    });
    const optionCurrencies = [
      { id: "USD", name: "Dollar (USD)" },
      { id: "MXN", name: "Mexican Peso (MXN)" },
    ];
    const { value: salaryExpectation } = useField(
      "salary_expectation",
      yup.string().required().label("Salary expectation").nullable(),
      {
        initialValue: userInfo.value?.candidate?.salary_expectation,
      }
    );
    const { value: currentSalary } = useField(
      "current_salary",
      yup.string().required().label("Current").nullable(),
      {
        initialValue: userInfo.value?.candidate?.current_salary,
      }
    );
    const { value: firstName } = useField("first_name");
    const { value: currencySalary } = useField("salary_currency");
    const { value: currencyCurrentSalary } = useField(
      "current_salary_currency"
    );
    const { value: lastName } = useField("last_name");
    const { value: adress } = useField("address");
    const { value: phone } = useField("phone");
    const { value: email } = useField("email");
    const { value: summary } = useField("summary");
    const buildTextFields = ref([
      { label: "Address", model: adress, name: "address" },
      { label: "Phone", model: phone, name: "phone" },
      { label: "Personal email", model: email, name: "email" },
    ]);
    const buildNamesFields = ref([
      { label: "First name", model: firstName, name: "first_name" },
      { label: "Last name", model: lastName, name: "last_name" },
    ]);
    const { meta, handleSubmit, resetForm, errors } = useForm({
      validationSchema: schema,
      initialValues: {
        address: userInfo.value.candidate.address,
        salary_currency: userInfo.value?.candidate.salary_currency,
        current_salary_currency:
          userInfo.value?.candidate.current_salary_currency,
        phone: userInfo.value.candidate.phone,
        email: userInfo.value.candidate.email,
        summary: userInfo.value.candidate.summary,
        first_name: userInfo.value.candidate.first_name,
        last_name: userInfo.value.candidate.last_name,
        candidate_work_histories:
          userInfo.value.candidate.candidate_work_histories &&
          userInfo.value.candidate.candidate_work_histories.length > 0
            ? userInfo.value.candidate.candidate_work_histories
            : [],
        candidate_education_histories:
          userInfo.value.candidate.candidate_education_histories &&
          userInfo.value.candidate.candidate_education_histories.length > 0
            ? userInfo.value.candidate.candidate_education_histories
            : [],
      },
    });
    const resetAll = () => {
      resetForm();
    };
    const closeEdit = () => {
      context.emit("closeEdit");
    };
    const onSubmit = handleSubmit((values) => {
      const buildParams = {
        job_candidate: {
          ...values,
          salary_currency: values.salary_currency,
          current_salary_currency: values.current_salary_currency,
          salary_expectation: parseFloat(
            values.salary_expectation?.replace(/,/g, "") || ""
          ),
          current_salary: parseFloat(
            values.current_salary?.replace(/,/g, "") || ""
          ),
          skills: skills.value,
          tags: tags.value,
        },
      };
      JobsAPI.updateJobCandidate(
        route.params.id,
        userInfo.value?.id,
        buildParams
      )
        .then(async () => {
          await context.emit("reloadCandidate");
          await context.emit("closeEdit");
          createToastAxelerate(
            "Edit Candidate",
            "The candidate has been updated successfully.",
            "success"
          );
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Edit Candidate",
            errors && errors[0]
              ? errors[0]
              : "An unexpected error seems to have occurred.",
            "danger"
          );
        });
    });
    return {
      optionCurrencies,
      currentSalary,
      currencyCurrentSalary,
      salaryExpectation,
      currencySalary,
      getInitials,
      buildTextFields,
      buildNamesFields,
      onSubmit,
      skills,
      summary,
      meta,
      file,
      uploadInfo,
      resetAll,
      closeEdit,
      userInfo,
      tags,
      errors,
    };
  },
});
export default EditCandidateForm;
</script>

<style src="./styles.css" scoped></style>
