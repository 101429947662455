<template>
  <div class="w-full flex flex-col justify-center p-4">
    <div class="w-full">
      <span class="font-semibold text-base"> Edit job board header</span>
    </div>
    <form @submit="onSubmit">
      <div class="w-full mt-8">
        <span class="font-semibold text-base"> Logo</span>
        <!-- <div class="flex flex-row mt-1 space-x-8 items-center" >
          <span class="font-medium">Show company logo</span>
          <SwitchButtton v-model="show_logo"></SwitchButtton>
        </div> -->
        <div class="w-full mt-4">
          <span class="font-semibold text-base">Upload logo</span>
          <div class="mt-2">
            <UploadFileButton
              :url="company.logo?.url"
              v-model="logo"
              name="logo"
              placeholder="You can upload JPG, PNG or SVG. We recommend using 400x400px"
            ></UploadFileButton>
          </div>
        </div>
        <div class="w-full mt-4">
          <span class="font-semibold text-base">Background</span>
          <!-- <div>
            <RadioButton
              v-for="(item, index) in bgOptions"
              :key="`radio-${item}-${index}`"
              :label="item.name"
              :value="item.id"
              v-model="radioBg"
              :hasCounter="false"
            />
          </div> -->
          <!-- image as background-->
          <div class="mt-4 space-y-2 flex flex-col">
            <div class="w-full flex flex-col">
              <span class="font-semibold text-base">Upload background</span>
              <span class="text-sm text-gray-400"
                >Your backgroud image will scale based on the amount of content
                in your section and the user's device, For this reason, a
                landscape image with a width of 1920 pixels (1440 x 420px) is
                recomended.</span
              >

              <UploadFileButton
                :url="company.background?.url"
                v-model="background"
                name="background"
                height="h-96"
                width="w-full"
                placeholder="You can upload JPG, PNG or SVG. We recommend using 1500x500px"
              ></UploadFileButton>
            </div>
          </div>
          <!-- color as background-->
          <div
            class="mt-4 space-y-2 flex flex-col animate__animated animate__fadeIn animate__delay-0.4s"
          >
            <span class="font-semibold text-base">Select brand colors</span>
            <div class="flex flex-row justify-between items-center">
              <div class="w-full py-4 px-6">
                <span class="font-medium text-gray-500">Primary color:</span>

                <ChooseColorPicker
                  v-model="primary_color"
                  :color="primary_color"
                />
                <div class="flex flex-row space-x-1">
                  <input
                    class="rounded-lg h-14 p-3 w-10/12 bg-gray-200 font-semibold focus:outline-none focus:ring-2 focus:ring-gray-400"
                    v-model="primary_color"
                    name="color"
                    maxlength="7"
                    pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                  />
                  <div
                    class="w-2/12 h-14 p-3 rounded-lg"
                    :style="{ 'background-color': `${primary_color}` }"
                  ></div>
                </div>
              </div>
              <div class="w-full py-4 px-6">
                <span class="font-medium text-gray-500">Secondary color:</span>
                <ChooseColorPicker
                  v-model="secondary_color"
                  :color="secondary_color"
                />
                <div class="flex flex-row space-x-1">
                  <input
                    class="rounded-lg h-14 p-3 w-10/12 bg-gray-200 font-semibold focus:outline-none focus:ring-2 focus:ring-gray-400"
                    v-model="secondary_color"
                    name="color"
                    maxlength="7"
                    pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                  />
                  <div
                    class="w-2/12 h-14 p-3 rounded-lg"
                    :style="{ 'background-color': `${secondary_color}` }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 flex flex-col">
            <span class="font-semibold text-base">Description</span>
            <span class="text-base text-gray-400 py-2"
              >Add a description of your company</span
            >
            <div>
              <TextArea
                v-model="description"
                label="Write here"
                rows="4"
                className="pt-5 pb-2 pl-4 pr-10 block w-full px-0 mt-0 bg-transparent border-2 rounded-lg border-solid appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200"
                :isLabel="false"
                placeholder="Write here"
                name="description"
              />
            </div>
          </div>
          <div class="w-full border-t-2 mt-4"></div>
          <div class="flex flex-row justify-between items-center py-3">
            <button
              @click="$emit('closeModal')"
              class="flex flex-col px-4 py-3 border-2 border-primary text-primary rounded-full"
            >
              <span>Cancelar</span>
            </button>
            <button
              :disabled="isLoading"
              class="flex flex-row px-4 py-3 border-2 border-primary bg-primary text-white rounded-full disabled:cursor-not-allowed disabled:opacity-50 justify-center items-center"
            >
              <div class="relative flex px-3 mr-3" v-if="isLoading">
                <svg
                  class="absolute w-5 h-5 text-left mr-2 animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
              <span>{{
                !isLoading ? "Save changes" : "Saving changes..."
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { defineComponent, ref, watchEffect, onMounted, computed } from "vue";
import SwitchButtton from "../../components/switchButton/index.vue";
import DragAndDropFile from "../../components/dragFile/index.vue";
import RadioButton from "../../components/radioButton/index.vue";
import TextArea from "../textArea/index.vue";
import { JobsAPI } from "../../services/jobs";
import ChooseColorPicker from "../../components/colorPicker/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import UploadFileButton from "../../components/uploadFileButton/index.vue";

const CustomCompany = defineComponent({
  name: "CustomCompany",
  emits: ["closeModal", "updateBranding"],
  components: {
    TextArea,
    // RadioButton,
    //SwitchButtton,
    //DragAndDropFile,
    ChooseColorPicker,
    UploadFileButton,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    companyId: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const logo = ref("");
    const color = ref("");
    const background = ref("");
    const formValid = ref(false);
    const show_logo = ref(true);
    const primary_color = ref("");
    const secondary_color = ref("");
    const isLoading = ref(false);

    const schema = yup.object({
      description: yup.string().label("Description"),
      primary_color: yup.string().nullable(),
      secondary_color: yup.string().nullable(),
    });

    const { value: description } = useField("description");

    const companyBrandingId = computed(() => props.company.id);
    const domainName = computed(() => props.company.domain_name);
    const radioBg = ref("image");
    const bgOptions = [
      {
        name: "Use a solid color as background for this section",
        id: "color",
      },
      {
        name: "Use an image as background for this section",
        id: "image",
      },
    ];

    const { meta, handleSubmit, resetForm, errors } = useForm({
      validationSchema: schema,
      initialValues: {
        description: props.company.description ? props.company.description : "",
        show_logo: true,
        primary_color: props.company.primary_color
          ? props.company.primary_color
          : "#a21aa5",
        secondary_color: props.company.secondary_color
          ? props.company.secondary_color
          : "#a21aa5",
      },
    });

    const onSubmit = handleSubmit((values) => {
      isLoading.value = true;
      const buildParams = {
        ...values,
        logo: logo.value,
        background: background.value,
        primary_color: primary_color.value,
        secondary_color: secondary_color.value,
        site_url: null,
        domain_name: domainName.value,
      };
      JobsAPI.updateCompanyBranding(
        props.companyId,
        companyBrandingId.value,
        buildParams
      )
        .then((response) => {
          isLoading.value = false;
          context.emit("closeModal");
          context.emit("updateBranding");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          isLoading.value = false;
          createToastAxelerate("Company branding", errors[0], "danger");
          context.emit("closeModal");
        });
    });

    onMounted(() => {
      primary_color.value = props.company?.primary_color
        ? props.company?.primary_color
        : "#82109c";
      secondary_color.value = props.company?.secondary_color
        ? props.company?.secondary_color
        : "#82109c";
    });

    const saveChanges = () => {
      const buildParams = {
        logo: logo.value,
        background: background.value,
        description: description.value,
        primary_color: color.value,
        secondary_color: color.value,
        site_url: null,
        domain_name: domainName.value,
      };
    };

    return {
      logo,
      color,
      radioBg,
      bgOptions,
      formValid,
      background,
      saveChanges,
      description,
      schema,
      onSubmit,
      show_logo,
      primary_color,
      secondary_color,
      isLoading,
    };
  },
});
export default CustomCompany;
</script>
<style scoped>
.bg-preview {
  height: 30%;
}
</style>
