
import { defineComponent, ref, computed, onBeforeMount, Ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MenuDropDown from "../menuDropdown/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import ResponsiveNavBar from "../responsiveNav/index.vue";

const HeaderResponsive = defineComponent({
  name: "HeaderResponsive",
  emits: ["logOut"],
  components: {
    MenuDropDown,
    ResponsiveNavBar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const token = computed(() => store.state.auth.token);
    const profile = computed(() => store.state.profile.data);
    const roles: Ref<Array<any>> = computed(
      () => store.state.profile.data.roles
    );
    const exchangeRate = computed(
      () => store.state.profile.data.average_exchange_rate
    );
    const notification = computed(
      () => store.state.profile.data.unread_notifications_count
    );
    const showTooltip = ref(false);
    const user = ref({});
    const showModal = ref(false);
    const isOpen = ref(false);
    const drawer = () => {
      isOpen.value = !isOpen.value;
    };
    onBeforeMount(async () => {
      document.addEventListener("keydown", (e) => {
        if (e.keyCode == 27 && isOpen.value) isOpen.value = false;
      });
      await store.dispatch("getProfile");
      const validRoles = [
        "superadmin",
        "internal_recruiter",
        "internal_hr",
        "recruiter_admin",
      ];
      if (roles.value.length > 0) {
        if (!validRoles.includes(roles.value[0])) {
          window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
        }
      }
      /*       if (profile?.value?.company?.subscription_status !== "active") {
        await router.push("/subscriptions");
      } */
    });
    const logOut = () => {
      store
        .dispatch("logout", { token: token.value })
        .then(async () => {
          await store.commit("RESET_PROFILE");
          window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
        })
        .catch(() => {
          createToastAxelerate("Log out", "An Error has ocurred.", "danger");
        });
    };
    return {
      user,
      showModal,
      logOut,
      profile,
      router,
      drawer,
      isOpen,
      exchangeRate,
      notification,
      showTooltip,
      roles,
    };
  },
});

export default HeaderResponsive;
