/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { instanceLoggedAPI } from "../axiosConfig";

export async function getPayrollPeriods() {
  try {
    const { data } = await instanceLoggedAPI.get("/payroll_periods_list");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getCompanyPayrolls(company_id: string, page?: number) {
  try {
    let query = 1;
    if (typeof page !== "undefined") {
      query = page;
    }
    const { data } = await instanceLoggedAPI.get(
      `/companies/${company_id}/payroll_periods?page=${query}`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getPayrolls(
  start_date?: string,
  end_date?: string,
  page?: string,
  company_id?: string
) {
  const params = {
    start_date,
    end_date,
  } as any;
  if (typeof page !== undefined) {
    params.page = page;
  }
  if (typeof company_id !== undefined) {
    params.company_id = company_id;
  }
  try {
    const { data } = await instanceLoggedAPI.get("/payroll_periods", {
      params: params,
    });
    return data;
  } catch (err) {
    return null;
  }
}

export async function getPayroll(company_id: string, payroll_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(
      `/companies/${company_id}/payroll_periods/${payroll_id}`
    );
    return data;
  } catch (err) {
    return null;
  }
}
