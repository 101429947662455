
import { defineComponent, onBeforeMount, ref } from "vue";
import Header from "../../components/header/index.vue";
import Link from "../../components/link/index.vue";
import Avatar from "../../components/avatar/index.vue";
import { useRoute } from "vue-router";
import { ClientAPI } from "../../services/clients/index";
import Tabs from "../../components/tabs/index.vue";
import ClientInformation from "../../components/clientInformation/index.vue";
import { useState } from "../../composables/useState";
import Loading from "vue-loading-overlay";
import ErrorPage from "../../components/error/index.vue";
import MembersTable from "../../components/membersTable/index.vue";
import InternalsTable from "../../components/internalsTable/index.vue";
import RolesTable from "@/components/rolesTable/index.vue";
import PaymentContacts from "@/components/paymentContacts/index.vue";
import CompanyJobBoard from "@/components/companyJobBoardSection/index.vue";

const ClientDetail = defineComponent({
  name: "clientDetail",
  components: {
    Header,
    Link,
    Avatar,
    Tabs,
    ClientInformation,
    InternalsTable,
    Loading,
    ErrorPage,
    MembersTable,
    RolesTable,
    PaymentContacts,
    CompanyJobBoard,
  },
  setup() {
    const route = useRoute();
    const company_id = route.params.id as string;
    const tabElements = [
      "About",
      "Client Staff",
      "Internal Collaborators",
      "Roles",
      "Payment Contacts",
      "Job Board",
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const tab = ref(0);
    const products = ref([""]);
    const company = ref();

    onBeforeMount(() => {
      setLoading(true);
      ClientAPI.getClientDetail(route?.params?.id?.toString())
        .then((res) => {
          company.value = res.data.company;
          products.value = res.data.company.company_products;

          setLoading(false);
          setData(res.data.company);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    });
    return {
      route,
      tabElements,
      tab,
      data,
      error,
      loading,
      company_id,
      products,
      company,
    };
  },
});
export default ClientDetail;
