
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, Ref, ref, watch } from "vue";
import Header from "@/components/header/index.vue";
import TalentCard from "@/components/talentCard/index.vue";
//import Filter from "@/components/filter/index.vue";
import RadioButton from "@/components/radioButton/index.vue";
import SearchInput from "@/components/searchInput/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import { getTalents, getTalentsFiltered } from "@/services/talents/index";
import VPagination from "@hennge/vue3-pagination";

export default defineComponent({
  name: "Talents",
  components: {
    TalentCard,
    Header,
    //Filter,
    SearchInput,
    Loader,
    ErrorState,
    RadioButton,
    VPagination,
  },
  setup() {
    const loading = ref(false);
    const error = ref(false);
    const talents = ref([]);
    const status = ref("all_status");
    const company = ref("all_company");
    const searchQuery = ref("");
    const currentPage: Ref<any> = ref(0);
    const openFilterStatus = ref(true);
    const openFilterCompany = ref(true);
    const filterStatusOptions: Ref<any> = ref({});
    const filterCompanyOptions: Ref<Array<any>> = ref([]);
    const pagination: Ref<any> = ref({});

    async function getAllTalents() {
      loading.value = true;
      const data = await getTalents(currentPage.value);
      if (data) {
        talents.value = data.talents;
        filterStatusOptions.value = data.meta.status;
        filterCompanyOptions.value = data.meta.company;
        filterCompanyOptions.value = filterCompanyOptions.value.filter(
          (x) => x.talents_count > 0
        );
        pagination.value = data.meta.pagination;
        currentPage.value = data.meta.pagination.current_page;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    async function getFilteredTalents(
      status: string,
      company: string,
      current_page: number
    ) {
      loading.value = true;
      const data = await getTalentsFiltered(status, company, current_page);
      if (data) {
        talents.value = data.talents;
        filterStatusOptions.value = data.meta.status;
        filterCompanyOptions.value = data.meta.company;
        filterCompanyOptions.value = filterCompanyOptions.value.filter(
          (x) => x.talents_count > 0
        );
        pagination.value = data.meta.pagination;
        currentPage.value = data.meta.pagination.current_page;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    watch(currentPage, (newVal) => {
      getFilteredTalents(status.value, company.value, newVal);
    });
    watch(status, (newVal) => {
      getFilteredTalents(newVal, company.value, currentPage.value);
    });
    watch(company, (newVal) => {
      getFilteredTalents(status.value, newVal, currentPage.value);
    });

    onMounted(() => {
      getAllTalents();
    });

    return {
      loading,
      error,
      talents,
      status,
      company,
      searchQuery,
      openFilterStatus,
      filterStatusOptions,
      openFilterCompany,
      filterCompanyOptions,
      currentPage,
      pagination,
    };
  },
});
