
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  watch,
  watchEffect,
  computed,
} from "vue";
import VueTableLite from "vue3-table-lite/ts";
//import CompanyCard from "@/components/companyCard/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import { useRoute } from "vue-router";
import { InternalClientstAPI } from "@/services/internalClients";
import { useStore } from "vuex";
import ModalAddMember from "@/components/modalAddMember/index.vue";
import Paginator from "@/components/paginator/index.vue";
import SearchInput from "../../components/searchInput/index.vue";
import { removeBlankProperties } from "@/utils/params";

export default defineComponent({
  name: "Collaborators Table",
  components: {
    VueTableLite,
    SearchInput,
    ModalAddMember,
    Paginator,
  },
  props: {
    visible: Boolean,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const tableData: Ref<Array<any>> = ref([]);
    const page: Ref<number> = ref(1);
    const pagination: Ref<any> = ref({});
    const openModal = ref(false);
    const company_id = route.params.id as string;
    const client = ref({});
    const openDropdown = ref("");
    const filters = ref({});
    const inputValue = ref("");
    const currentDropdown = ref("");
    store.commit("SET_REFRESH", false);
    const refresh = computed(() => store.getters.getRefresh);
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          isKey: true,
          width: "40%",
        },
        {
          label: "Email",
          field: "email",
          isKey: true,
          width: "40%",
        },
        {
          label: "Role",
          field: "role",
          sortable: true,
          width: "40%",
        },
        {
          label: "",
          field: "menu",
          sortable: true,
          width: "20%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });

    function updateHandler(e: any) {
      page.value = e;
      getCompanies();
    }

    async function getCompanies() {
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      InternalClientstAPI.getInternalClients(company_id, page.value, _filters)
        .then((res) => {
          const data: any = [];
          res.data.internal_clients.map((internal: any) => {
            if (internal.user) {
              internal.user.name = `${internal.user.first_name} ${internal.user.last_name}`;
              internal.user.id_relacion = internal.id;
              internal.user.role = internal.user?.roles
                ? [...internal.user.roles].shift()
                : "n_a";
              data.push(internal.user);
            }
          });
          tableData.value = data;
          pagination.value = res.data.meta.pagination;
          setTable(tableData.value);
          table.isLoading = false;
        })
        .catch(() => {
          table.isLoading = false;
        });
    }

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
      });
    });
    const closeMenu = () => {
      currentDropdown.value = "";
    };

    const openDropDown = (data: any) => {
      client.value = data;
      currentDropdown.value = data.id;
    };

    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    function deactivateClient(id: string) {
      InternalClientstAPI.deactivateClient(id)
        .then(() => {
          createToastAxelerate(
            "Deactivate collaborator",
            "The collaborator has been deactivated successfully",
            "success"
          );
          store.commit("SET_REFRESH", true);
          closeMenu();
        })
        .catch((error) => {
          const {
            response: {
              data: { errors },
            },
          } = error;
          createToastAxelerate("Deactivate collaborator", errors[0], "danger");
        });
    }

    watchEffect(() => {
      if (refresh.value) {
        getCompanies();
        store.commit("SET_REFRESH", false);
      }

      watch(
        () => refresh.value,
        () => {
          store.commit("SET_REFRESH", false);
        }
      );
    });

    function searchTitle(event: any) {
      const value = event.target.value.toLowerCase();
      const data = tableData.value.filter(function (row) {
        return (
          row.name.toLowerCase().includes(value) ||
          row.name.toLowerCase().includes(value) ||
          row.id.includes(value)
        );
      });
      setTable(data);
    }

    watch(inputValue, (newVal) => {
      if (newVal) {
        page.value = 1;
      }
    });

    onMounted(() => {
      getCompanies();
    });

    return {
      table,
      closeMenu,
      searchTitle,
      openDropdown,
      currentDropdown,
      openDropDown,
      openModal,
      client,
      deactivateClient,
      page,
      pagination,
      updateHandler,
      filters,
      inputValue,
      getCompanies,
    };
  },
});
