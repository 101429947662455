import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-538da576"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in items-center" }
const _hoisted_2 = ["name", "checked", "id", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        name: _ctx.fieldId,
        checked: _ctx.checked,
        id: _ctx.fieldId,
        disabled: _ctx.disabled,
        onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:checked', event.target.checked)),
        class: "toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer"
      }, null, 40, _hoisted_2),
      _createElementVNode("label", {
        for: _ctx.fieldId,
        class: "toggle-label block overflow-hidden h-7 rounded-full bg-gray-300 cursor-pointer"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("label", {
      for: _ctx.fieldId,
      class: _normalizeClass(_ctx.labelClass)
    }, _toDisplayString(_ctx.label), 11, _hoisted_4)
  ], 64))
}