
import { defineComponent, ref } from "vue";
import Header from "@/components/header/index.vue";
import ClientsTable from "@/components/clientsTable/index.vue";
import ModalAddCompany from "@/components/modalAddCompany/index.vue";

export default defineComponent({
  name: "Clients",
  components: {
    Header,
    ClientsTable,
    ModalAddCompany,
  },
  setup() {
    const openModal = ref(false);

    return { openModal };
  },
});
