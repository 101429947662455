
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, watchEffect, ref } from "vue";
import { getInitials, numberWithCommas } from "../../utils/functions";
import Chips from "../../components/chips/index.vue";
import { useRoute, useRouter } from "vue-router";
import { CandidatesAPI } from "../../services/candidates/index";
import { useState } from "../../composables/useState";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Header from "../../components/header/index.vue";
import MenuCandidate from "@/components/menuCandidate/index.vue";
import CandidateForm from "@/components/addCandidateForm/index.vue";
import AddCandidateInJob from "../../components/addCandidateInJob/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import Tabs from "../../components/tabs/index.vue";
import Divider from "@/components/divider/index.vue";
import Avatar from "@/components/avatar/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import PdfView from "@/components/pdfViewer/index.vue";
import EmptyState from "../../components/emptyState/index.vue";
import EvaluationsAnswer from "@/components/evaluationAnswer/index.vue";
import FilesForm from "@/components/filesForm/index.vue";

const CompanyCandidate = defineComponent({
  name: "CompanyCandidate",
  components: {
    Chips,
    Loading,
    Header,
    MenuCandidate,
    CandidateForm,
    AddCandidateInJob,
    DateFormatter,
    ReusableModal,
    Tabs,
    Divider,
    Avatar,
    PdfView,
    EmptyState,
    EvaluationsAnswer,
    FilesForm,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const [candidate, setCandidate] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const idCandidate = ref(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const [editCandidate, setEditCandidate] = useState(false);
    const showModalFile = ref(false);
    const hasPreferredWork = ref(false);
    const tab = ref(0);
    const tabElements = [
      "Work experience",
      "Education",
      "Recruitment process",
      "Files",
      "Evaluation",
    ];

    const buildOverYears = (startYear: any, endYear: any, isCurrent: any) => {
      if (isCurrent) {
        return `(Now)`;
      }
      const _endyear = new Date(endYear).getFullYear();
      const _startyear = new Date(startYear).getFullYear();
      if (_endyear - _startyear > 0) {
        return `Over ${_endyear - _startyear} Years`;
      } else {
        return `Over ${
          endYear.getMonth() -
          startYear.getMonth() +
          12 * (startYear.getFullYear() - endYear.getFullYear())
        } Months`;
      }
    };
    const buildNow = (year: any, isCurrent: any) => {
      if (isCurrent) {
        return `Now`;
      }
      return `${year}`;
    };

    const closeFileForm = () => {
      showModalFile.value = false;
      getCandidate();
      tab.value = 3;
    };

    const getCandidate = () => {
      setIsLoading(true);
      CandidatesAPI.getCompanyCandidate(route.params.id).then((res: any) => {
        idCandidate.value = res.data.candidate_id;
        if (res.data?.candidate_work_histories.length > 0)
          res.data.candidate_work_histories.reverse();
        setCandidate(res.data);
        getPreferredWork();
        setIsLoading(false);
      });
    };

    const openEdit = () => {
      setEditCandidate(true);
    };

    const onShowInfo = () => {
      setEditCandidate(!editCandidate);
      getCandidate();
    };

    watchEffect(() => {
      if (route.query.id) {
        getCandidate();
      }
    });

    onBeforeMount(() => {
      getCandidate();
    });

    const getSalary = (
      salary: any,
      currency: string,
      show_monthly_salaries: boolean
    ) => {
      if (currency)
        return `$${numberWithCommas(salary || 0)}  (${currency}) ${
          show_monthly_salaries ? "Monthly" : "Annual"
        }`;
      else
        return `$${numberWithCommas(salary || 0)} ${
          show_monthly_salaries ? "Monthly" : "Annual"
        }`;
    };

    const getPreferredWork = () => {
      let workString = "";
      let array: string[] = [];
      Object.entries(candidate.value.preferred_work_type).find(
        ([key, value]) => {
          if (value === true) {
            array.push(key);
          }
        }
      );
      if (array.length > 0) hasPreferredWork.value = true;
      array.forEach((element, index) => {
        let lasItem = index === array.length - 1;
        element = element.replaceAll("_", " ");
        workString =
          workString +
          element.charAt(0).toUpperCase() +
          element.slice(1) +
          `${lasItem ? "" : ", "}`;
      });
      return array.length > 0 ? workString : "N/A";
    };
    return {
      getInitials,
      buildOverYears,
      buildNow,
      getPreferredWork,
      numberWithCommas,
      candidate,
      tab,
      getSalary,
      router,
      route,
      isLoading,
      setShowModal,
      showModal,
      getCandidate,
      tabElements,
      setShowDropDown,
      showDropDown,
      idCandidate,
      openEdit,
      setEditCandidate,
      editCandidate,
      showModalFile,
      closeFileForm,
      onShowInfo,
      hasPreferredWork,
    };
  },
});

export default CompanyCandidate;
