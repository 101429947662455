
import { defineComponent, computed } from "vue";
import JobSingle from "../../components/jobSingleDetail/index.vue";
import { useStore } from "vuex";

const JobShow = defineComponent({
  name: "JobShow",
  components: { JobSingle },
  setup() {
    const store = useStore();
    const job = computed(() => store.state.addCanidates.data);

    return {
      job,
    };
  },
});
export default JobShow;
