import { GetterTree } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";

export const getters: GetterTree<UserState, RootState> = {
  getEmail(state): string {
    return state.email;
  },
  getUserId(state): string {
    return state.user_id;
  },
  getUserFirstName(state): string {
    return state.first_name;
  },
  getUserLastName(state): string {
    return state.last_name;
  },
  getUserRole(state): string {
    return state.role;
  },
  getCompanyId(state): string {
    return state.company_id;
  },
  getCompanyName(state): string {
    return state.company_name;
  },
};
