
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from '@/composables/useState';
import {
	computed,
	defineComponent,
	onBeforeMount,
	onMounted,
	ref,
	Ref,
	watchEffect,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Header from '../../components/header/index.vue';
import ShowStages from '../../components/showStages/index.vue';
import Skeleton from '../../components/skeleton/index.vue';
import Button from '../../components/button/index.vue';
import JobCandidateCard from '../../components/jobCandidateCard/index.vue';
// import CandidateInfo from "../../components/candidateInfo/index.vue";
// import EditCandidateForm from "../../components/editCandidate/index.vue";
import Loading from 'vue-loading-overlay';
import ReusableModal from '../../components/reusableModal/index.vue';
import AddCandidatesForm from '../../components/addCandidateForm/index.vue';
import { selectStages } from '../../constants/candidates';
import SimpleSelect from '../../components/simpleSelect/index.vue';
import { isValidURL, buildWorkableId, onlyPaste } from '../../utils/functions';
import { createToastAxelerate } from '../../utils/toast';
import { JobsAPI } from '../../services/jobs/index';
import InputUniversalCopy from '../../components/universalCopyInput/index.vue';
import Link from '../../components/link/index.vue';
import AddCandidatesInJob from '../../components/addCandidatesToJob/index.vue';
import ErrorPage from '../../components/error/index.vue';
import AddTemplateToJob from '@/components/addTemplateToJob/index.vue';
import AddChallengeToJob from '@/components/addChallengeToJob/index.vue';
import JobMemberSection from '@/components/jobMemberSection/index.vue';

const JobCandidatesDetail = defineComponent({
	name: 'JobCandidatesDetail',
	components: {
		Header,
		ShowStages,
		Skeleton,
		Button,
		JobCandidateCard,
		ReusableModal,
		Loading,
		AddCandidatesForm,
		SimpleSelect,
		InputUniversalCopy,
		Link,
		AddCandidatesInJob,
		ErrorPage,
		AddTemplateToJob,
		AddChallengeToJob,
		JobMemberSection,
	},
	setup() {
		const formRef = ref<InstanceType<typeof AddCandidatesForm>>();
		const store = useStore();
		const route = useRoute();
		const stage = ref('');
		const router = useRouter();
		const job = computed(() => store.state.addCanidates.data);
		const companyId = computed(
			() => store.state.addCanidates.data.company.id
		);
		const candidates = computed(
			() => store.state.jobCandidates.data.job_candidates
		);
		const [selected, setSelected] = useState('');
		const [loading, setLoading] = useState(false);
		const [errUrl, setErrUrl] = useState(false);
		const [error, setError] = useState(false);
		const [toggleInfo, setToggleInfo] = useState(false);
		const [option, setOption] = useState('detail');
		const [showForm, setShowForm] = useState(false);
		const [isEdit, setIsEdit] = useState(false);
		const [expand, setExpand] = useState(false);
		const [input, setInput] = useState('');
		const [showSearch, setShowSearch] = useState(false);
		const [showModal, setShowModal] = useState(false);
		const [showModalChallenge, setShowModalChallenge] = useState(false);
		const [loadingTemplate, setLoadingTemplate] = useState(false);
		const isMenuOpen = ref(false);
		const isMenuOpenChallenge = ref(false);
		const jobId: Ref<string> = ref(route.params.id as string);
		const candidate = computed(() => store.state.candidateInfo.data);
		const isValid = ref(false);
		const resetSelected = () => setSelected('');
		const getFromForm = async () => {
			setLoading(true);
			const data = { jobId: jobId.value, filters: { all_stages: true } };
			const getJob = store.dispatch('getJobState', jobId.value);
			const getCandidates = store.dispatch('getJobCandidatesState', data);
			return await Promise.all([getJob, getCandidates])
				.then(() => {
					setLoading(false);
					setInput(job?.value?.workable_id);
				})
				.catch(() => {
					setLoading(false);
					setError(true);
				});
		};
		const handleCandidateClick = async (id: string) => {
			window.scrollTo(0, 0);
			router.push({
				name: 'JobCandidates',
				params: { job_candidate_id: id },
			});
			setSelected(id);
		};
		const reloadAllCandidatesStage = () => {
			setToggleInfo(true);
			setLoading(true);
			const data = { jobId: jobId.value, filters: {} };
			setOption('detail');
			store.dispatch('getJobCandidatesState', data).then(() => {
				setLoading(false);
				store.dispatch('getJobState', route.query.id);
			});
		};
		const reloadCandidate = () => {
			setToggleInfo(true);
			setLoading(true);
			const buildParams = {
				jobId: jobId.value,
				userId: candidate.value?.id,
			};
			const data = { jobId: jobId.value, filters: {} };
			setOption('');
			store.dispatch('getJobCandidatesState', data);
			store
				.dispatch('getCandidateState', buildParams)
				.then(() => {
					setOption('info');
					setLoading(false);
				})
				.catch(() => {
					setError(true);
					setLoading(false);
				});
		};
		const closeInForm = async () => {
			//  await formRef?.value?.resetFormOutside();
			setShowForm(false);
		};
		const handleDetail = () => {
			router.push({ name: 'Job Show', params: { id: jobId.value } });
		};
		const handleEvents = () => {
			router.push({ name: 'Job Events', params: { id: jobId.value } });
		};
		const handleChangeStage = () => {
			setOption('detail');
			setSelected('');
			setLoading(true);
			const data = {
				jobId: jobId.value,
				filters: { by_stage_name: stage.value, all_stages: true },
			};
			store
				.dispatch('getJobCandidatesState', data)
				.then(() => setLoading(false));
		};
		onBeforeMount(() => {
			store.commit('RESET_CANDIDATE');
			store.commit('RESET_JOB_CANDIDATES');
			store.commit('RESET_CANDIDATES', []);
			getFromForm();
		});
		onMounted(() => {
			if (route.query.openModal) {
				setShowForm(true);
			}
		});
		watchEffect(() => {
			if (stage.value) {
				handleChangeStage();
			} else {
				getFromForm();
			}
		});
		watchEffect(() => {
			isValid.value =
				candidates.value &&
				candidates.value.length &&
				candidates.value.some(
					(e: any) => e.current_stage !== 'Talent Pool'
				)
					? false
					: true;
		});
		const updateValue = (e: any) => {
			setInput(e);
		};
		const onPaste = async (e: any) => {
			const text = e.target.textContent;
			if (isValidURL(text)) {
				const { error, id } = buildWorkableId(text);
				if (error) {
					setErrUrl(true);
					createToastAxelerate(
						'Workbale id',
						'The text copied is not a workable valid url',
						'danger'
					);
					await setInput(null);
				} else {
					setErrUrl(false);
					setInput(id);
					createToastAxelerate(
						'Workbale id',
						'The url has been copied to clipboard',
						'info',
						() => undefined,
						3000,
						null,
						'#00000f'
					);
				}
			} else {
				createToastAxelerate(
					'Workbale id',
					'The text copied is not a valid url with a valid id',
					'danger'
				);
			}
		};
		const updateId = () => {
			const payload = {
				job: {
					workable_id: input.value,
				},
			};
			JobsAPI.editJob(payload, jobId.value)
				.then(async () => {
					await setIsEdit(false);
					await getFromForm();
					await createToastAxelerate(
						'Change workable id',
						'The id has been saved.',
						'success'
					);
				})
				.catch((err) => {
					const {
						response: {
							data: { errors },
						},
					} = err;
					createToastAxelerate(
						'Change workable id',
						errors ? errors[0] : 'An error has ocurred.',
						'danger'
					);
				});
		};
		const syncCandidates = () => {
			JobsAPI.syncJobs(jobId.value)
				.then(async () => {
					await createToastAxelerate(
						'Sync job',
						'The sync has been successful.',
						'success'
					);
					await getFromForm();
				})
				.catch((err) => {
					const {
						response: {
							data: { errors },
						},
					} = err;
					createToastAxelerate(
						'Sync job',
						errors ? errors[0] : 'An error has ocurred.',
						'danger'
					);
				});
		};
		const closeMenuChallenge = () => {
			isMenuOpenChallenge.value = false;
		};
		const closeMenu = () => {
			isMenuOpen.value = false;
		};
		const removeEvaluation = () => {
			closeMenu();
			JobsAPI.removeJobEvaluation(jobId.value)
				.then(() => {
					createToastAxelerate(
						'Remove evaluation',
						'The evaluation has been removed',
						'success'
					);
					updateJobInfo();
				})
				.catch((err) => {
					const {
						response: {
							data: { errors },
						},
					} = err;
					createToastAxelerate(
						'Remove evaluation',
						errors
							? errors[0]
							: `Job's evaluation can't be removed`,
						'danger'
					);
				});
		};

		const updateEvaluation = () => {
			closeMenu();
			if (job.value?.job_evaluation?.title) {
				JobsAPI.removeJobEvaluation(jobId.value)
					.then(() => {
						updateJobInfo();
						setShowModal(true);
					})
					.catch((err) => {
						const {
							response: {
								data: { errors },
							},
						} = err;
						createToastAxelerate(
							'Remove evaluation',
							errors
								? errors[0]
								: `Job's evaluation can't be updated`,
							'danger'
						);
					});
			} else {
				setShowModal(true);
			}
		};

		const updateChallenge = () => {
			isMenuOpenChallenge.value = false;
			setShowModalChallenge(true);
		};

		const removeChallenge = () => {
			isMenuOpenChallenge.value = false;

			JobsAPI.removeJobChallenge(jobId.value)
				.then((response) => {
					updateJobInfo();
					createToastAxelerate(
						'Remove Challenge',
						`Job's evaluations has been removed`,
						'success'
					);
				})
				.catch((err) => {
					const {
						response: {
							data: { errors },
						},
					} = err;
					createToastAxelerate(
						'Remove evaluation',
						errors ? errors[0] : `Job's challenge can't be removed`,
						'danger'
					);
				});
		};

		const updateJobInfo = () => {
			store
				.dispatch('getJobState', route.params.id)
				.then(() => {
					setLoadingTemplate(false);
				})
				.catch(() => {
					setLoadingTemplate(false);
				});
		};

		return {
			removeChallenge,
			updateChallenge,
			job,
			loading,
			error,
			router,
			candidates,
			selected,
			handleCandidateClick,
			toggleInfo,
			option,
			setOption,
			candidate,
			reloadAllCandidatesStage,
			reloadCandidate,
			formRef,
			closeInForm,
			showForm,
			getFromForm,
			setShowForm,
			handleDetail,
			selectStages,
			stage,
			handleChangeStage,
			resetSelected,
			setExpand,
			expand,
			setIsEdit,
			isEdit,
			onPaste,
			setInput,
			input,
			updateId,
			onlyPaste,
			errUrl,
			syncCandidates,
			updateValue,
			jobId,
			setShowSearch,
			showSearch,
			closeMenu,
			isMenuOpen,
			removeEvaluation,
			updateEvaluation,
			setShowModal,
			showModal,
			updateJobInfo,
			setLoadingTemplate,
			loadingTemplate,
			isValid,
			isMenuOpenChallenge,
			closeMenuChallenge,
			showModalChallenge,
			setShowModalChallenge,
			handleEvents,
			companyId,
		};
	},
});

export default JobCandidatesDetail;
