
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount } from "vue";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { useState } from "@/composables/useState";
import Avatar from "../avatar/index.vue";
import VPagination from "@hennge/vue3-pagination";
import Skeleton from "../skeleton/index.vue";
import SearchInput from "../searchInput/index.vue";
import { createToastAxelerate } from "../../utils/toast";

const AddTemplateToJob = defineComponent({
  name: "AddTemplateToJob",
  emits: ["reload", "close"],
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    Avatar,
    VPagination,
    Skeleton,
    SearchInput,
  },
  setup(props, context) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    onBeforeMount(() => {
      setIsLoading(true);
      getTemplates(1);
    });

    const assingTemplate = (id: string) => {
      const payload = {
        job_id: props.id,
      };
      TalentEvaluationAPI.assignTemplate(id, payload)
        .then(async () => {
          await context.emit("reload");
          await context.emit("close");
          await createToastAxelerate(
            "Assign template",
            "The template has been assigned",
            "success"
          );
        })
        .catch((err: any) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Assign template",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const getTemplates = (page: number) => {
      TalentEvaluationAPI.getAllTemplates(undefined, page).then((res: any) => {
        setData(res.data.evaluations);
        setPage(page);
        setPages(res.data.meta.pagination.total_pages)
        setIsLoading(false);
      });
    }

    const updateHandler = (e: number) => {
      getTemplates(e)
    };

    return {
      data,
      isLoading,
      assingTemplate,
      updateHandler,
      page,
      pages
    };
  },
});

export default AddTemplateToJob;
