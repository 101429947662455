
import { computed, defineComponent, onMounted, ref } from "vue";
import { JobsAPI } from "@/services/jobs/index";
import { useRoute } from "vue-router";
import Skeleton from "@/components/skeleton/index.vue";
import { timeAgo } from "../../utils/functions";
import { useState } from "@/composables/useState";
import moment from "moment";
import Mentionable from "@/components/mentions/index.vue";
import { createToastAxelerate } from "@/utils/toast";
import { MembersAPI } from "@/services/accountMembers";
import { buildUsers } from "@/utils/users";

const JobEvents = defineComponent({
  name: "JobEvents",
  components: {
    Skeleton,
    Mentionable,
  },
  setup() {
    const route = useRoute();
    const [loading, setLoading] = useState(true);
    const jobId = computed(() => route.params.id.toString());
    const [jobTimeline, setJobTimeline] = useState([]);
    const [users, setUsers] = useState([]);
    const content = ref("");
    const [addComment, setAddComment] = useState(false);
    onMounted(async () => {
      await getMembers();
      await getJobEvents();
      setLoading(false);
    });

    const getJobEvents = () => {
      JobsAPI.getJobEvents(jobId.value)
        .then((response: any) => {
          setJobTimeline(response.data.job_timeline);
        })
        .catch(() => {
          createToastAxelerate("Job events", "An error has ocurred", "danger");
        });
    };

    const getMembers = () => {
      MembersAPI.getTaggableMembers(jobId.value)
        .then((res: any) => {
          setUsers(buildUsers(res.data.users));
        })
        .catch(() => {
          createToastAxelerate("Job members", "An error has ocurred", "danger");
        });
    };

    const buildImageStage = (option: string) => {
      switch (option) {
        case "new_job":
          return require("@/assets/svgs/jobs.svg");

        case "managed_job_created":
          return require("@/assets/svgs/jobs.svg");

        case "managed_job_turned_off":
          return require("@/assets/svgs/link.svg");

        case "managed_job_turned_on":
          return require("@/assets/svgs/unlink.svg");

        case "job_closed":
          return require("@/assets/svgs/jobs.svg");

        case "job_reopened":
          return require("@/assets/svgs/jobs.svg");

        case "update":
          return require("@/assets/svgs/edit.svg");

        case "job_update_publish_date":
          return require("@/assets/svgs/date.svg");

        case "job_activity_comment":
          return require("@/assets/svgs/comment.svg");
      }
    };

    const highlightColor = (option: string) => {
      let color = "#fff";
      switch (option) {
        case "managed_job_created":
          color = "#FEFEE5";
          break;
        case "managed_job_turned_on":
          color = "#EEFFE5";
          break;
        case "managed_job_turned_off":
          color = "#FFF2F2";
          break;
      }
      return color;
    };

    const getFormattedDate = (date: string) => {
      return moment(date).format("MMM Do YYYY, h:mm a");
    };

    const addJobActivityComment = () => {
      const buildResponse = {
        comment: {
          content: content.value,
          section: "job_activity",
          mentions: content.value.match(
            /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
          ),
        },
      };

      JobsAPI.createJobComment(buildResponse, jobId.value)
        .then(async () => {
          createToastAxelerate(
            "Add comment",
            "The comment has been added.",
            "success"
          );
          setLoading(true);
          await getJobEvents();
          clearForm();
          setLoading(false);
        })
        .catch(() => {
          createToastAxelerate("Job comment", "An error has ocurred", "danger");
        });
    };

    const clearForm = () => {
      content.value = "";
      setAddComment(false);
    };

    return {
      users,
      timeAgo,
      content,
      loading,
      setUsers,
      clearForm,
      addComment,
      setLoading,
      jobTimeline,
      setAddComment,
      highlightColor,
      setJobTimeline,
      buildImageStage,
      getFormattedDate,
      addJobActivityComment,
    };
  },
});
export default JobEvents;
