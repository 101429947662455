
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { JobsAPI } from "../../services/jobs/index";
import { numberWithCommas, buildWorkableId } from "../../utils/functions";
import Loading from "vue-loading-overlay";
import Chips from "../chips/index.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Button from "../button/index.vue";
import JobCommentForm from "../../components/jobCommentForm/index.vue";
import { useStore } from "vuex";
import { useState } from "@/composables/useState";
import TimeLine from "../timeLine/index.vue";
import Skeleton from "../skeleton/index.vue";

const JobDetailsComponent = defineComponent({
  name: "JobDetailsComponent",
  components: {
    Loading,
    Chips,
    Button,
    JobCommentForm,
    TimeLine,
    Skeleton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const labelNoInfo = "No information";
    const [data, setData] = useState({});
    const isLoading = ref(false);
    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const jobId = route.query.id;
    const infoCommentStore = computed(() => store.state.jobs.info_comments);
    const functionCommentStore = computed(
      () => store.state.jobs.function_comments
    );
    const detailsCommentStore = computed(
      () => store.state.jobs.details_comments
    );
    const salaryCommentStore = computed(() => store.state.jobs.salary_comments);
    const getCommentsBySection = (path: string) => {
      setLoadingTimeline(true);
      store
        .dispatch(path, jobId)
        .then(() => {
          setLoadingTimeline(false);
        })
        .catch(() => {
          setLoadingTimeline(false);
        });
    };
    const getFirstTime = () => {
      const values = [
        "getInfoComments",
        "getFunctionComments",
        "getDetailsComments",
        "getSalaryComments",
      ];
      values.map((item) => {
        getCommentsBySection(item);
      });
    };
    onMounted(() => {
      isLoading.value = true;
      JobsAPI.getOneJob(route?.query?.id)
        .then((res: any) => {
          isLoading.value = false;
          setData(res.data);
        })
        .catch(() => {
          isLoading.value = false;
        });
      getFirstTime();
    });
    const handleRouter = (route: string) => router.push(route);
    const replaceEnum = (str: string) => (str ? str.replace(/_/g, " ") : str);

    const expand = reactive({
      comment_info: false,
      comment_function: false,
      comment_details: false,
      comment_salary: false,
    });
    return {
      route,
      router,
      handleRouter,
      data,
      replaceEnum,
      numberWithCommas,
      isLoading,
      buildWorkableId,
      labelNoInfo,
      setShowModal,
      showModal,
      expand,
      infoCommentStore,
      functionCommentStore,
      detailsCommentStore,
      salaryCommentStore,
      getCommentsBySection,
      loadingTimeline,
    };
  },
});

export default JobDetailsComponent;
