import { instanceLoggedAPI } from "../axiosConfig";
import { createToastAxelerate } from "@/utils/toast";

export async function getRoles(company_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(
      `/companies/${company_id}/positions`
    );
    return data;
  } catch (err: any) {
    if (err.response?.data?.errors) {
      const message = err.response?.data?.errors[0];
      createToastAxelerate("Could not get roles", message, "danger");
    }
  }
}

export async function getRole(company_id: string, position_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(
      `/companies/${company_id}/positions/${position_id}`
    );
    return data;
  } catch (err: any) {
    if (err.response?.data?.errors) {
      const message = err.response?.data?.errors[0];
      createToastAxelerate("Could not get role", message, "danger");
    }
  }
}

export async function createRole(company_id: string, body: any) {
  try {
    const { data } = await instanceLoggedAPI.post(
      `/companies/${company_id}/positions`,
      body
    );
    return data;
  } catch (err: any) {
    if (err.response?.data?.errors) {
      const message = err.response?.data?.errors[0];
      createToastAxelerate("Could not create role", message, "danger");
    }
  }
}

export async function editRole(position_id: string, body: any) {
  try {
    const { data } = await instanceLoggedAPI.put(
      `/positions/${position_id}`,
      body
    );
    return data;
  } catch (err: any) {
    if (err.response?.data?.errors) {
      const message = err.response?.data?.errors[0];
      createToastAxelerate("Could not save role", message, "danger");
    }
  }
}

export async function deleteRole(position_id: string) {
  try {
    const { data } = await instanceLoggedAPI.delete(
      `/positions/${position_id}`
    );
    return data;
  } catch (err: any) {
    if (err.response?.data?.errors) {
      const message = err.response?.data?.errors[0];
      createToastAxelerate("Could not delete role", message, "danger");
    }
  }
}
