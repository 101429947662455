
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, Ref, ref } from "vue";
import Header from "@/components/header/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import UserCard from "@/components/userCard/index.vue";
import { getPayroll } from "@/services/payroll/index";
import { useRoute } from "vue-router";
import CardExtraCharge from "@/components/cardExtraCharge/index.vue";
//import PayrollTable from "@/components/payrollTable/index.vue";
//import ExtraChargesTable from "@/components/extraChargesTable/index.vue";

export default defineComponent({
  name: "Payroll Detail",
  components: {
    Header,
    Loader,
    ErrorState,
    DateFormatter,
    UserCard,
    CardExtraCharge,
    //PayrollTable,
    //ExtraChargesTable,
  },
  setup() {
    const loading = ref(false);
    const error = ref(false);
    const route = useRoute();
    const company_id: Ref<string> = ref(route.params.company_id as string);
    const payroll_id: Ref<string> = ref(route.params.payroll_id as string);
    const showTooltip = ref(false);
    const hover = ref("");
    const hoverBonus = ref("");
    const newMargin = ref("");
    const payroll: Ref<any> = ref({});
    const meta: Ref<any> = ref({});
    const timeline: Ref<any> = ref({});
    //const selectedTable = ref("expenses");

    async function getPayrollDetails() {
      loading.value = true;
      const data = await getPayroll(company_id.value, payroll_id.value);
      if (data) {
        payroll.value = data.payroll_period;
        meta.value = data.meta;
        timeline.value = data.payroll_period.time_lines;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    function showBenefits(id: string) {
      hover.value = id;
      const margin = document.getElementById("tooltip")?.clientHeight as number;
      if (margin) {
        newMargin.value = String(margin);
      }
    }

    function showBonusesTooltip(id: string) {
      hoverBonus.value = id;
    }

    onMounted(() => {
      getPayrollDetails();
    });

    return {
      loading,
      error,
      showTooltip,
      payroll,
      meta,
      timeline,
      showBenefits,
      newMargin,
      hover,
      hoverBonus,
      company_id,
      payroll_id,
      showBonusesTooltip,
      //selectedTable,
    };
  },
});
