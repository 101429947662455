
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

const MenuEditUser = defineComponent({
  name: "MenuDropdown",
  emits: ["openEdit", "deleteCandidate", "goToPublic", "rejectCandidate"],
  props: {
    candidateId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const openEdit = () => {
      context.emit("openEdit");
    };
    const deleteCandidate = () => {
      context.emit("deleteCandidate");
      isMenuOpen.value = false;
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const goToPublic = () => {
      context.emit("goToPublic");
      isMenuOpen.value = false;
    };
    const rejectCandidate = () => {
      context.emit("rejectCandidate");
      isMenuOpen.value = false;
    };

    return {
      isMenuOpen,
      closeMenu,
      openEdit,
      deleteCandidate,
      router,
      goToPublic,
      rejectCandidate,
    };
  },
});
export default MenuEditUser;
