
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import TextField from "@/components/textField/index.vue";
import DropDown from "@/components/selectDropdown/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import Spinner from "@/components/spinner/index.vue";
import { InternalClientstAPI } from "@/services/internalClients";
import { collaborator_role } from "../../constants/company";
import { useRoute } from "vue-router";
import { createToastAxelerate } from "../../utils/toast";
import { useStore } from "vuex";

export default defineComponent({
  name: "Modal Add Member",
  components: {
    TextField,
    DropDown,
    Spinner,
  },
  props: {
    openModal: Boolean,
    client: Object,
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const checkValues = ref([]);
    const clientId = ref("");
    const isEditing = ref(false);
    const countrySelected = ref("");
    const internals = ref();
    const newCollaborator = ref(false);
    const company_id = route.params.id as string;
    //Validation
    const schema = yup.object({
      internal: yup.string().required().label("Select a collaborator"),
      first_name: yup.string().label("First name"),
      last_name: yup.string().label("Last name"),
      email: yup.string().email().label("Email"),
      role: yup.string().label("Select a role"),
    });

    const { handleSubmit, meta, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        internal: "",
        first_name: "",
        last_name: "",
        email: "",
        role: "",
      },
    });

    const fieldErrors = ref([]);

    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: email } = useField("email");
    const { value: internal } = useField("internal");
    const { value: role } = useField("role");

    const onSubmit = handleSubmit((values) => {
      loading.value = true;
      addCollaborator(values);
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    function addCollaborator(values: any) {
      let internal_client = {};
      if (internal.value !== "other") {
        internal_client = {
          company_id: company_id,
          user_id: values.internal,
        };
      } else {
        internal_client = {
          company_id: company_id,
          user: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            role: values.role,
          },
        };
      }

      InternalClientstAPI.addInternal(internal_client)
        .then(() => {
          createToastAxelerate(
            "Add collaborator",
            "The collaborator has been added successfully",
            "success"
          );
          loading.value = false;
          store.commit("SET_REFRESH", true);
          closeModal();
        })
        .catch((error) => {
          const {
            response: {
              data: { errors },
            },
          } = error;
          loading.value = false;
          createToastAxelerate("Add collaborator", errors[0], "danger");
        });
    }

    //Vars and functions
    const loading = ref(false);
    const error = ref(false);

    function closeModal() {
      resetForm();
      emit("update:openModal", false);
    }

    function getInternals() {
      InternalClientstAPI.getInternals()
        .then((response) => {
          internals.value = response.data.users;
          internals.value.map((internal: any) => {
            internal.name = `${internal.first_name} ${internal.last_name}`;
          });
          internals.value.push({ name: "New", id: "other" });
        })
        .catch(() => {
          createToastAxelerate("Clients", "An error has ocurred", "danger");
        });
    }

    watchEffect(() => {
      if (internal.value === "other") {
        newCollaborator.value = true;
      } else {
        newCollaborator.value = false;
      }
    });

    onMounted(async () => {
      getInternals();
    });

    return {
      loading,
      error,
      closeModal,
      onSubmit,
      fieldErrors,
      first_name,
      last_name,
      email,
      checkValues,
      meta,
      isEditing,
      clientId,
      countrySelected,
      getInternals,
      internals,
      internal,
      newCollaborator,
      collaborator_role,
      role,
    };
  },
});
