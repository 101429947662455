/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { ScheduleMeeting } from "./types";

export const actions: ActionTree<ScheduleMeeting, RootState> = {
  setIsModalOpened({ commit }, data) {
    commit("SET_IS_MODAL_OPEN", data);
  },
};
