
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
  watchEffect,
  watch,
} from "vue";
import { useStore } from "vuex";
import Header from "../../components/header/index.vue";
import { getInitials, buildColorStep } from "../../utils/functions";
import RadioButton from "../../components/radioButton/index.vue";
import CheckBoxGroup from "../../components/checkboxGroup/index.vue";
import SearchInput from "../../components/searchInput/index.vue";
import { checkStage, appDateValues } from "../../constants/candidates";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { removeBlankProperties } from "@/utils/params";
import "../../styles/pagination.css";
import { useState } from "../../composables/useState";
import Button from "../../components/button/index.vue";
import AddCandidatesForm from "../../components/addCandidateForm/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import CardCandidateDetail from "../../components/cardCandidateDetail/index.vue";
import { useRouter } from "vue-router";
import { CompanyAPI } from "../../services/companies/index";
import RangeSlider from "../../components/sliderRange/index.vue";
import { RecruiterAPI } from "../../services/recruiters/index";
import SkillCheckbox from "../../components/skillCheckbox/index.vue";
import { skillsEnum } from "../../enums/modules/skillsEnum";
import { optionSearch } from "@/constants/candidates";
import SwitchButton from "@/components/switchButton/index.vue";
import Paginator from "../../components/paginator/index.vue";
import { CandidatesAPI } from "@/services/candidates/index";
import { createToastAxelerate } from "@/utils/toast";

const Candidates = defineComponent({
  name: "Candidates",
  components: {
    Header,
    RangeSlider,
    RadioButton,
    CheckBoxGroup,
    SearchInput,
    Loading,
    Button,
    AddCandidatesForm,
    ReusableModal,
    CardCandidateDetail,
    SkillCheckbox,
    SwitchButton,
    Paginator,
  },
  setup() {
    const radioCompany = ref("");
    const router = useRouter();
    const radioAppDate = ref("");
    const radioStage = ref("");
    const rangeSalary = ref([0, 0]);
    const checkValues = ref([]);
    const restrict_attributes = ref([]);
    const inputValue = ref("");
    const store = useStore();
    const candidates = computed(() => store.state.candidates.data);
    const userId = computed(() => store.state?.profile.data.id);
    const isLoading = ref(false);
    const recruiters = ref([]);
    const companyList = ref([]);
    const [page, setPage] = useState(1);
    const rangeValue = ref([2, 30]);
    const dataFiltered = ref(false);
    const locationSelected = ref("");
    const [locations, setLocations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [noSkills, setNoSkills] = useState(false);
    const [noSalary, setNoSalary] = useState(false);
    const expand = reactive({
      company: false,
      stage: false,
      range: false,
      recruiter: false,
      skills: false,
    });
    const filters = ref({});
    const skillsList = Object.values(skillsEnum);
    const checkSkills = ref([]);

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
        by_stage_name: radioStage.value,
        by_company: radioCompany.value,
        city: locationSelected.value,
      });
    });

    const getCandidates = (newPage = 1) => {
      setPage(newPage);
      store
        .dispatch("getCandidatesIndex", {
          page: page.value,
          filters: {
            search: Object.values(filters.value).join("%26"),
            salary_range: getSalaryrange(),
            recruiters: checkValues.value,
            skills: noSkills.value ? "no_skills" : checkSkills.value,
            restrict_attributes: restrict_attributes.value,
          },
        })
        .then(() => {
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const updateRange = (e: any) => {
      rangeSalary.value = [];
      e.map((el: any) => {
        el = el * 1000;
        if (el <= 0) el = 1;
        rangeSalary.value.push(el);
      });
      dataFiltered.value = true;
    };

    const getSalaryrange = () => {
      let filteredSalary: any = "";
      if (noSalary.value) {
        filteredSalary = "no_salary";
      } else if (!noSalary.value && dataFiltered.value) {
        filteredSalary = rangeSalary.value;
      } else {
        filteredSalary = "";
      }
      return filteredSalary;
    };

    onMounted(async () => {
      isLoading.value = true;
      CompanyAPI.getCompanies(userId.value).then((res: any) => {
        companyList.value = res.data;
      });
      await getInternals();
      await getCandidates();
      await getLocations();
    });

    const getLocations = () => {
      CandidatesAPI.getCandidateLocations(userId.value)
        .then((response: any) => {
          setLocations(response.data);
        })
        .catch((error) => {
          createToastAxelerate("Get locations", "", "danger");
        });
    };

    const getInternals = () => {
      RecruiterAPI.listInternals().then((response: any) => {
        recruiters.value = response.data.users;
      });
    };

    const applyFilters = () => {
      setPage(1);
      isLoading.value = true;
      store
        .dispatch("getCandidatesIndex", {
          page: page.value,
          filters: removeBlankProperties({
            search: Object.values(filters.value).join("%26"),
            salary_range: getSalaryrange(),
            recruiters: checkValues.value,
            skills: noSkills.value ? "no_skills" : checkSkills.value,
            city: locationSelected.value,
            restrict_attributes: restrict_attributes.value,
          }),
        })
        .then((res: any) => {
          isLoading.value = res;
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const clearFilters = () => {
      radioStage.value = "";
      inputValue.value = "";
      radioCompany.value = "";
      rangeValue.value = [10, 10];
      checkValues.value = [];
      restrict_attributes.value = [];
      dataFiltered.value = false;
      expand.range = false;
      locationSelected.value = "";
      isLoading.value = true;
      checkSkills.value = [];
      setNoSalary(false);
      setTimeout(() => {
        applyFilters();
      }, 100);
    };

    const updateHandler = (e: any) => {
      getCandidates(e);
      isLoading.value = true;
    };

    watch(locationSelected, (newVal) => {
      applyFilters();
    });

    return {
      applyFilters,
      updateHandler,
      buildColorStep,
      getInitials,
      clearFilters,
      page,
      setPage,
      radioCompany,
      updateRange,
      radioStage,
      checkStage,
      expand,
      radioAppDate,
      appDateValues,
      inputValue,
      isLoading,
      candidates,
      filters,
      companyList,
      setShowModal,
      showModal,
      getCandidates,
      router,
      userId,
      rangeSalary,
      dataFiltered,
      rangeValue,
      getInternals,
      recruiters,
      checkValues,
      skillsList,
      checkSkills,
      skillsEnum,
      noSkills,
      setNoSkills,
      noSalary,
      setNoSalary,
      locations,
      setLocations,
      locationSelected,
      optionSearch,
      restrict_attributes,
    };
  },
});
export default Candidates;
