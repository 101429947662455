import Vuex, { StoreOptions } from "vuex";
import VuexPersistence from "vuex-persist";

import { RootState } from "./types";
import { auth } from "./auth";
import { profile } from "./profile";
import { jobs } from "./jobs/index";
import { candidates } from "./candidates/index";
import { talentEvaluation } from "./talentEvaluation/index";
import { jobCandidates } from "./jobCandidates/index";
import { addCanidates } from "./addCanidates/index";
import { candidateInfo } from "./candidateInfo/index";
import { timeLine } from "./timeLine/index";
import { candidatesWithoutJob } from "./candidatesWithoutJob/index";
import { answers } from "./answers/index";
import { user } from "./user/index";
import { createJob } from "./createJobForm/index";
import { templates } from "./templates/index";
import { jobStages } from "./jobStages/index";
import { nylas } from "./nylas/index";
import { reimbursements } from "./reimbursements/index";
import { clients } from "./clients/index";
import { scheduleMeeting } from "./scheduleMeeting/index";
import { emailComposer } from "./emailComposer/index";

const persistData = new VuexPersistence({
  modules: ["auth", "profile", "user", "nylas"],
});

const store: StoreOptions<RootState> = {
  state: {},
  modules: {
    addCanidates,
    answers,
    auth,
    candidates,
    candidateInfo,
    candidatesWithoutJob,
    createJob,
    emailComposer,
    jobs,
    jobCandidates,
    jobStages,
    nylas,
    profile,
    reimbursements,
    talentEvaluation,
    templates,
    timeLine,
    user,
    clients,
    scheduleMeeting,
  },
  mutations: {},
  actions: {},
  plugins: [persistData.plugin],
};

export default new Vuex.Store<RootState>(store);
