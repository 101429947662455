<template>
  <FieldArray :name="name" v-slot="{ fields }">
    <div
      class="space-y-4 flex flex-col"
      v-for="(field, idx) in fields"
      :key="field.key"
    >
      <div
        class="flex flex-col w-full bg-white border-l border-r border-b border-gray-400 px-3"
      >
        <div class="flex flex-row space-x-4 justify-between w-full items-start">
          <div class="w-full py-3">
            <div class="">
              <Field
                as="textarea"
                disabled
                class="bg-white w-full border-gray-400 text-sm resize-none"
                :name="`${name}[${idx}].question_content`"
                :rows="3"
              />
            </div>
          </div>
          <div v-show="false" class="w-1/12">
            <Field
              disabled
              type="hidden"
              class="bg-white w-full border-gray-400 text-sm"
              :name="`${name}[${idx}].question_id`"
            />
          </div>
          <div class="flex flex-col justify-center py-2 items-center">
            <div class="w-5/12 flex justify-center items-center">
              <CustomRenderRadio
                v-for="item in radio"
                :key="item.value"
                :name="`${name}[${idx}].answer_value`"
                :type="item.type"
                :value="item.value"
              />
            </div>
            <div
              v-if="errors && Boolean(errors[`${name}[${idx}].answer_value`])"
              class="flex w-full flex-row space-x-1 items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="text-xs text-red-500">The answer is required</span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="p-2">
            <Field
              as="textarea"
              rows="4"
              maxlength="280"
              style="resize: none"
              :placeholder="isAnswered ? '' : 'Please, add an answer'"
              class="w-full focus:outline-none focus:ring-2 focus:ring-black text-sm text-gray-500 p-2 bg-gray-100 focus:bg-transparent rounded-2xl h-28"
              :name="`${name}[${idx}].answer_content`"
            />
          </div>
        </div>
      </div>
    </div>
  </FieldArray>
</template>

<script>
import { defineComponent } from "vue";
import { FieldArray, Field } from "vee-validate";
import CustomRenderRadio from "../renderCustomRadio/index.vue";

const AnswerArrayField = defineComponent({
  name: "AnswerArrayField",
  props: {
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isAnswered: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Field,
    FieldArray,
    CustomRenderRadio,
  },
  setup() {
    const radio = [
      { value: "na", type: "na" },
      { value: "not_a_fit", type: "thumbs-down" },
      { value: "moderate", type: "thumbs-up" },
      { value: "strong", type: "star" },
    ];
    return { radio };
  },
});

export default AnswerArrayField;
</script>
