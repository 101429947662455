import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nylas_composer = _resolveComponent("nylas-composer")!

  return (_ctx.nylasAccessToken)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_nylas_composer, {
          class: "mt-4",
          ref: "composer",
          id: "2e2e4e41-1ca3-4234-8dfa-8ce17da55bb4",
          access_token: _ctx.nylasAccessToken,
          show_header: "false",
          show_from: "false",
          show_cc_button: "false",
          show_bcc_button: "false"
        }, null, 8, ["access_token"])
      ]))
    : _createCommentVNode("", true)
}