
import { defineComponent, onMounted, reactive, ref, Ref } from "vue";
import VueTableLite from "vue3-table-lite/ts";
import { deleteRole, getRoles } from "@/services/roles";
import { createToastAxelerate } from "@/utils/toast";
//import DateFormatter from "@/components/dateFormatter/index.vue";
//import { createToastAxelerate } from "@/utils/toast";
import Paginator from "@/components/paginator/index.vue";

export default defineComponent({
  name: "Roles Table",
  props: {
    company_id: {
      type: String,
      default: "",
    },
  },
  components: {
    VueTableLite,
    Paginator,
  },
  setup(props) {
    const loading = ref(false);
    const dropdownItem = ref("");
    const showDropdown = ref(false);
    const showTooltip = ref("");
    const showEmployees = ref("");
    const tableData: Ref<Array<any>> = ref([]);
    const page: Ref<number> = ref(1);
    const pagination: Ref<any> = ref({});
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
          isKey: true,
          width: "10%",
        },
        {
          label: "Department",
          field: "department",
          sortable: true,
          width: "10%",
        },
        {
          label: "Benefits",
          field: "benefits",
          sortable: true,
          width: "10%",
        },
        {
          label: "Employees",
          field: "employees",
          sortable: true,
          width: "10%",
        },
        {
          label: "",
          field: "menu",
          width: "1%",
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "title",
        sort: "asc",
      },
    }) as any;

    async function getAllRoles() {
      table.isLoading = true;
      const data = await getRoles(props.company_id);
      if (data) {
        tableData.value = data.positions;
        pagination.value = data.meta.pagination;
        setTable(data.positions);
      }
      table.isLoading = false;
    }

    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    function search(event: any) {
      const value = event.target.value.toLowerCase();
      const data = tableData.value.filter(function (row) {
        return row.name.toLowerCase().includes(value);
      });
      setTable(data);
    }

    function openDropdown(id: string) {
      if (dropdownItem.value === "") {
        dropdownItem.value = id;
      } else {
        dropdownItem.value = "";
      }
      showDropdown.value = true;
    }

    async function removeRole(id: string) {
      loading.value = true;
      const data = await deleteRole(id);
      if (data) {
        getAllRoles();
        createToastAxelerate("Role deleted successfully", "", "success");
      } else {
        openDropdown("");
      }
      loading.value = false;
    }

    function updateHandler(e: any) {
      page.value = e;
      getAllRoles();
    }

    onMounted(() => {
      getAllRoles();
    });

    return {
      table,
      search,
      openDropdown,
      showDropdown,
      dropdownItem,
      removeRole,
      showTooltip,
      showEmployees,
      pagination,
      page,
      updateHandler,
    };
  },
});
