import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "inline" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "font-semibold truncate text-left" }
const _hoisted_6 = {
  key: 1,
  class: "font-semibold text-ellipsis overflow-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.image,
            class: _normalizeClass(["rounded-full", { 'h-18 w-18': _ctx.isBigCard, 'h-12 w-12': !_ctx.isBigCard }])
          }, null, 10, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["flex justify-center items-center bg-primary text-white rounded-full uppercase", { 'h-16 w-16 text-2xl': _ctx.isBigCard, 'h-12 w-12': !_ctx.isBigCard }])
          }, _toDisplayString(_ctx.initials()), 3))
    ]),
    (_ctx.showName)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex flex-col text-left ml-4", { 'text-2xl': _ctx.isBigCard }])
        }, [
          (_ctx.id)
            ? (_openBlock(), _createElementBlock("button", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.companyName), 1)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.companyName), 1))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}