
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  Ref,
  ref,
  watch,
  watchEffect,
} from "vue";
import VueTableLite from "vue3-table-lite/ts";
import ModalHiringFee from "@/components/modalHiringFee/index.vue";
import { HiringFeeAPI } from "@/services/hiringFees";
import { createToastAxelerate } from "../../utils/toast";
import { useStore } from "vuex";
import Paginator from "@/components/paginator/index.vue";
import SearchInput from "../../components/searchInput/index.vue";
import { removeBlankProperties } from "@/utils/params";

const HiringFees = defineComponent({
  name: "HiringFees",
  components: {
    VueTableLite,
    ModalHiringFee,
    Paginator,
    SearchInput,
  },
  props: {},
  setup() {
    const loading = ref(false);
    const openModal = ref(false);
    const store = useStore();
    const tableData: Ref<Array<any>> = ref([]);
    const page: Ref<number> = ref(1);
    const pagination: Ref<any> = ref({});
    const filters = ref({});
    const inputValue = ref("");
    const hiringFee = ref({});
    const openDropdown = ref("");
    const currentDropdown = ref("");
    store.commit("SET_REFRESH", false);
    const refresh = computed(() => store.getters.getRefresh);
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Company",
          field: "company",
          isKey: true,
          width: "20%",
        },
        {
          label: "Name",
          field: "name",
          isKey: true,
          width: "18%",
        },
        {
          label: "Fee",
          field: "title",
          isKey: false,
          width: "30%",
        },
        {
          label: "Original amount",
          field: "original_amount",
          isKey: true,
          width: "15%",
        },
        {
          label: "Amount",
          field: "amount",
          isKey: true,
          width: "25%",
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          width: "24%",
        },
        {
          label: "",
          field: "menu",
          sortable: true,
          width: "10%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });

    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    const closeMenu = () => {
      currentDropdown.value = "";
    };

    const openDropDown = (data: any) => {
      hiringFee.value = data;
      currentDropdown.value = data.id;
    };

    function deleteExpense(id: string) {
      HiringFeeAPI.deleteHiringFee(id)
        .then(() => {
          getHiringFees();
          createToastAxelerate(
            "Hiring Fees",
            "The hiring fee has been deleted successfully",
            "success"
          );
        })
        .catch(() => {
          createToastAxelerate("Hiring Fees", "An error has ocurred", "danger");
        });
    }

    function getHiringFees() {
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      HiringFeeAPI.getHiringFees(_filters, page.value)
        .then((response: any) => {
          tableData.value = response.data.expenses;
          pagination.value = response.data.meta.pagination;
          table.isLoading = false;
          setTable(tableData.value);
        })
        .catch((error) => {
          const {
            response: {
              data: { errors },
            },
          } = error;
          createToastAxelerate("Hiring Fees", errors[0], "danger");
        });
    }

    watchEffect(() => {
      if (refresh.value) {
        getHiringFees();
        store.commit("SET_REFRESH", false);
      }

      watch(
        () => refresh.value,
        () => {
          store.commit("SET_REFRESH", false);
        }
      );
    });

    function searchTitle(event: any) {
      const value = event.target.value.toLowerCase();
      const data = tableData.value.filter(function (row) {
        return (
          row.company.name.toLowerCase().includes(value) ||
          row.first_name.toLowerCase().includes(value) ||
          row.last_name.toLowerCase().includes(value) ||
          row.title.toLowerCase().includes(value) ||
          row.currency.toLowerCase().includes(value) ||
          row.id.includes(value)
        );
      });
      setTable(data);
    }

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
      });
    });

    function updateHandler(e: any) {
      page.value = e;
      getHiringFees();
    }

    onMounted(() => {
      getHiringFees();
    });

    return {
      loading,
      table,
      hiringFee,
      openModal,
      tableData,
      openDropdown,
      currentDropdown,
      deleteExpense,
      closeMenu,
      openDropDown,
      searchTitle,
      page,
      pagination,
      updateHandler,
      filters,
      inputValue,
      getHiringFees,
    };
  },
});
export default HiringFees;
