
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import DropDown from "../selectDropdown/index.vue";
import Button from "../button/index.vue";
import { useField, useForm } from "vee-validate";
import { JobsAPI } from "../../services/jobs/index";
import { useState } from "../../composables/useState";
import { buildJobs, buildCompany } from "../../utils/jobs";
import * as yup from "yup";
import { createToastAxelerate } from "../../utils/toast";
import { useRoute } from "vue-router";
import { CompanyAPI } from "@/services/companies";
import { useStore } from "vuex";

const AddCandidateInJob = defineComponent({
  name: "AddCandidateInJob",
  components: {
    // DropDown,
    Button,
  },
  emits: ["closeModal", "reloadCandidate"],
  setup(props, context) {
    const [jobs, setJobs] = useState([]);
    const isLoadingButton = ref(false);
    const [companies, setCompanies] = useState([]);
    const route = useRoute();
    const store = useStore();
    const userid = computed(() => store.state.profile.data.id);
    const candidateId = route?.params?.id;
    const schema = yup.object({
      company_id: yup.string().required().label("company"),
      job_id: yup.string().required().label("Job"),
    });

    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: company_id } = useField("company_id");
    const { value: job_id } = useField("job_id");
    const onSubmit = handleSubmit((values) => {
      isLoadingButton.value = true;
      JobsAPI.postCandidateToJob(values.job_id, candidateId)
        .then(async () => {
          await context.emit("closeModal");
          await context.emit("reloadCandidate");
          createToastAxelerate(
            "Add candidate",
            "The candidate has been added to job",
            "success"
          );
          isLoadingButton.value = false;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          isLoadingButton.value = false;
          createToastAxelerate("Add candidates", errors[0], "danger");
        });
    });
    onMounted(() => {
      CompanyAPI.getListCompanies(userid.value)
        .then((response: any) => {
          setCompanies(buildCompany(response.data));
          setJobs([]);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Add candidates", errors[0], "danger");
        });
    });

    const getJobs = () => {
      JobsAPI.getJobsByCompany(String(company_id.value))
        .then((response: any) => {
          setJobs(buildJobs(response.data));
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Add candidates", errors[0], "danger");
        });
    };

    watch(company_id, (newVal) => {
      jobs.value = [];
      job_id.value = "";
      getJobs();
    });

    return {
      onSubmit,
      jobs,
      meta,
      job_id,
      companies,
      setCompanies,
      company_id,
      isLoadingButton,
    };
  },
});

export default AddCandidateInJob;
