/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";
import { makeFilter } from "@/utils/params";
import { instanceLoggedAPI, instanceAuthAPI } from "../axiosConfig";
export default class CandidatesAPIService {
  private axiosInstance: AxiosInstance;
  private publicInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.publicInstance = instanceAuthAPI;
  }

  async getCandidates(filters: any, page = 1): Promise<unknown> {
    return this.axiosInstance.get(
      `/job_candidates?page=${page}&${makeFilter(filters)}`
    );
  }

  async getCandidatesIndex(filters: any, page = 1): Promise<unknown> {
    return this.axiosInstance.get(
      `/candidates?page=${page}&${makeFilter(filters)}`
    );
  }
  async getPublicProfile(candiate_id: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/candidates/${candiate_id}?complete_profile=true`
    );
  }

  async getCompanyCandidate(candidate_id: any): Promise<unknown> {
    return this.axiosInstance.get(`/company_candidates/${candidate_id}`);
  }

  async createCandidate(data: any): Promise<unknown> {
    const formData = new FormData();
    formData.append("candidate[first_name]", data.first_name);
    formData.append("candidate[last_name]", data.last_name);
    formData.append("candidate[email]", data.email);
    formData.append("candidate[phone]", data.phone);
    formData.append("candidate[place_id]", data.place_id);
    formData.append("candidate[position]", data.position);
    formData.append("candidate[link]", data.link ? data.link : "");
    formData.append(
      "candidate[show_monthly_salaries]",
      data.show_monthly_salary
    );
    if (data.summary) formData.append("candidate[summary]", data.summary);
    if (data.resume) formData.append("candidate[resume]", data.resume);
    if (data.documents) formData.append("candidate[documents]", data.documents);
    if (data.current_salary)
      formData.append("candidate[current_salary]", data.current_salary);
    if (data.current_salary_currency)
      formData.append(
        "candidate[current_salary_currency]",
        data.current_salary_currency
      );
    if (data.salary_expectation)
      formData.append("candidate[salary_expectation]", data.salary_expectation);
    if (data.salary_currency)
      formData.append("candidate[salary_currency]", data.salary_currency);

    return this.axiosInstance.post("/candidates/", formData);
  }

  async updateCandidate(candidateId: string, data: any): Promise<unknown> {
    const formData = new FormData();
    formData.append("candidate[first_name]", data.first_name);
    formData.append("candidate[last_name]", data.last_name);
    formData.append("candidate[email]", data.email);
    formData.append("candidate[phone]", data.phone);
    formData.append("candidate[position]", data.position);
    formData.append("candidate[place_id]", data.place_id);
    formData.append("candidate[link]", data.link ? data.link : "");
    formData.append(
      "candidate[show_monthly_salaries]",
      data.show_monthly_salary
    );
    if (data.summary) formData.append("candidate[summary]", data.summary);
    if (data.resume) formData.append("candidate[resume]", data.resume);
    if (data.documents) formData.append("candidate[documents]", data.documents);
    if (data.current_salary)
      formData.append("candidate[current_salary]", data.current_salary);
    if (data.current_salary_currency)
      formData.append(
        "candidate[current_salary_currency]",
        data.current_salary_currency
      );
    if (data.salary_expectation)
      formData.append("candidate[salary_expectation]", data.salary_expectation);
    if (data.salary_currency)
      formData.append("candidate[salary_currency]", data.salary_currency);
    return this.axiosInstance.put(`/candidates/${candidateId}`, formData);
  }

  async updateCandidateHistories(
    candidateId: string,
    data: any
  ): Promise<unknown> {
    return this.axiosInstance.put(`/candidates/${candidateId}`, data);
  }

  async getCandidateLocations(userId: string): Promise<unknown> {
    return this.axiosInstance.get(`/${userId}/candidate_location_list`);
  }
}
export const CandidatesAPI = new CandidatesAPIService();
