
import { defineComponent } from "vue";

export default defineComponent({
  name: "Company Card",
  props: {
    companyName: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    showName: {
      type: Boolean,
      default: true,
    },
    isBigCard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    function initials(): string {
      let init = "";
      init += props.companyName[0]
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      if (props.companyName?.length > 1) {
        init += props.companyName[1]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      }
      return init;
    }

    return { initials };
  },
});
