<template>
  <ContentEditable
    tag="ul"
    class="w-full h-28 px-8 py-5 sticky-div list-disc rounded-xl disabled:cursor-not-allowed"
    :placeholder="placeholder"
    :contenteditable="!disabled"
    :modelValue="modelValue"
    @input="emptied"
    @update:modelValue="$emit('update:modelValue', $event ? $event : '')"
    :noNL="false"
    :noHTML="false"
  />
</template>

<script>
import ContentEditable from "vue-contenteditable";
import { ref, defineComponent } from "vue";

const Bullets = defineComponent({
  name: "Bullets",
  inheritAttrs: false,
  emits: ["update:modelValue", "setDefault"],
  components: {
    ContentEditable,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },

  setup(props, context) {
    const menuContainer = ref(null);
    const message = ref("");
    const isEmpty = (str) => !str.trim().length;
    const focusValue = () => {
      if (props.modelValue === "") {
        context.emit("update:modelValue", "<li>&#160;&#160;</li>");
      }
    };
    const emptied = async (e) => {
      if (isEmpty(e.target.innerHTML)) {
        context.emit("update:modelValue", "<li>&#160;&#160;</li>");
      }
    };
    return {
      message,
      focusValue,
      menuContainer,
      emptied,
    };
  },
});

export default Bullets;
</script>

<style sccoped>
.sticky-div {
  padding: 0.3em 0.5em 0 0.5em;
  text-align: left;
  overflow-y: auto;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: relative;
  color: gray;
}

[contenteditable]:focus {
  background-color: transparent;
  border: 3px solid black;
}

[contenteditable] {
  background-color: #eff0f6;
  border-color: #eff0f6;
}
</style>
