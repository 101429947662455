/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";
export default class CompaniesAPIService {
  private axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }
  async getCompanies(userId: string): Promise<unknown> {
    return this.axiosInstance.get(`/${userId}/company_list`);
  }
  async getAllCompanies(filters?: any, page?: number): Promise<unknown> {
    return this.axiosInstance.get(
      `/companies?page=${page}&${makeFilter(filters)}`
    );
  }
  async getListCompanies(user_id: string): Promise<unknown> {
    return this.axiosInstance.get(`/${user_id}/company_list`);
  }
  async getCompany(companyId: string): Promise<any> {
    return this.axiosInstance.get(`/companies/${companyId}`);
  }
  async savePaymentContacts(companyId: string, body: any): Promise<any> {
    return this.axiosInstance.put(`/companies/${companyId}`, body);
  }
}
export const CompanyAPI = new CompaniesAPIService();
