
import { defineComponent } from "vue";

const Link = defineComponent({
  name: "Link",
  props: {
    variant: {
      type: String,
      default: "",
    },
    labelSize: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
    },
    sizeLabel: {
      type: String,
      default: "text-base",
    },
    route: {
      type: String,
      default: "/",
    },
  },
  setup() {
    const buildStyleByVariant = (variant: string, sizeText: string) => {
      switch (variant) {
        case "contained":
          return `btn-filled-sm flex flex-row items-center disabled:opacity-50 justify-between truncate ${sizeText} bg-primary rounded-full transition duration-150 ease-in`;
        case "outlined":
          return `btn-outline-sm flex flex-row items-center disabled:opacity-50 justify-between px-4 py-2 text-primary truncate ${sizeText} border-2 rounded-full transition duration-150 ease-in`;
        case "contained-lg":
          return `btn-filled-lg flex flex-row items-center disabled:opacity-50 justify-between truncate ${sizeText} bg-primary rounded-full transition duration-150 ease-in`;
        case "outlined-lg":
          return `btn-outline-lg flex flex-row items-center disabled:opacity-50 justify-between px-4 py-2 text-primary truncate ${sizeText} border-2 rounded-full transition duration-150 ease-in`;
        default:
          return `btn-text flex flex-row items-center p-2 text-primary hover:bg-gray-100 disabled:text-white ${sizeText}`;
      }
    };
    return { buildStyleByVariant };
  },
});

export default Link;
