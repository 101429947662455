<template>
  <TextField ref="inputRef" type="text" label="Amount" :disabled="disabled" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { defineComponent } from "vue";
import TextField from "@/components/textField/index.vue";

export default defineComponent({
  name: "Currency Field Hotfix",
  inheritAttrs: false,
  components: {
    TextField,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hiddenCurrencySymbol: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({
      currency: "USD",
      precision: 2,
      hideCurrencySymbolOnFocus: false,
      currencyDisplay:
        props.hiddenCurrencySymbol === true ? "hidden" : undefined,
    });

    return { inputRef };
  },
});
</script>
