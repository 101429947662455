export enum skillsEnum {
  PHP = "Php",
  JAVASCRIPT = "JavaScript",
  RUBY = "Ruby",
  PYTHON = "Python",
  REACT = "React",
  NET = ".Net",
  ANGULAR = "Angular",
  GO = "Go",
  C_SHARP = "C#",
  TYPESCRIPT = "TypeScript",
  BASH = "Bash",
  AWS = "AWS",
  C = "C++",
  CSS = "CSS",
  DOCKER = "Docker",
  HTML = "HTML",
  JAVA = "Java",
  NODE = "Node js",
  SQL = "SQL",
  SWIFT = "Swift",
  KOTLIN = "Kotlin",
  KUBERNETES = "Kubernetes",
  SCALA = "Scala",
  NOSQL = "NoSQL",
  SHELL = "Shell",
}

export default skillsEnum;
