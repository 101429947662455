<template>
  <ContentEditable
    tag="div"
    :class="className"
    :placeholder="placeholder"
    :contenteditable="true"
    :modelValue="modelValue"
    @keypress="onKeypress"
    @paste="onPaste"
    @update:modelValue="(e) => $emit('update:modelValue', e)"
    :noNL="false"
    :noHTML="true"
  />
</template>
<script>
import ContentEditable from "vue-contenteditable";
import { defineComponent } from "vue";

const InputUniversalCopy = defineComponent({
  name: "inputUniversalCopy",
  inheritAttrs: false,
  emits: ["update:modelValue", "onKeypress", "onPaste"],
  components: {
    ContentEditable,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    className: {
      Type: String,
      default:
        "text-xs p-2 rounded-2xl placeholder-gray-800 w-48 h-8 truncate border border-transparent focus:border-black focus:outline-none",
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },
  setup(props, context) {
    const onKeypress = (e) => {
      context.emit("onKeypress", e);
    };
    const onPaste = (e) => {
      context.emit("onPaste", e);
    };
    return { onKeypress, onPaste };
  },
});
export default InputUniversalCopy;
</script>

<style sccoped>
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: relative;
  color: gray;
}
[contenteditable]:focus {
  background-color: transparent;
}
[contenteditable] {
  background-color: #eff0f6;

  border-color: #eff0f6;
}
</style>
