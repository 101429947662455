
import { defineComponent } from "vue";

export default defineComponent({
  name: "User Card",
  props: {
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    function fullName(): string {
      const original = props.firstName + " " + props.lastName;
      const name = original.toLowerCase();
      const names = name.split(" ");
      for (let i = 0; i < names.length; i++) {
        names[i].toLowerCase();
        if (names[i]) {
          names[i] = names[i][0].toUpperCase() + names[i].substr(1);
        }
      }
      return names.join(" ");
    }
    function initials(): string {
      let init = "";
      if (props.firstName && props.lastName) {
        init += props.firstName[0]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        init += props.lastName[0]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      } else if (props.firstName) {
        init += props.firstName[0]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (props.firstName?.length > 1) {
          init += props.firstName[1]
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        }
      }
      return init;
    }

    return { fullName, initials };
  },
});
