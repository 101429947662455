
import { defineComponent } from 'vue-demi';

const ShowStages = defineComponent({
	name: 'ShowStages',
	props: {
		stages: {
			type: Array,
			default: () => [],
		},
		totalCandidates: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const rejected = props.stages.reduce((total: any, stage: any) => {
			return total + stage.drop_off_count + stage.rejected_count;
		}, 0);

		return {
			rejected,
		};
	},
});
export default ShowStages;
