import { MutationTree } from "vuex";
import { UserState } from "./types";

export enum UserMutations {
  SET_USEREMAIL = "SET_USEREMAIL",
  SET_USERID = "SET_USERID",
  SET_USERFIRSTNAME = "SET_USERFIRSTNAME",
  SET_USERLASTNAME = "SET_USERLASTNAME",
  SET_USERROLE = "SET_USERROLE",
  SET_COMPANYID = "SET_COMPANYID",
  SET_COMPANYNAME = "SET_COMPANYNAME",
}

export const mutations: MutationTree<UserState> = {
  [UserMutations.SET_USEREMAIL](state, payload: string) {
    state.email = payload;
  },
  [UserMutations.SET_USERID](state, payload: string) {
    state.user_id = payload;
  },
  [UserMutations.SET_USERFIRSTNAME](state, payload: string) {
    state.first_name = payload;
  },
  [UserMutations.SET_USERLASTNAME](state, payload: string) {
    state.last_name = payload;
  },
  [UserMutations.SET_USERROLE](state, payload: string) {
    state.role = payload;
  },
  [UserMutations.SET_COMPANYID](state, payload: string) {
    state.company_id = payload;
  },
  [UserMutations.SET_COMPANYNAME](state, payload: string) {
    state.company_name = payload;
  },
};
