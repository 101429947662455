
import { defineComponent, ref } from "vue";
import { addNomilineaID } from "@/services/talents";
import { useField, useForm } from "vee-validate";
import TextField from "@/components/textField/index.vue";
import * as yup from "yup";

export default defineComponent({
  name: "Add Nomilinea ID",
  components: {
    TextField,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: String,
      default: "",
    },
    refreshPage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:openModal", "update:refreshPage"],
  setup(props, { emit }) {
    const loading = ref(false);
    const error = ref(false);

    const schema = yup.object({
      employee_id: yup.string().required().label("Employee Id"),
    });
    const fieldError = ref(false);

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: employee_id } = useField("employee_id");

    const onSubmit = handleSubmit((values) => {
      const id = values.employee_id as string;
      addId(id);
    }, onInvalidSubmit);

    function onInvalidSubmit() {
      fieldError.value = true;
    }

    async function addId(employee_id: string) {
      loading.value = true;
      const data = await addNomilineaID(props.user_id, employee_id);
      if (data) {
        emit("update:refreshPage", true);
        closeModal();
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    function closeModal() {
      emit("update:openModal", false);
    }

    return {
      loading,
      error,
      fieldError,
      employee_id,
      onSubmit,
      closeModal,
    };
  },
});
