<template>
  <aside
    class="transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 py-4 px-4 space-y-5 shadow"
    :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
  >
    <span class="w-52 p-4 rounded text-sm font-semibold text-gray-600">
      Menu
    </span>
    <div v-if="role === 'internal_hr'" class="space-y-3">
      <router-link
        v-for="(item, index) in buildOptionsMenuHRManager"
        :key="`${item.label}-${index + 1}`"
        :to="item.route"
        class="hover:bg-gray-100 w-52 p-4 rounded flex text-gray-400"
        :class="{ 'bg-gray-100 text-gray-600': route?.name === item.routeName }"
      >
        <div class="flex flex-row items-center space-x-4">
          <img :src="item.src" />
          <span class="text-sm font-semibold">{{ item.label }}</span>
        </div>
      </router-link>
    </div>
    <div v-else-if="role === 'superadmin'" class="space-y-3">
      <router-link
        v-for="(item, index) in buildOptionsMenuSuperAdmin"
        :key="`${item.label}-${index + 1}`"
        :to="item.route"
        class="hover:bg-gray-100 w-52 p-4 rounded flex text-gray-400"
        :class="{ 'bg-gray-100 text-gray-600': route?.name === item.routeName }"
      >
        <div class="flex flex-row items-center space-x-4">
          <img :src="item.src" />
          <span class="text-sm font-semibold">{{ item.label }}</span>
        </div>
      </router-link>
    </div>
    <div v-else class="space-y-3">
      <router-link
        v-for="(item, index) in buildOptionsMenu"
        :key="`${item.label}-${index + 1}`"
        :to="item.route"
        class="hover:bg-gray-100 w-52 p-4 rounded flex text-gray-400"
        :class="{ 'bg-gray-100 text-gray-600': route?.name === item.routeName }"
      >
        <div class="flex flex-row items-center space-x-4">
          <img :src="item.src" />
          <span class="text-sm font-semibold">{{ item.label }}</span>
        </div>
      </router-link>
    </div>
    <div class="w-full" :class="role === 'superadmin' ? '' : 'fixed bottom-0'">
      <span class="w-52 p-4 rounded text-sm font-semibold text-gray-600">
        Admin
      </span>
      <div class="py-5">
        <router-link
          to="/"
          class="hover:bg-gray-100 w-52 p-4 rounded flex text-gray-400"
          :class="{ 'bg-gray-100 text-gray-600': route?.name === 'Settings' }"
        >
          <div class="flex flex-row items-center space-x-4">
            <img :src="SettingsIcon" />
            <span class="text-sm font-semibold">Settings</span>
          </div>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import HomeIcon from "@/assets/svgs/home.svg";
import JobsIcon from "@/assets/svgs/jobs.svg";
import CandidatesIcon from "@/assets/svgs/candidates.svg";
import ClientsIcon from "@/assets/svgs/clients.svg";
import SettingsIcon from "@/assets/svgs/settings.svg";
import ClipboardIcon from "@/assets/svgs/clipboard.svg";
import TalentIcon from "@/assets/svgs/talent.svg";
import ExpensesIcon from "@/assets/svgs/expenses.svg";
import PayrollsIcon from "@/assets/svgs/payrolls.svg";
import PTOIcon from "@/assets/svgs/pto.svg";
import BellIcon from "@/assets/svgs/bell-gray.svg";
import { useStore } from "vuex";

const ResponsiveNavBar = defineComponent({
  name: "ResponsiveNavBar",
  emits: ["closeNav"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const closeNav = () => context.emit("closeNav");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const role = computed(() => store.state.profile.data.roles[0]);

    const buildOptionsMenuHRManager = [
      { routeName: "Home", label: "Home", route: "/", src: HomeIcon },
      {
        routeName: "Payrolls",
        label: "Payrolls",
        route: "/payroll",
        src: PayrollsIcon,
      },
      {
        routeName: "Talent",
        label: "Talent",
        route: "/talent",
        src: TalentIcon,
      },
      {
        routeName: "Notifications",
        label: "Notifications",
        route: "/notifications",
        src: BellIcon,
      },
      {
        routeName: "Expenses",
        label: "Expenses",
        route: "/expenses",
        src: ExpensesIcon,
      },
      {
        routeName: "PTO",
        label: "Time-off Management",
        route: "/",
        src: PTOIcon,
      },
    ];
    const buildOptionsMenu = [
      { routeName: "Home", label: "Home", route: "/", src: HomeIcon },
      {
        routeName: "Clients",
        label: "Clients",
        route: "/clients",
        src: ClientsIcon,
      },
      {
        routeName: "Jobs",
        label: "Jobs",
        route: "/jobs",
        src: JobsIcon,
      },
      {
        routeName: "Candidates",
        label: "Candidates",
        route: "/candidates",
        src: CandidatesIcon,
      },
      {
        routeName: "Notifications",
        label: "Notifications",
        route: "/notifications",
        src: BellIcon,
      },
      {
        routeName: "Candidates Evaluations",
        label: "Talent evaluations",
        route: "/talent-evaluation",
        src: ClipboardIcon,
      },
    ];
    const buildOptionsMenuSuperAdmin = [
      { routeName: "Home", label: "Home", route: "/", src: HomeIcon },
      {
        routeName: "Clients",
        label: "Clients",
        route: "/clients",
        src: ClientsIcon,
      },
      {
        routeName: "Jobs",
        label: "Jobs",
        route: "/jobs",
        src: JobsIcon,
      },
      {
        routeName: "Candidates",
        label: "Candidates",
        route: "/candidates",
        src: CandidatesIcon,
      },
      {
        routeName: "Notifications",
        label: "Notifications",
        route: "/notifications",
        src: BellIcon,
      },
      {
        routeName: "Candidates Evaluations",
        label: "Talent evaluations",
        route: "/talent-evaluation",
        src: ClipboardIcon,
      },
      {
        routeName: "Hiring Fees",
        label: "Hiring Fees",
        route: "/hiring-fees",
        src: ExpensesIcon,
      },
      {
        routeName: "Payrolls",
        label: "Payrolls",
        route: "/payroll",
        src: PayrollsIcon,
      },
      {
        routeName: "Talent",
        label: "Talent",
        route: "/talent",
        src: TalentIcon,
      },
      {
        routeName: "Expenses",
        label: "Expenses",
        route: "/expenses",
        src: ExpensesIcon,
      },
      {
        routeName: "PTO",
        label: "Time-off Management",
        route: "/",
        src: PTOIcon,
      },
    ];

    return {
      closeNav,
      buildOptionsMenu,
      router,
      route,
      SettingsIcon,
      role,
      buildOptionsMenuHRManager,
      buildOptionsMenuSuperAdmin,
    };
  },
});

export default ResponsiveNavBar;
</script>
