<template>
  <Loading
    v-model:active="isLoading"
    :can-cancel="false"
    loader="spinner"
    color="#8908F9"
    :width="128"
    :height="128"
    :is-full-page="true"
  />
  <div class="w-full animate__animated animate__fadeIn" v-if="!loading">
    <div class="flex flex-row space-x-3 items-center">
      <div
        class="flex justify-center items-center rounded-full bg-primary text-white w-8 h-8 p-2 text-xs"
      >
        {{
          getInitials(`${currentUser?.first_name} ${currentUser?.last_name}`)
        }}
      </div>
      <span class="font-bold text-sm"
        >Add a comment
        <span class="pl-1 font-normal">
          Candidates never see comments.</span
        ></span
      >
    </div>
    <div class="w-full pt-3">
      <form @submit="onSubmit">
        <div class="w-full pt-2 rounded-lg relative">
          <Mentionable
            v-if="!loading"
            v-model="content"
            :users="users"
            placeholder="Use @ to mention a collaborator"
          />
          <div class="w-full mt-2 flex flex-row justify-between items-center">
            <div class="w-full flex flex-col">
              <div class="flex flex-row space-x-2" v-if="fileArray.length > 0">
                <div
                  v-for="(_file, i) in fileArray.slice(0, 3)"
                  :key="`array-${i + 1}`"
                >
                  <div
                    class="px-3 py-2 flex flex-row text-xs items-center bg-gray-200 rounded-full border border-gray-300 text-primary"
                  >
                    <svg
                      class="h-4 w-4 mr-2"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                      <circle cx="8.5" cy="8.5" r="1.5" />
                      <polyline points="21 15 16 10 5 21" />
                    </svg>
                    <span class="w-16 truncate">{{ _file.name }}</span>
                    <svg
                      @click="removeFiles(i)"
                      class="h-5 w-5 text-gray-500 ml-3 cursor-pointer"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <line x1="15" y1="9" x2="9" y2="15" />
                      <line x1="9" y1="9" x2="15" y2="15" />
                    </svg>
                  </div>
                </div>
                <span
                  v-if="fileArray.length > 3"
                  class="px-3 py-2 flex flex-row text-xs items-center text-primary border border-gray-300 bg-gray-200 rounded-full"
                  >+{{ fileArray.length - 3 }}</span
                >
              </div>
            </div>
            <div
              class="w-full flex flex-row justify-end space-x-3 items-center"
            >
              <label
                class="flex items-center text-primary tracking-wide cursor-pointer hover:text-primary-dark"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  />
                </svg>
                <input
                  type="file"
                  class="hidden"
                  accept="image/*"
                  @change.stop="handleFile"
                />
              </label>
              <button
                class="shadow-lg text-sm bg-primary rounded-full px-3 py-2 text-white disabled:opacity-60 disabled:cursor-not-allowed"
                :disabled="!fileArray.length && !content.length"
              >
                Send comment
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-end items-center pt-3 w-full">
          <div v-if="false" class="flex items-center justify-center">
            <label
              class="flex items-center bg-white text-primary tracking-wide cursor-pointer hover:bg-gray-100 hover:text-primary-dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                />
              </svg>
              <span class="pl-1 text-base leading-normal">Attach a file</span>
              <input
                type="file"
                class="hidden"
                accept="image/*"
                @change="handleFile"
              />
            </label>
          </div>
          <div class="flex flex-row items-center space-x-3">
            <span v-if="false" class="opacity-50">Visible to</span>
            <DropDown
              v-if="false"
              :options="visibilities"
              className="select-simple"
              label="Select the visibility"
              name="visibility"
            />
            <div v-if="false">
              <Button
                :disabled="content === ''"
                variant="contained"
                type="submit"
                label="Submit comment"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-5" v-else>
    <Skeleton option="card"></Skeleton>
  </div>
</template>

<script>
import { computed, defineComponent, ref, onBeforeMount } from "vue";
import { FieldArray, useForm } from "vee-validate";
import Button from "../button/index.vue";
import DropDown from "../selectDropdown/index.vue";
import { useState } from "../../composables/useState";
import { useStore } from "vuex";
import { getInitials } from "../../utils/functions";
import { CommentsAPI } from "../../services/comments/index";
import { createToastAxelerate } from "../../utils/toast";
import Mentionable from "../mentions/index.vue";
import { MembersAPI } from "../../services/accountMembers/index";
import { buildUsers } from "../../utils/users";
import { useRoute } from "vue-router";
import Skeleton from "@/components/skeleton/index.vue";
import Loading from "vue-loading-overlay";

const CommentsForm = defineComponent({
  name: "CommentsForm",
  components: {
    Button,
    DropDown,
    Mentionable,
    Skeleton,
    Loading,
  },
  setup() {
    const { handleSubmit, resetForm, meta } = useForm({});
    const route = useRoute();
    const fileArray = ref([]);
    const jobId = route.params.id;
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    onBeforeMount(() => {
      setLoading(true);
      MembersAPI.getTaggableMembers(jobId).then((res) => {
        setUsers(buildUsers(res.data.users));
        setLoading(false);
      });
    });
    const [file, setFile] = useState([]);
    const [reload, setReload] = useState(true);
    const content = ref("");
    const store = useStore();
    const currentUser = computed(() => store.state.profile.data);
    const candidateId = computed(() => store.state.candidateInfo.data?.id);
    const parseContent = ref();
    const visibilities = [
      { id: "everyone", name: "Everyone" },
      { id: "only_me", name: "Only me" },
    ];
    const isLoading = ref(false);
    const handleFile = (e) => {
      setFile(e.target.files[0]);
      fileArray.value.push(file.value);
    };
    const onSubmit = handleSubmit((values) => {
      isLoading.value = true;
      setReload(false);

      const buildResponse = {
        comment: {
          ...values,
          images: fileArray.value,
          content: content.value.replace(/<[^>]+>/g, ""),
          mentions: content.value.match(
            /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
          ),
        },
      };
      resetForm();
      content.value = "";
      fileArray.value = [];
      CommentsAPI.postComment(candidateId.value, buildResponse)
        .then(() => {
          resetForm();
          setReload(true);
          createToastAxelerate(
            "Comments",
            "The comment has been published.",
            "success"
          );
          store.dispatch("getTimeLine", candidateId.value);
          isLoading.value = false;
        })
        .catch(() => {
          createToastAxelerate(
            "Comments",
            "Oops! an error has ocurred.",
            "danger"
          );
          isLoading.value = false;
        });
    });
    const removeFiles = (index) => {
      fileArray.value.splice(index, 1);
    };
    return {
      onSubmit,
      reload,
      handleFile,
      file,
      visibilities,
      currentUser,
      getInitials,
      meta,
      content,
      parseContent,
      users,
      loading,
      setFile,
      fileArray,
      removeFiles,
      isLoading,
    };
  },
});
export default CommentsForm;
</script>
