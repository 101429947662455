<template>
  <div
    class="flex flex-col md:flex-row lg:flex-row items-start lg:space-x-5 mt-2 lg:mt-0 md:mt-0 md:space-x-5 w-full"
  >
    <div class="w-full md:w-1/2 lg:w-1/2 lg:space-y-5 md:space-y-5">
      <div class="bg-white shadow rounded-2xl p-5">
        <PersonalDetails />
      </div>
      <div class="bg-white shadow rounded-2xl p-5 mt-4 lg:mt-0 md:mt-0">
        <SettingsChangePassword />
      </div>
    </div>
    <div class="w-full lg:w-1/2 md:w-1/2 mt-4 lg:mt-0 md:mt-0">
      <CalendarDetails />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PersonalDetails from "../personalDetails/index.vue";
import CalendarDetails from "../calendarDetails/index.vue";
import SettingsChangePassword from "@/components/settingsChangePassword/index.vue";

export default defineComponent({
  name: "PrincipalUserSettings",
  components: {
    SettingsChangePassword,
    PersonalDetails,
    CalendarDetails,
  },
});
</script>
