/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import {
  instanceLoggedAPI,
  instanceFormAPI,
  instanceLoggedTmpAPI,
} from "../axiosConfig";
export default class JobsAPIService {
  private axiosInstance: AxiosInstance;
  private instanceForm: AxiosInstance;
  private axiosTmpInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.instanceForm = instanceFormAPI;
    this.axiosTmpInstance = instanceLoggedTmpAPI;
  }
  async createJob(job: unknown): Promise<unknown> {
    return this.axiosInstance.post("/jobs", job);
  }
  async editJob(job: unknown, jobId: any): Promise<unknown> {
    return this.axiosInstance.put(`/jobs/${jobId}`, job);
  }
  async deactivateJob(jobId: string, data: any): Promise<unknown> {
    return this.axiosInstance.put(`/jobs/${jobId}/close_job`, data);
  }
  async getAllJobs(filters?: any, page = 1): Promise<unknown> {
    return this.axiosInstance.get(`/jobs?page=${page}&${makeFilter(filters)}`);
  }
  async getJobFunctions(): Promise<unknown> {
    return this.axiosInstance.get("/job_functions");
  }
  async getCompanyIndustries(): Promise<unknown> {
    return this.axiosInstance.get("/company_industries");
  }
  async getJobList(): Promise<unknown> {
    return this.axiosInstance.get("/job_list");
  }
  async getJobsByCompany(company_id: string): Promise<unknown> {
    return this.axiosInstance.get(`/job_list?company_id=${company_id}`);
  }
  async getJobsCandidates(jobId: string, filters: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${jobId}/job_candidates?${makeFilter(filters)}`
    );
  }
  async getJobTemplates(filters: any): Promise<unknown> {
    return this.axiosInstance.get(`/job_templates?${makeFilter(filters)}`);
  }
  async getOneTemplate(id: string): Promise<unknown> {
    return this.axiosInstance.get(`/job_templates/${id}`);
  }
  async updateCandidateStage(
    stageId: string,
    _payload?: any
  ): Promise<unknown> {
    _payload = _payload ? _payload : "";
    return this.instanceForm.put(
      `/job_candidate_stages/${stageId}?out_of_process=${_payload}`
    );
  }
  async getOneJob(id: number | string | any): Promise<unknown> {
    return this.axiosInstance.get(`/jobs/${id}`);
  }
  async addJobMembers(ids: unknown, id: any): Promise<unknown> {
    // This is a temporary instance.
    return this.axiosTmpInstance.post(`/jobs/${id}/job_members`, ids);
  }
  async addRecruiterToJob(
    idJob: string,
    idRecruiter: string
  ): Promise<unknown> {
    return this.axiosInstance.put(`/jobs/${idJob}`, {
      job: { responsible_recruiter_id: idRecruiter },
    });
  }
  async updateJob(job: unknown, id: number): Promise<unknown> {
    return this.axiosInstance.put(`/jobs/${id}`, job);
  }
  async getJobStages(): Promise<unknown> {
    return this.axiosInstance.get("/stages");
  }
  async getAllJobCandidates(id: number | string | any): Promise<unknown> {
    return this.axiosInstance.get(`/jobs/${id}/job_candidates`);
  }
  async deleteJobCanidate(
    id: number | string | any,
    idCandidate: number | string
  ): Promise<unknown> {
    return this.axiosInstance.delete(
      `/jobs/${id}/job_candidates/${idCandidate}`
    );
  }
  async updateJobCandidate(
    id: number | string | any,
    idCandidate: number | string,
    data: any
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("job_candidate[first_name]", data.first_name);
    formData.append("job_candidate[last_name]", data.last_name);
    formData.append("job_candidate[email]", data.email);
    formData.append("job_candidate[phone]", data.phone);
    formData.append("candidate[place_id]", data.place_id);
    formData.append("job_candidate[position]", data.position);
    formData.append("job_candidate[link]", data.link ? data.link : "");
    formData.append("job_candidate[is_visible]", data.is_visible);
    formData.append(
      "job_candidate[show_monthly_salaries]",
      data.show_monthly_salary
    );
    formData.append("override_candidates", data.override_candidate);
    if (data.summary) formData.append("job_candidate[summary]", data.summary);
    if (data.resume) formData.append("job_candidate[resume]", data.resume);
    if (data.documents)
      formData.append("job_candidate[documents]", data.documents);
    if (data.current_salary)
      formData.append("job_candidate[current_salary]", data.current_salary);
    if (data.current_salary_currency)
      formData.append(
        "job_candidate[current_salary_currency]",
        data.current_salary_currency
      );
    if (data.salary_expectation)
      formData.append(
        "job_candidate[salary_expectation]",
        data.salary_expectation
      );
    if (data.salary_currency)
      formData.append("job_candidate[salary_currency]", data.salary_currency);
    return this.axiosInstance.put(
      `/jobs/${id}/job_candidates/${idCandidate}`,
      formData
    );
  }

  async updateJobCandidateVisibility(
    id: number | string | any,
    idCandidate: number | string,
    candidate: any
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${id}/job_candidates/${idCandidate}`,
      candidate
    );
  }

  async updateJobCandidateHistory(
    id: number | string | any,
    idCandidate: number | string,
    candidate: any
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${id}/job_candidates/${idCandidate}`,
      candidate
    );
  }

  async getJobCandidate(
    id: number | string | any,
    idCandidate: number | string
  ): Promise<unknown> {
    return this.axiosInstance.get(`/jobs/${id}/job_candidates/${idCandidate}`);
  }
  async postJobCandidate(data: any, id: string): Promise<unknown> {
    const formData = new FormData();
    formData.append("job_candidate[first_name]", data.first_name);
    formData.append("job_candidate[last_name]", data.last_name);
    formData.append("job_candidate[email]", data.email);
    formData.append("job_candidate[phone]", data.phone);
    formData.append("job_candidate[position]", data.position);
    formData.append("job_candidate[place_id]", data.place_id);
    formData.append("job_candidate[link]", data.link);
    formData.append(
      "job_candidate[show_monthly_salaries]",
      data.show_monthly_salary
    );
    formData.append("job_candidate[is_visible]", data.isVisible);
    if (data.summary) formData.append("job_candidate[summary]", data.summary);
    if (data.resume) formData.append("job_candidate[resume]", data.resume);
    if (data.documents)
      formData.append("job_candidate[documents]", data.documents);
    if (data.current_salary)
      formData.append("job_candidate[current_salary]", data.current_salary);
    if (data.current_salary_currency)
      formData.append(
        "job_candidate[current_salary_currency]",
        data.current_salary_currency
      );
    if (data.salary_expectation)
      formData.append(
        "job_candidate[salary_expectation]",
        data.salary_expectation
      );
    if (data.salary_currency)
      formData.append("job_candidate[salary_currency]", data.salary_currency);
    return this.axiosInstance.post(`/jobs/${id}/job_candidates`, formData);
  }

  async uploadFile(company_candidate_id: string, data: any): Promise<unknown> {
    const formData = new FormData();

    if (data) {
      for (let i = 0; i < data.length; i++) {
        formData.append("[documents][]", data[i]);
      }
    }
    return this.instanceForm.post(
      `/company_candidates/${company_candidate_id}/candidate_documents`,
      formData
    );
  }
  async deleteFile(document_id: string): Promise<unknown> {
    return this.instanceForm.delete(`/candidate_documents/${document_id}`);
  }

  async postCandidateToJob(id: any, candidateId: any): Promise<unknown> {
    return this.instanceForm.post(`/jobs/${id}/add_candidate/${candidateId}`);
  }
  async getJobLocations(userId: string): Promise<unknown> {
    return this.axiosInstance.get(`/${userId}/location_list`);
  }
  async createJobComment(data: any, job_id: any): Promise<unknown> {
    return this.axiosInstance.post(`/add_job_comment/${job_id}/`, data);
  }
  async infoComments(job_id: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${job_id}/job_comments?section=job_info`
    );
  }
  async functionComments(job_id: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${job_id}/job_comments?section=job_function`
    );
  }
  async detailsComments(job_id: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${job_id}/job_comments?section=job_details`
    );
  }
  async salaryComments(job_id: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/jobs/${job_id}/job_comments?section=job_salary`
    );
  }
  async syncJobs(id: any): Promise<unknown> {
    return this.axiosInstance.get(`/jobs/${id}/sync_candidates`);
  }

  async deactivateJobs(id: any): Promise<unknown> {
    return this.axiosInstance.put(`/deactivate_job/${id}`);
  }

  async removeJobEvaluation(jobId: string): Promise<unknown> {
    return this.axiosInstance.put(`/jobs/${jobId}/detach`);
  }

  async removeJobChallenge(jobId: string): Promise<unknown> {
    return this.instanceForm.put(`/code_signal/${jobId}/unassign`);
  }
  async getCompanyById(company_id: string): Promise<unknown> {
    return this.axiosInstance.get(`/companies/${company_id}/company_brandings`);
  }
  async updateCompanyBranding(
    companyId: string,
    companyBrandingId: string,
    data: any
  ): Promise<unknown> {
    const formData = new FormData();
    if (data.logo) formData.append("company_branding[logo]", data.logo);
    if (data.background)
      formData.append("company_branding[background]", data.background);

    formData.append("company_branding[description]", data.description);
    if (data.primary_color)
      formData.append("company_branding[primary_color]", data.primary_color);
    if (data.secondary_color)
      formData.append(
        "company_branding[secondary_color]",
        data.secondary_color
      );
    if (data.site_url)
      formData.append("company_branding[site_url]", data.site_url);
    if (data.domain_name)
      formData.append("company_branding[domain_name]", data.domain_name);

    return this.axiosInstance.put(
      `/companies/${companyId}/company_brandings/${companyBrandingId}`,
      formData
    );
  }
  async getJobMembers(jobId: string): Promise<unknown> {
    return this.axiosInstance.get(`/jobs/${jobId}/job_members`);
  }
  async updateJobMember(
    jobId: string,
    memberId: string,
    body: any
  ): Promise<unknown> {
    return this.axiosInstance.put(
      `/jobs/${jobId}/job_members/${memberId}`,
      body
    );
  }
  async removeJobMember(jobId: string, memberId: string): Promise<unknown> {
    // this is a temporary instance will be replaced by default
    return this.axiosTmpInstance.delete(
      `/jobs/${jobId}/job_members/${memberId}`
    );
  }

  async jobMemberList(companyId: string): Promise<unknown> {
    return this.axiosInstance.get(
      `/companies/${companyId}/add_job_members_user_list`
    );
  }
  async getSkills(): Promise<unknown> {
    return this.axiosInstance.get("/skills");
  }
  async verifyAttachEvaluation(jobId: string): Promise<unknown> {
    return this.axiosInstance.get(`/can_attach_evaluation/${jobId}`);
  }

  async verifyDetachEvaluation(jobId: string): Promise<unknown> {
    return this.axiosInstance.get(`/can_detach_evaluation/${jobId}`);
  }

  async verifyAttachChallenge(jobId: string): Promise<unknown> {
    return this.axiosInstance.get(`/can_attach_challenge/${jobId}`);
  }

  async verifyDetachChallenge(jobId: string): Promise<unknown> {
    return this.axiosInstance.get(`/can_detach_challenge/${jobId}`);
  }

  async assignTemplate(id: string, payload: any): Promise<unknown> {
    return this.axiosInstance.put(`/evaluations/${id}/attach`, payload);
  }

  async updateJobChallenge(jobId: string, payload: any): Promise<unknown> {
    return this.axiosInstance.put(`/code_signal/${jobId}/update`, payload);
  }

  async assignJobChallenge(jobId: string, payload: any): Promise<unknown> {
    return this.axiosInstance.post(`/code_signal/${jobId}/assign`, payload);
  }

  async getJobEvents(jobId: string): Promise<unknown> {
    return this.axiosInstance.get(`/job_events/${jobId}`);
  }

  async getCurrencies(): Promise<unknown> {
    return this.axiosInstance.get(`/currencies`);
  }

  async downloadReport(): Promise<unknown> {
    return this.axiosInstance.get(`/reports/get_job_stages_count`);
  }
}
export const JobsAPI = new JobsAPIService();
