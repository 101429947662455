<template>
  <input
    @input="check()"
    type="checkbox"
    :checked="checked"
    :id="fieldId"
    class="mr-2 hidden"
    v-bind="$attrs"
    :disabled="disabled"
  />
  <label
    :for="fieldId"
    class="flex flex-row items-center text-sm cursor-pointer select-none overflow-auto"
  >
    <slot>
      <span
        :class="{
          'text-grey-400 cursor-not-allowed': disabled,
          '': !disabled,
        }"
        >{{ label }}</span
      ></slot
    >
  </label>
</template>

<script>
import { computed, defineComponent } from "vue";

const SkillCheckbox = defineComponent({
  name: "SkillCheckbox",
  emits: ["update:names"],
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    size: {
      type: String,
      default: "fa-lg",
    },
    fieldId: {
      type: [String, Number],
      required: true,
    },
    names: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const checked = computed(() => props.names.includes(props.value));
    const check = () => {
      let updatedNames = [...props.names];
      if (checked.value) {
        updatedNames.splice(updatedNames.indexOf(props.value), 1);
      } else {
        updatedNames.push(props.value);
      }
      context.emit("update:names", updatedNames);
    };

    return {
      check,
      checked,
    };
  },
});

export default SkillCheckbox;
</script>
<style src="./style.css" scoped></style>
