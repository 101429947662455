
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  PropType,
  computed,
  watch,
} from "vue";
import TextField from "@/components/textField/index.vue";
import TextArea from "@/components/textArea/index.vue";
import UserCard from "@/components/userCard/index.vue";
import CompanyCard from "@/components/companyCard/index.vue";
import Close from "@/assets/icons/close/index.vue";
import {
  createReimbursement,
  editReimbursement,
  deleteReimbursementFile,
  getClients,
  createExtraCharge,
  getCompanyTalents,
  editExtraCharge,
} from "@/services/expenses/index";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import { useStore } from "vuex";
import { createToastAxelerate } from "@/utils/toast";
import NewCurrencyField from "@/components/newCurrencyField/index.vue";

export default defineComponent({
  name: "Modal Reimbursement",
  props: {
    reimbursement: Object as PropType<any>,
    extraCharge: Object as PropType<any>,
    openModal: Boolean,
  },
  components: {
    TextField,
    TextArea,
    UserCard,
    CompanyCard,
    Close,
    NewCurrencyField,
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    //Validation
    const schema = {
      title: yup.string().required().label("Title"),
      amount: yup.number().required().min(0).label("Amount"),
      currency: yup.string().required().label("Currency"),
      note: yup.string().nullable().label("Note"),
    } as any;
    const { handleSubmit, errors } = useForm({
      validationSchema: schema,
    });
    const { value: title } = useField("title");
    const { value: detail } = useField("detail");
    const { value: amount } = useField<number>("amount");
    amount.value = 0;
    const { value: currency } = useField("currency");
    const { value: note } = useField("note");
    const { value: talent_id } = useField("talent_id");
    const { value: client_id } = useField("client_id");
    const onSubmit = handleSubmit((values) => {
      const bodyReimbursement = {
        title: values.title,
        detail: values.detail,
        amount: values.amount,
        currency: values.currency,
        note: values.note,
        talent_id: values.talent_id,
        vouchers: vouchers.value,
      };
      const bodyExtraCharge = {
        extra_charge: {
          title: values.title,
          description: values.note,
          amount: values.amount * 100,
          currency: values.currency,
        },
      };
      if (editing.value) {
        if (typeOfExpense.value === "reimbursements") {
          editingReimbiursement(bodyReimbursement);
        } else if (typeOfExpense.value === "extra_charges") {
          editingExtraCharge(bodyExtraCharge);
        }
      } else {
        if (typeOfExpense.value === "reimbursements") {
          createNewReimbursement(bodyReimbursement);
        } else if (typeOfExpense.value === "extra_charges") {
          createExtraCharge(values.client_id, bodyExtraCharge);
        }
      }
      closeModal();
    });

    //Variables
    const typeOfExpense = ref("extra_charges");
    const store = useStore();
    const selectedEmployees = ref(false);
    const selectedEmployeeName = ref("");
    const talent: Ref<Array<any>> = ref([]);
    const selectedCompanies = ref(false);
    const selectedCompanyName = ref("");
    const companies: Ref<Array<any>> = ref([]);
    const loadingReimbursement = ref(false);
    const loadingExtraCharge = ref(false);
    const error = ref(false);
    const filesToDelete: Ref<any> = ref([]);
    const editing = ref(false);
    const uploadFile = ref(true);
    const files: Ref<any> = ref([]);
    const vouchers: Ref<Array<File>> = ref([]);
    const preview: Ref<Array<string>> = ref([]);
    const details = [
      { name: "Travel", value: "travel" },
      { name: "Food & Beverage", value: "food_and_beverages" },
      { name: "Office Supplies", value: "office_supplies" },
      { name: "Other", value: "other" },
    ];
    const currencies = [
      { name: "Mexican Peso (MXN)", value: "mxn" },
      { name: "US Dollar (USD)", value: "usd" },
    ];
    const permittedFiles = ["image/png", "image/jpeg", "application/pdf"];

    //Functions
    function closeModal() {
      emit("update:openModal", false);
      store.dispatch("setRefresh", true);
    }

    function uploadFiles(event: any) {
      if (event) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (permittedFiles.includes(event.target.files[i].type)) {
            preview.value.push(event.target.files[i].name);
            vouchers.value.push(event.target.files[i]);
          } else {
            createToastAxelerate(
              "Forbidden file type",
              "Only JPG, PNG, and PDF file are allowed",
              "danger"
            );
          }
        }
      }
    }

    async function createNewReimbursement(body: any) {
      loadingReimbursement.value = true;
      const data = await createReimbursement(body.talent_id, body);
      if (data) {
        createToastAxelerate(
          "Reimbursement request",
          "Your reimbuserment request has been created",
          "success"
        );
      } else {
        createToastAxelerate("Error", data.errors[0], "danger");
        error.value = true;
      }
      loadingReimbursement.value = false;
      closeModal();
    }

    async function editingReimbiursement(body: any) {
      loadingReimbursement.value = true;
      const id = props.reimbursement?.id as string;
      if (files.value.length > 0 || preview.value.length > 0) {
        const data = await deleteReimbursementFile(id, filesToDelete.value);
        if (data && !data.errors) {
          const data = await editReimbursement(id, body);
          if (!data.errors) {
            loadingReimbursement.value = false;
            createToastAxelerate(
              "Reimbursement request",
              "Your reimbuserment request has been updated",
              "success"
            );
            closeModal();
          } else {
            loadingReimbursement.value = false;
            createToastAxelerate("Error", data.errors[0], "danger");
            error.value = true;
          }
        } else {
          loadingReimbursement.value = false;
          createToastAxelerate(
            "Reimbursement request",
            data.errors[0],
            "danger"
          );
        }
      } else {
        loadingReimbursement.value = false;
        createToastAxelerate(
          "Reimbursement request",
          "You need at least one file in this reimbursement",
          "danger"
        );
      }
    }

    async function editingExtraCharge(body: any) {
      loadingExtraCharge.value = true;
      const id = props.extraCharge?.id as string;
      const payroll_period_id = props.extraCharge?.payroll_period?.id as string;
      const data = await editExtraCharge(id, payroll_period_id, body);
      if (!data.errors) {
        loadingExtraCharge.value = false;
        createToastAxelerate("Extra charge", "Updated successfully", "success");
        closeModal();
      } else {
        loadingExtraCharge.value = false;
        createToastAxelerate("Error", data.errors[0], "danger");
        error.value = true;
      }
    }

    function deteleLocalFile(index: number) {
      preview.value.splice(index, 1);
    }

    function getFileName(path: string) {
      return path.substring(path.lastIndexOf("/") + 1);
    }

    function viewReceipt(url: string) {
      window.open(url, "_blank");
    }

    async function deteleFile(index: string) {
      /*       const reimbursementId = props.reimbursement?.id
        ? props.reimbursement?.id
        : ""; */
      filesToDelete.value.push(index);
      files.value.splice(index, 1);
    }

    function openSelectedEmployees() {
      selectedEmployees.value = !selectedEmployees.value;
      selectedCompanies.value = false;
    }

    async function getClientTalents(id: string) {
      talent_id.value = "";
      talent.value = [];
      selectedEmployees.value = false;
      selectedEmployeeName.value = "";
      const data = await getCompanyTalents(id);
      if (data) {
        talent.value = data.talents;
      }
    }

    function selectEmployee(index: number) {
      talent_id.value = talent.value[index].id;
      selectedEmployeeName.value =
        talent.value[index].first_name + " " + talent.value[index].last_name;
      selectedEmployees.value = false; //To close the dropdown
    }

    function openSelectedCompanies() {
      if (selectedCompanies.value === false) {
        getCompaniesDisplay();
      }
      selectedCompanies.value = !selectedCompanies.value;
      selectedEmployees.value = false;
    }

    async function getCompaniesDisplay() {
      const data = await getClients();
      if (data) {
        companies.value = data;
      }
    }

    function selectCompany(index: number) {
      client_id.value = companies.value[index].company_id;
      selectedCompanyName.value = companies.value[index].visible_name;
      selectedCompanies.value = false; //To close the dropdown
    }

    //Computed
    //const canSubmit = computed(() => (preview.value.length > 0 || vouchers.value.length > 0 || files.value.length > 0));
    const canSubmit = computed(() => true);

    watch(client_id, (newVal) => {
      getClientTalents(newVal as string);
    });

    watch(typeOfExpense, (newVal) => {
      if (newVal === "reimbursements") {
        schema.talent_id = yup.string().required().label("User");
        schema.detail = yup.string().required().label("Detail");
        schema.client_id = yup.string().label("Client");
      } else if (newVal === "extra_charges") {
        schema.client_id = yup.string().required().label("Client");
        schema.talent_id = yup.string().label("User");
        schema.detail = yup.string().label("Detail");
      }
    });

    //Hooks
    onMounted(() => {
      if (props.reimbursement) {
        typeOfExpense.value = "reimbursements";
        editing.value = true;
        title.value = props.reimbursement.title;
        detail.value = props.reimbursement.detail;
        amount.value = props.reimbursement.original_amount / 100;
        currency.value = props.reimbursement.currency;
        note.value = props.reimbursement.note;
        files.value = props.reimbursement.vouchers;
        talent_id.value = props.reimbursement.user.id;
        selectedEmployeeName.value =
          props.reimbursement.user.first_name +
          " " +
          props.reimbursement.user.last_name;
      } else if (props.extraCharge) {
        typeOfExpense.value = "extra_charges";
        editing.value = true;
        amount.value = props.extraCharge.original_amount / 100;
        currency.value = props.extraCharge.currency;
        note.value = props.extraCharge.description;
        title.value = props.extraCharge.title;
      } else {
        detail.value = "";
        currency.value = "mxn";
      }
    });

    return {
      title,
      detail,
      amount,
      currency,
      note,
      typeOfExpense,
      talent_id,
      client_id,
      selectedEmployeeName,
      selectedEmployees,
      selectEmployee,
      openSelectedEmployees,
      talent,
      selectedCompanyName,
      selectedCompanies,
      selectCompany,
      openSelectedCompanies,
      companies,
      details,
      currencies,
      deteleLocalFile,
      onSubmit,
      errors,
      uploadFile,
      canSubmit,
      uploadFiles,
      preview,
      vouchers,
      files,
      deteleFile,
      closeModal,
      editing,
      getFileName,
      viewReceipt,
      loadingReimbursement,
      loadingExtraCharge,
    };
  },
});
