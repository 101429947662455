import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollaboratorsTable = _resolveComponent("CollaboratorsTable")!
  const _component_ModalAddMember = _resolveComponent("ModalAddMember")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_CollaboratorsTable)
    ]),
    (_ctx.openModal)
      ? (_openBlock(), _createBlock(_component_ModalAddMember, {
          key: 0,
          openModal: _ctx.openModal,
          "onUpdate:openModal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openModal) = $event))
        }, null, 8, ["openModal"]))
      : _createCommentVNode("", true)
  ], 64))
}