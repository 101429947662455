
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
  watchEffect,
} from "vue";
import Header from "@/components/header/index.vue";
import TextField from "@/components/textField/index.vue";
import NewCurrencyField from "@/components/newCurrencyField/index.vue";
//import TextArea from "@/components/textArea/index.vue";
import Bullets from "@/components/bullets/index.vue";
import SelectDropdown from "@/components/selectDropdown/index.vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import {
  getCompanies,
  getJobs,
  addTalent,
  getPositions,
} from "@/services/talents/index";
import { useRouter } from "vue-router";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Add Talent",
  components: {
    Header,
    TextField,
    SelectDropdown,
    //TextArea,
    Bullets,
    NewCurrencyField,
  },
  setup() {
    //Validations
    const schema = yup.object({
      email: yup.string().email().required().label("Email"),
      Empleado_ID: yup.string().required().label("Employee ID"),
      company_id: yup.string().required().label("Company"),
      name: yup.string().required().label("Name"),
      job_id: yup.string().label("Job"),
      pto_days: yup.number().required().min(0).label("PTO Days"),
      description: yup.string().label("Description"),
      requirements: yup.string().label("Requirements"),
      responsibilities: yup.string().label("Responsibilities"),
      department: yup.string().label("Department"),
      position_id: yup.string().label("Role"),
    });
    const fieldErrors = ref([]);

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: email } = useField("email");
    const { value: Empleado_ID } = useField("Empleado_ID");
    const { value: company_id } = useField("company_id");
    const { value: job_id } = useField("job_id");
    const { value: name } = useField("name");
    const { value: pto_days } = useField("pto_days");
    const { value: description } = useField("description");
    const { value: requirements } = useField("requirements");
    const { value: responsibilities } = useField("responsibilities");
    const { value: department } = useField("department");
    const { value: position_id } = useField("position_id");

    const onSubmit = handleSubmit((values) => {
      const bodyBenefits = [] as Array<any>;
      for (let i = 0; i < benefits.value.length; i++) {
        bodyBenefits.push({
          name: benefits.value[i].name,
          value: benefits.value[i].value * 100,
          currency: benefits.value[i].currency,
        });
      }
      const bodyExisting = {
        talent: {
          email: values.email,
          company_id: values.company_id,
          position_id: values.position_id,
        },
        Empleado_ID: values.Empleado_ID,
      };
      const bodyNew = {
        talent: {
          email: values.email,
          company_id: values.company_id,
          position: {
            name: values.name,
            description: values.description,
            requirements: values.requirements,
            responsibilities: values.responsibilities,
            department: values.department,
            benefits: bodyBenefits,
          },
        },
        Empleado_ID: values.Empleado_ID,
      };
      if (isNew.value) {
        createTalent(bodyNew);
      } else {
        createTalent(bodyExisting);
      }
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    //Vars and functions
    const router = useRouter();
    const loading = ref(false);
    const error = ref(false);
    const openModal = ref(false);
    const companies: Ref<Array<any>> = ref([]);
    const positions: Ref<Array<any>> = ref([]);
    const positionOptions: Ref<Array<any>> = ref([
      { id: "", name: "New role" },
    ]);
    const jobs: Ref<Array<any>> = ref([]);
    const allJobs: Ref<Array<any>> = ref([]);
    const departmentOptions: Ref<Array<any>> = ref([
      { id: "engineering", name: "Engineering" },
      { id: "design", name: "Design" },
      { id: "marketing", name: "Marketing" },
      { id: "sales", name: "Sales" },
    ]);
    const jobDescription = ref({
      position: "Position...",
      department: "Department...",
      role: "Role...",
      occupation: "Occupation...",
      pto: "PTO days...",
    });
    const benefits: Ref<Array<any>> = ref([]);
    const isNew = ref(true);
    const newBenefitName = ref("");
    const newBenefitAmount = ref(0);
    const newBenefitCurrency = ref("usd");
    const refreshCounterN = ref(0);
    const refreshCounterV = ref(0);
    const refreshCounterC = ref(0);
    const refreshCounterRole = ref(0);
    const refreshCounterChange = ref(0);
    const currencyOptions = ref([
      { id: "usd", name: "US Dollar (USD)" },
      { id: "mxn", name: "Mexican Peso (MXN)" },
    ]);
    const errorName = ref(false);

    function addBenefit() {
      benefits.value.push({
        name: newBenefitName.value,
        value: newBenefitAmount.value,
        currency: newBenefitCurrency.value,
      });
      newBenefitName.value = "";
      newBenefitAmount.value = 0;
      newBenefitCurrency.value = "usd";
      refreshCounterN.value++;
      refreshCounterV.value++;
      refreshCounterC.value++;
    }
    async function getAllCompanies() {
      companies.value = [];
      const data = await getCompanies();
      if (data) {
        const allCompanies = data.companies;
        for (let i = 0; i < allCompanies.length; i++) {
          companies.value.push({
            id: allCompanies[i].id,
            name: allCompanies[i].name,
          });
        }
      } else {
        createToastAxelerate(
          "Could not load companies",
          "There was an error loading companies",
          "danger"
        );
      }
    }
    async function getAllJobs(id: string) {
      jobs.value = [];
      const data = await getJobs(id);
      if (data) {
        allJobs.value = data.jobs;
        for (let i = 0; i < allJobs.value.length; i++) {
          jobs.value.push({
            id: allJobs.value[i].id,
            name: allJobs.value[i].title,
          });
        }
        jobs.value.push({
          name: "Roles",
          disabled: true,
        });
        for (let i = 0; i < positions.value.length; i++) {
          jobs.value.push({
            id: positions.value[i].id,
            name: positions.value[i].name,
          });
        }
      } else {
        createToastAxelerate(
          "Could not load jobs",
          "There was an error loading jobs",
          "danger"
        );
      }
    }
    async function createTalent(body: any) {
      loading.value = true;
      error.value = false;
      const data = await addTalent(body);
      if (data) {
        openModal.value = false;
        router.push("/talent");
      } else {
        createToastAxelerate(
          "Could not add talent",
          "There was an error adding the talent",
          "danger"
        );
      }
      loading.value = false;
    }
    async function getAllPositions(id: string) {
      positionOptions.value = [{ id: "", name: "New role" }];
      const data = await getPositions(id);
      if (data) {
        positions.value = data.positions;
        for (let i = 0; i < positions.value.length; i++) {
          positionOptions.value.push({
            id: positions.value[i].id,
            name: positions.value[i].name,
          });
        }
      } else {
        createToastAxelerate(
          "Could not load roles",
          "There was an error loading roles",
          "danger"
        );
      }
    }

    function setTemplate(id: any) {
      const job = allJobs.value.find((x) => id === x.id);
      const position = positions.value.find((x) => id === x.id);
      if (job) {
        name.value = job.title || "";
        department.value = job.department || "";
        pto_days.value = job.pto_days || 20;
        responsibilities.value = job.responsabilities || "";
        requirements.value = job.requirements || "";
      } else if (position) {
        name.value = position.name || "";
        department.value = position.department || "";
        pto_days.value = position.pto_days || 20;
        responsibilities.value = position.responsibilities || "";
        requirements.value = position.requirements || "";
        benefits.value = [];
        for (let i = 0; i < position.benefits?.length; i++) {
          benefits.value.push({
            name: position.benefits[i].name,
            value: position.benefits[i].value / 100,
            currency: "usd",
          });
        }
      }
    }
    /* 
    function selectJobInformation(id: string) {
      const job = allJobs.value.find((x) => x.id === id);
      if (job.job_function) {
        jobDescription.value = {
          position: job.title,
          department: job.department,
          role: job.employment_type,
          occupation: job.job_function.name,
          pto: "20",
        };
      }
    } */

    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    function setPosition(pos: any) {
      if (pos.id) {
        isNew.value = false;
      } else {
        isNew.value = true;
      }
      name.value = pos.name || "";
      department.value = pos.department || "";
      pto_days.value = pos.pto_days || 20;
      responsibilities.value = pos.responsibilities || "";
      requirements.value = pos.requirements || "";
      benefits.value = [];
      for (let i = 0; i < pos.benefits?.length; i++) {
        benefits.value.push({
          name: pos.benefits[i].name,
          value: pos.benefits[i].value / 100,
          currency: "usd",
        });
      }
      position_id.value = pos.id;
      refreshCounterRole.value++;
      refreshCounterChange.value++;
    }

    const canAddBenefit = computed(
      () => newBenefitName.value && newBenefitAmount.value > 0
    );

    watchEffect(() => {
      if (responsibilities.value === "" || !responsibilities.value) {
        responsibilities.value = "<li>&#160;&#160;</li>";
      }
      if (requirements.value === "" || !requirements.value) {
        requirements.value = "<li>&#160;&#160;</li>";
      }
    });

    watch(company_id, (newVal) => {
      const id = newVal as string;
      getAllPositions(id);
      getAllJobs(id);
    });
    watch(job_id, (newVal) => {
      const id = newVal as string;
      setTemplate(id);
    });
    watch(position_id, (newVal) => {
      errorName.value = false;
      const id = newVal as string;
      if (id) {
        setPosition(positions.value.find((x) => x.id === id));
      } else {
        setPosition({});
      }
    });
    watch(name, (newVal) => {
      const newName = newVal as string;
      const pos = positions.value.find(
        (x) => newName.toLowerCase() === x.name.toLowerCase()
      );
      if (pos && isNew.value) {
        //name.value = "";
        //setPosition(pos);
        createToastAxelerate(
          "This role exists",
          "There is a role already with the same name",
          "danger"
        );
        errorName.value = true;
      } else {
        errorName.value = false;
      }
    });

    onMounted(() => {
      getAllCompanies();
      //getAllPositions();
      pto_days.value = 20;
    });

    return {
      loading,
      error,
      companies,
      jobs,
      jobDescription,
      email,
      Empleado_ID,
      company_id,
      job_id,
      name,
      responsibilities,
      requirements,
      description,
      pto_days,
      department,
      departmentOptions,
      onSubmit,
      fieldErrors,
      openModal,
      scrollToTop,
      positions,
      positionOptions,
      position_id,
      benefits,
      isNew,
      newBenefitName,
      newBenefitAmount,
      newBenefitCurrency,
      addBenefit,
      refreshCounterN,
      refreshCounterV,
      refreshCounterC,
      refreshCounterChange,
      refreshCounterRole,
      errorName,
      canAddBenefit,
      currencyOptions,
    };
  },
});
