import { createApp } from "vue";
import VueClickAway from "vue3-click-away";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/app.css";
import "vue-select/dist/vue-select.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import vSelect from "vue-select";

createApp(App)
  .component("v-select", vSelect)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyD0qCTGi6UJDmvJhUPgoYAccFUNguHJZiw",
      libraries: "places",
    },
  })
  .mount("#app");
