
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Avatar from "../avatar/index.vue";
import { getInitials, numberWithCommas } from "../../utils/functions";
//import MenuSingleCandidate from "../menuSingleCandidate/index.vue";
const CardCandidateDetail = defineComponent({
  name: "CandidateCard",
  components: {
    Avatar,
    //   MenuSingleCandidate,
  },
  props: {
    candidates: {
      default: () => [],
      type: Array,
    },
  },
  setup() {
    const router = useRouter();
    const colors = [
      "#FFC899",
      "#99FFCC",
      "#F3F3B2",
      "#F2F5FF",
      "#FFF8F2",
      "#F2FFF9",
      "#FEFAF1",
      "#FBF2FF",
      "#FFF2F2",
    ];
    const randomColor = () => colors[Math.floor(Math.random() * colors.length)];
    const viewCandidate = (id: string) => {
      const url = `${process.env.VUE_APP_BASE_URL}/candidates/${id}`;
      window.open(url, "_blank");
    };

    return {
      getInitials,
      router,
      numberWithCommas,
      randomColor,
      viewCandidate,
    };
  },
});

export default CardCandidateDetail;
