
import { defineComponent, onMounted, Ref, ref, watch, watchEffect } from "vue";
import Header from "@/components/header/index.vue";
import TextField from "@/components/textField/index.vue";
import SelectDropdown from "@/components/selectDropdown/index.vue";
import NewCurrencyField from "@/components/newCurrencyField/index.vue";
import Bullets from "@/components/bullets/index.vue";
import UserCard from "@/components/userCard/index.vue";
import Spinner from "@/components/spinner/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useRouter } from "vue-router";
import { getCompanies, getPositions, getJobs } from "@/services/talents";
import { createRole, editRole, getRole } from "@/services/roles";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Add Role",
  props: {
    roleId: String,
    companyId: String,
  },
  components: {
    Header,
    TextField,
    SelectDropdown,
    NewCurrencyField,
    Bullets,
    UserCard,
    Spinner,
  },
  setup(props) {
    //Validations
    const schema = yup.object({
      name: yup.string().required().label("Name"),
      job_id: yup.string().label("Job"),
      description: yup.string().label("Description"),
      requirements: yup.string().label("Requirements"),
      responsibilities: yup.string().label("Responsibilities"),
      department: yup.string().label("Department"),
      company_id: yup.string().required().label("Company"),
    });
    const fieldErrors = ref([]);

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: company_id } = useField("company_id");
    const { value: job_id } = useField("job_id");
    const { value: name } = useField("name");
    const { value: requirements } = useField("requirements");
    const { value: responsibilities } = useField("responsibilities");
    const { value: department } = useField("department");
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const onSubmit = handleSubmit((values) => {
      const bodyBenefits = [] as Array<any>;
      for (let i = 0; i < benefits.value.length; i++) {
        const bodyToPush = {
          name: benefits.value[i].name,
          value: benefits.value[i].value * 100,
          currency: benefits.value[i].currency,
        } as any;
        if (benefits.value[i].id) {
          bodyToPush.id = benefits.value[i].id;
        }
        bodyBenefits.push(bodyToPush);
      }
      const body = {
        position: {
          name: values.name,
          department: values.department,
          requirements: values.requirements,
          responsibilities: values.responsibilities,
          benefits: bodyBenefits,
        },
      };
      if (editable.value) {
        editRoleDetails(body);
      } else {
        const id = values.company_id as string;
        createNewRole(id, body);
      }
    }, onInvalidSubmit);
    /* eslint-disable @typescript-eslint/no-explicit-any */

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    //Vars - functions
    const router = useRouter();
    const loading = ref(false);
    const error = ref(false);
    const editable = ref(false);
    /* eslint-disable @typescript-eslint/no-explicit-any */

    const benefits: Ref<Array<any>> = ref([]);
    const companies: Ref<Array<any>> = ref([]);
    const positions: Ref<Array<any>> = ref([]);
    const positionOptions: Ref<Array<any>> = ref([
      { id: "", name: "New role" },
    ]);
    const jobs: Ref<Array<any>> = ref([]);
    const allJobs: Ref<Array<any>> = ref([]);
    const departmentOptions: Ref<Array<any>> = ref([
      { id: "engineering", name: "Engineering" },
      { id: "design", name: "Design" },
      { id: "marketing", name: "Marketing" },
      { id: "sales", name: "Sales" },
    ]);
    const newBenefitName = ref("");
    const newBenefitAmount = ref(0);
    const newBenefitCurrency = ref("usd");
    const refreshCounterN = ref(0);
    const refreshCounterV = ref(0);
    const refreshCounterC = ref(0);
    const refreshCounterRole = ref(0);
    const refreshCounterChange = ref(0);
    const currencyOptions = ref([
      { id: "usd", name: "US Dollar (USD)" },
      { id: "mxn", name: "Mexican Peso (MXN)" },
    ]);
    const errorName = ref(false);
    // eslint-disable-next-line
    const users: Ref<Array<any>> = ref([]);
    const jobsLoading = ref(false);

    function addBenefit() {
      benefits.value.push({
        name: newBenefitName.value,
        value: newBenefitAmount.value,
        currency: newBenefitCurrency.value,
      });
      newBenefitName.value = "";
      newBenefitAmount.value = 0;
      newBenefitCurrency.value = "usd";
      refreshCounterN.value++;
      refreshCounterV.value++;
      refreshCounterC.value++;
    }

    async function getAllCompanies() {
      companies.value = [];
      const data = await getCompanies();
      if (data) {
        const allCompanies = data.companies;
        for (let i = 0; i < allCompanies.length; i++) {
          companies.value.push({
            id: allCompanies[i].id,
            name: allCompanies[i].name,
          });
        }
      } else {
        //createToastAxelerate("Could not load companies", "There was an error loading companies", "danger")
      }
    }

    async function getFullRole(companyId: string, positionId: string) {
      loading.value = true;
      const data = await getRole(companyId, positionId);
      if (data) {
        users.value = data.position.users;
        company_id.value = data.position.company.id;
        name.value = data.position.name;
        department.value = data.position.department;
        responsibilities.value = data.position.responsibilities;
        requirements.value = data.position.requirements;
        for (let i = 0; i < data.position.benefits.length; i++) {
          benefits.value.push({
            id: data.position.benefits[i].id,
            name: data.position.benefits[i].name,
            value: data.position.benefits[i].value / 100,
            currency: "usd",
          });
        }
        editable.value = true;
      }
      loading.value = false;
    }

    async function getAllJobs(id: string) {
      jobsLoading.value = true;
      jobs.value = [];
      const data = await getJobs(id);
      if (data) {
        allJobs.value = data.jobs;
        for (let i = 0; i < allJobs.value.length; i++) {
          jobs.value.push({
            id: allJobs.value[i].id,
            name: allJobs.value[i].title,
          });
        }
        jobs.value.push({
          name: "Roles",
          disabled: true,
        });
        for (let i = 0; i < positions.value.length; i++) {
          jobs.value.push({
            id: positions.value[i].id,
            name: positions.value[i].name,
          });
        }
      } else {
        createToastAxelerate(
          "Could not load jobs",
          "There was an error loading jobs",
          "danger"
        );
      }
      jobsLoading.value = false;
    }

    async function getAllPositions(company_id: string) {
      loading.value = true;
      const data = await getPositions(company_id);
      if (data) {
        positions.value = data.positions;
        for (let i = 0; i < positions.value.length; i++) {
          positionOptions.value.push({
            id: positions.value[i].id,
            name: positions.value[i].name,
          });
        }
      }
      loading.value = false;
    }
    // eslint-disable-next-line
    async function editRoleDetails(body: any) {
      loading.value = true;
      let id = "";
      if (props.roleId) {
        id = props.roleId;
      }
      const data = await editRole(id, body);
      if (data) {
        createToastAxelerate("Role edited successfully", "", "success");
        if (props.companyId) {
          router.push(`/client-detail/${props.companyId}`);
        } else {
          router.push("/clients");
        }
      }
      loading.value = false;
    }
    // eslint-disable-next-line
    async function createNewRole(company_id: string, body: any) {
      loading.value = true;
      const data = await createRole(company_id, body);
      if (data) {
        createToastAxelerate("Role created successfully", "", "success");
        router.push(`/client-detail/${company_id}`);
      }
      loading.value = false;
    }
    // eslint-disable-next-line
    function setTemplate(id: any) {
      const job = allJobs.value.find((x) => id === x.id);
      const position = positions.value.find((x) => id === x.id);
      if (job) {
        name.value = job.title || "";
        department.value = job.department || "";
        responsibilities.value = job.responsabilities || "";
        requirements.value = job.requirements || "";
      } else if (position) {
        name.value = position.name || "";
        department.value = position.department || "";
        responsibilities.value = position.responsibilities || "";
        requirements.value = position.requirements || "";
        benefits.value = [];
        for (let i = 0; i < position.benefits?.length; i++) {
          benefits.value.push({
            name: position.benefits[i].name,
            value: position.benefits[i].value / 100,
            currency: "usd",
          });
        }
      }
    }

    watchEffect(() => {
      if (responsibilities.value === "" || !responsibilities.value) {
        responsibilities.value = "<li>&#160;&#160;</li>";
      }
      if (requirements.value === "" || !requirements.value) {
        requirements.value = "<li>&#160;&#160;</li>";
      }
    });

    function returnToCompany() {
      if (props.companyId) {
        router.push(`/client-detail/${props.companyId}`);
      } else {
        router.push(`/clients`);
      }
    }

    watch(company_id, (newVal) => {
      const id = newVal as string;
      getAllPositions(id);
      getAllJobs(id);
    });

    watch(job_id, (newVal) => {
      const id = newVal as string;
      setTemplate(id);
    });

    watch(name, (newVal) => {
      errorName.value = false;
      const newName = newVal as string;
      if (
        positions.value.find(
          (x) => x.name.toLowerCase() === newName.toLowerCase()
        ) &&
        editable.value === false
      ) {
        createToastAxelerate(
          "Role already exists",
          "Please choose a different name",
          "danger"
        );
        errorName.value = true;
      }
    });

    onMounted(() => {
      if (props.roleId && props.companyId) {
        getFullRole(props.companyId, props.roleId);
      }
      if (props.companyId) {
        company_id.value = props.companyId;
      }
      getAllCompanies();
    });

    return {
      benefits,
      loading,
      error,
      companies,
      positions,
      positionOptions,
      jobs,
      allJobs,
      departmentOptions,
      newBenefitName,
      newBenefitAmount,
      newBenefitCurrency,
      refreshCounterN,
      refreshCounterV,
      refreshCounterC,
      refreshCounterRole,
      refreshCounterChange,
      currencyOptions,
      errorName,
      company_id,
      job_id,
      name,
      requirements,
      responsibilities,
      department,
      onSubmit,
      addBenefit,
      users,
      jobsLoading,
      returnToCompany,
      editable,
    };
  },
});
