
import { defineComponent, onMounted, computed, ref } from "vue";
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import * as yup from "yup";
import { useStore } from "vuex";
import TextField from "../../components/textField/index.vue";
import PasswordField from "../../components/passwordField/index.vue";
import CheckBox from "../../components/simpleCheckbox/index.vue";
import { createToastAxelerate } from "../../utils/toast";

const Login = defineComponent({
  name: "Login",
  components: {
    PasswordField,
    TextField,
    CheckBox,
  },
  setup() {
    const store = useStore();
    const remember = ref(false);
    const router = useRouter();
    const token = computed(() => store.state.auth);
    const schema = yup.object({
      email: yup.string().required().email(),
      password: yup.string().required().min(8),
    });

    const { meta, handleSubmit } = useForm({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: schema,
    });
    onMounted(() => {
      if (token.value.token) {
        router.push("/");
      }
    });
    const onSubmit = handleSubmit((values) => {
      const dataSubmit = {
        session: {
          ...values,
        },
      };
      store
        .dispatch("login", dataSubmit)
        .then(async (res) => {
          await store.dispatch("getProfile");
          await router.push(res);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Login", errors[0], "danger");
        });
    });

    return { onSubmit, remember, meta };
  },
});
export default Login;
