
import Header from "../components/header/index.vue";
import { defineComponent } from "vue";

const Home = defineComponent({
  name: "Home",
  components: {
    Header,
  },
});
export default Home;
