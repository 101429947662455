
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getInitials, timeAgo } from "../../utils/functions";
import Header from "../../components/header/index.vue";
import AddCandidatesForm from "../../components/addCandidateForm/index.vue";
import CandidateInfo from "../../components/candidateInfo/index.vue";
import EditCandidateForm from "../../components/editCandidate/index.vue";
import { useState } from "../../composables/useState";
import Link from "../../components/link/index.vue";
import Divider from "../../components/divider/index.vue";
import ErrorPage from "../../components/error/index.vue";

const CandidateDetail = defineComponent({
  name: "CandidateDetail",
  components: {
    Header,
    CandidateInfo,
    Loading,
    Link,
    Divider,
    ErrorPage,
  },
  setup() {
    const formRef = ref<InstanceType<typeof AddCandidatesForm>>();
    const editRef = ref<InstanceType<typeof EditCandidateForm>>();
    const store = useStore();
    const jobStore = computed(() => store.state.addCanidates.data);
    const userInfo = computed(() => store.state.candidateInfo.data);
    const candidates = computed(() => store.state.jobCandidates.data);
    const route = useRoute();
    const isLoading = ref(true);
    const jobId = route.query.id;
    const candidateId = route.query.candidateId;
    const showModal = ref(false);
    const showDelete = ref(false);
    const toggleInfo = ref(false);
    const selectedUser: any = ref(0);
    const isMenuOpen = ref(false);
    const showOption = ref("");
    const [showSearch, setShowSearch] = useState(false);
    const activeTab: any = ref(route.query.by_stage_name);
    const resetSelected = () => (selectedUser.value = 0);
    const inputValue = ref("");
    const [error, setError] = useState(false);
    onBeforeMount(async () => {
      const candidate = getCandidateData(candidateId, jobId);
      const job = store.dispatch("getJobState", jobId);
      Promise.all([candidate, job])
        .then((res) => res)
        .catch(() => {
          isLoading.value = false;
          setError(true);
        });
    });
    const reloadCandidate = () => {
      toggleInfo.value = true;
      isLoading.value = true;
      const buildParams = {
        jobId,
        userId: userInfo.value?.id,
      };
      const data = { jobId, filters: { by_stage_name: activeTab.value } };
      showOption.value = "";
      store.dispatch("getJobCandidatesState", data);
      store
        .dispatch("getCandidateState", buildParams)
        .then(() => {
          showOption.value = "info";
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
          setError(true);
        });
    };
    const getCandidateData = (candidateId: any, jobId: any) => {
      editRef?.value?.resetAll();
      selectedUser.value = candidateId;
      toggleInfo.value = false;
      showOption.value = "";
      toggleInfo.value = true;
      isLoading.value = true;
      const buildParams = {
        jobId,
        userId: candidateId,
      };
      store
        .dispatch("getCandidateState", buildParams)
        .then((res) => {
          showOption.value = "info";
          isLoading.value = res;
        })
        .catch((err) => {
          isLoading.value = err;
        });
    };
    return {
      userInfo,
      activeTab,
      candidates,
      jobStore,
      getInitials,
      showModal,
      showDelete,
      toggleInfo,
      formRef,
      getCandidateData,
      route,
      reloadCandidate,
      selectedUser,
      isMenuOpen,
      isLoading,
      showOption,
      editRef,
      resetSelected,
      timeAgo,
      inputValue,
      setShowSearch,
      showSearch,
      jobId,
      candidateId,
      error,
    };
  },
});

export default CandidateDetail;
