
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, PropType, ref } from "vue";
import { getNextPayrollDates, updateReimbursement } from "@/services/expenses";
import UserCard from "@/components/userCard/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import { useStore } from "vuex";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Modal Decision",
  components: {
    UserCard,
    DateFormatter,
  },
  props: {
    openDecision: Boolean,
    reimbursement: Object as PropType<any>,
  },
  emits: ["update:openDecision"],
  setup(props, { emit }) {
    const store = useStore();
    const decision = ref("");
    const message = ref("");
    const upvoteBtn = ref({
      color: "transparent",
      img: "thumbs-up",
      text: "black",
      selected: false,
    });
    const downvoteBtn = ref({
      color: "transparent",
      img: "thumbs-down",
      hover: "gray-300",
      text: "black",
      selected: false,
    });
    const payment_date = ref("");
    const showTooltip = ref(false);
    const loading = ref(false);

    function upvote() {
      if (!upvoteBtn.value.selected) {
        setPaymentDate();
        decision.value = "approved";
        upvoteBtn.value.selected = true;
        downvoteBtn.value.selected = false;
        upvoteBtn.value.color = "green-100";
        upvoteBtn.value.text = "green-500";
        upvoteBtn.value.img = "thumbs-up-green";
        downvoteBtn.value.text = "gray-400";
        downvoteBtn.value.img = "thumbs-down-gray";
        downvoteBtn.value.color = "transparent";
      } else {
        resetBtns();
      }
    }
    function downvote() {
      if (!downvoteBtn.value.selected) {
        decision.value = "denied";
        downvoteBtn.value.selected = true;
        upvoteBtn.value.selected = false;
        downvoteBtn.value.color = "red-100";
        downvoteBtn.value.text = "red-500";
        downvoteBtn.value.img = "thumbs-down-red";
        upvoteBtn.value.text = "gray-400";
        upvoteBtn.value.img = "thumbs-up-gray";
        upvoteBtn.value.color = "transparent";
      } else {
        resetBtns();
      }
    }
    function resetBtns() {
      decision.value = "";
      upvoteBtn.value = {
        color: "transparent",
        img: "thumbs-up",
        text: "black",
        selected: false,
      };
      downvoteBtn.value = {
        color: "transparent",
        img: "thumbs-down",
        hover: "gray-300",
        text: "black",
        selected: false,
      };
    }
    async function decide() {
      loading.value = true;
      const id = props.reimbursement?.id || "";
      /*       const note = props.reimbursement?.note
        ? props.reimbursement.note
        : message.value; */
      const data = await updateReimbursement(message.value, decision.value, id);
      if (data) {
        closeModal();
      } else {
        createToastAxelerate(
          "Error deciding reimbursement",
          "Please try again",
          "danger"
        );
      }
      loading.value = false;
    }
    function closeModal() {
      store.dispatch("setRefresh", true);
      emit("update:openDecision", false);
    }
    async function setPaymentDate() {
      const id = props.reimbursement?.user.id as string;
      const data = await getNextPayrollDates(id);
      if (data) {
        if (data.current.length > 0) {
          const end = new Date(data.current[0].end_date);
          const now = new Date();
          if (end < now) {
            payment_date.value = data.current[0].end_date;
          } else if (data.upcoming.length > 0) {
            payment_date.value = data.upcoming[0].end_date;
          }
        }
      }
    }

    const isSelected = computed(
      () =>
        upvoteBtn.value.selected ||
        (downvoteBtn.value.selected && message.value)
    );

    return {
      decision,
      message,
      upvoteBtn,
      downvoteBtn,
      payment_date,
      showTooltip,
      upvote,
      downvote,
      decide,
      closeModal,
      isSelected,
      loading,
    };
  },
});
