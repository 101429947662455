
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, PropType, ref } from "vue";
import UserCard from "@/components/userCard/index.vue";
export default defineComponent({
  name: "Modal Reason",
  components: {
    UserCard,
  },
  props: {
    openReason: Boolean,
    reimbursement: Object as PropType<any>,
  },

  emits: ["update:openReason"],
  setup(props, { emit }) {
    const files = ref([]);

    //Functions
    function closeModal() {
      emit("update:openReason", false);
    }

    function getFileName(path: string) {
      return path.substring(path.lastIndexOf("/") + 1);
    }

    function viewReceipt(url: string) {
      window.open(url, "_blank");
    }

    onMounted(() => {
      files.value = props.reimbursement?.vouchers;
    });

    return {
      closeModal,
      files,
      viewReceipt,
      getFileName,
    };
  },
});
