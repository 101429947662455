/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";
import { instanceAuthAPI, instanceLoggedAPI } from "../axiosConfig";
import { User, Recover, SetPassword, ChangePassword } from "./types";
export default class UserAPIService {
  private axiosInstance: AxiosInstance;
  private axiosLogInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceAuthAPI;
    this.axiosLogInstance = instanceLoggedAPI;
  }

  async registerUser(user: Partial<User>): Promise<unknown> {
    return this.axiosInstance.post("/users", user);
  }
  async getUserRegister(token: string): Promise<unknown> {
    return this.axiosLogInstance.get(`/user/${token}`);
  }
  async updateSignUp(token: any, payload: any): Promise<unknown> {
    return this.axiosInstance.put(`/account_confirmation/${token}`, payload);
  }
  async recoverPassword(user: Partial<Recover>): Promise<unknown> {
    return this.axiosInstance.post("/recover_password", user);
  }
  async resetPassword(data: Partial<SetPassword>): Promise<unknown> {
    return this.axiosInstance.put("/set_password", data);
  }
  async getProfile(): Promise<unknown> {
    return this.axiosLogInstance.get("/profiles");
  }
  async updateUser(data: any, id: number | string): Promise<unknown> {
    const formData = new FormData();
    formData.append("user[first_name]", data.first_name);
    formData.append("user[last_name]", data.last_name);
    if (data.avatar) formData.append("user[avatar]", data.avatar);

    return this.axiosLogInstance.put(`/users/${id}`, formData);
  }

  async changePassword(id: string, payload: ChangePassword): Promise<unknown> {
    return this.axiosLogInstance.put(`/users/change_password`, payload);
  }
}

export const userAPI = new UserAPIService();
