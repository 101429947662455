
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import TextField from "@/components/textField/index.vue";
import DropDown from "@/components/selectDropdown/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import Spinner from "@/components/spinner/index.vue";
import { ClientAPI } from "@/services/clients/index";
import { companyRole, platforms } from "../../constants/company";
import { createToastAxelerate } from "../../utils/toast";
import { useStore } from "vuex";
import CheckBoxGroup from "../checkboxGroup/index.vue";
import { JobsAPI } from "@/services/jobs/index";

export default defineComponent({
  name: "Modal Add Company",
  components: {
    TextField,
    DropDown,
    Spinner,
    CheckBoxGroup,
  },
  props: {
    openModal: Boolean,
    client: Object,
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    const checkValues = ref([]);
    const clientId = ref("");
    const isEditing = ref(false);
    const store = useStore();
    const countrySelected = ref("");
    const countryMx = ref();
    const countryUsa = ref();
    const countryMxId = ref("");
    const countryUsaId = ref("");
    const countriesDefault = ref([{}]);
    const countries = ref([]);
    const companyIndustries = ref([]);
    //Validation
    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
      email: yup.string().email().required().label("Email"),
      company_name: yup.string().required().label("Company name"),
      country_1: yup.string().required().label("Country"),
      auxCountry: yup.string().label("Country"),
      company_industry_id: yup.string().label("Company industry"),
    });

    const { handleSubmit, meta, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        country_1: "",
        auxCountry: "",
        company_industry_id: "",
      },
    });

    const fieldErrors = ref([]);

    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: email } = useField("email");
    const { value: company_name } = useField("company_name");
    const { value: country_1 } = useField("country_1");
    const { value: auxCountry } = useField("auxCountry");
    const { value: company_industry_id } = useField("company_industry_id");

    const onSubmit = handleSubmit((values) => {
      loading.value = true;
      let client = {
        user: {
          roles: ["sourcing_admin", "workforce_admin"],
          company_name: values.company_name,
          company_country_id:
            values.country_1 === "other" ? auxCountry.value : values.country_1,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          products: checkValues.value,
          product: "sourcing",
          company_industry_id: values.company_industry_id,
        },
      };
      if (isEditing.value) {
        editClient(client);
      } else {
        createClient(client);
      }
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    //Vars and functions
    const loading = ref(false);
    const error = ref(false);

    function closeModal() {
      resetForm();
      emit("update:openModal", false);
    }

    async function createClient(data: any) {
      ClientAPI.createClient(data)
        .then((response) => {
          createToastAxelerate("Client", response.data.message, "success");
          closeModal();
          store.commit("SET_REFRESH", true);
          loading.value = false;
        })
        .catch((error) => {
          const {
            response: {
              data: { errors },
            },
          } = error;
          createToastAxelerate("Clients", errors[0], "danger");
          loading.value = false;
        });
    }

    async function editClient(data: any) {
      ClientAPI.updateClient(data, clientId.value)
        .then((response) => {
          createToastAxelerate("Client", response.data.message, "success");
          closeModal();
          store.commit("SET_REFRESH", true);
          loading.value = false;
        })
        .catch((error) => {
          const {
            response: {
              data: { errors },
            },
          } = error;
          createToastAxelerate("Clients", errors[0], "danger");
          loading.value = false;
        });
    }

    watchEffect(() => {
      if (!isEditing.value) {
        checkValues.value = [];
      }

      switch (country_1.value) {
        case "":
          platforms[0].available = true;
          platforms[1].available = true;
          break;
        case "other":
          platforms[0].available = true;
          platforms[1].available = true;
          break;
        case countryMxId.value:
          platforms[0].available = false;
          platforms[1].available = true;
          auxCountry.value = "";
          break;
        default:
          platforms[0].available = false;
          platforms[1].available = false;
          auxCountry.value = "";
      }
    });

    watch(auxCountry, (newVal) => {
      if (newVal) {
        platforms[0].available = false;
        platforms[1].available = false;
      } else {
        platforms[0].available = true;
        platforms[1].available = true;
      }
    });

    onBeforeMount(() => {
      getCountries();
      getCompanyIndustries();
    });

    onMounted(async () => {
      if (props.client?.id) {
        isEditing.value = true;
        company_name.value = props.client.name;
        country_1.value = "";
        email.value = props.client.owner.email;
        first_name.value = props.client.owner.first_name;
        last_name.value = props.client.owner.last_name;
        clientId.value = props.client.owner.id;
        company_industry_id.value = props.client.company_industry_id;
        countrySelected.value = props.client.country;
        props.client.company_products.forEach((element: any) => {
          const name = element;
          checkValues.value.push(name as never);
        });
      }
    });

    const getCompanyCountrySelected = (company_country_id: string) => {
      if (company_country_id === countryMxId.value) {
        return countryMxId.value;
      }
      if (company_country_id === countryUsaId.value) {
        return countryUsaId.value;
      } else {
        return "other";
      }
    };

    const getCountries = () => {
      ClientAPI.getCountries()
        .then((response: any) => {
          countriesDefault.value = [];
          countries.value = response.data;
          countryMx.value = countries.value.find(
            (o: any) => o.short_code === "MX"
          );
          countryMxId.value = countryMx.value.id;
          countryUsa.value = countries.value.find(
            (o: any) => o.short_code === "US"
          );
          countryUsaId.value = countryUsa.value.id;
          countriesDefault.value.push(countryMx.value);
          countriesDefault.value.push(countryUsa.value);
          countriesDefault.value.push({
            id: "other",
            name: "Rest of the world",
            short_name: "ROTW",
          });

          if (props.client?.id) {
            country_1.value = getCompanyCountrySelected(
              props.client?.company_country.id
            );
            auxCountry.value = props.client?.company_country.id;
          }
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Get countries", errors[0], "danger");
        });
    };

    const getCompanyIndustries = () => {
      JobsAPI.getCompanyIndustries()
        .then((response: any) => {
          companyIndustries.value = response.data;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Company Industries", errors[0], "danger");
        });
    };

    return {
      loading,
      error,
      closeModal,
      onSubmit,
      fieldErrors,
      first_name,
      last_name,
      email,
      company_name,
      company_industry_id,
      companyRole,
      countries,
      platforms,
      checkValues,
      meta,
      country_1,
      isEditing,
      createClient,
      editClient,
      clientId,
      countrySelected,
      countriesDefault,
      auxCountry,
      companyIndustries,
    };
  },
});
