/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createToastAxelerate } from "@/utils/toast";
import { instanceLoggedAPI } from "../axiosConfig";

export async function getTalents(page: number) {
  try {
    const { data } = await instanceLoggedAPI.get("/talents", {
      params: { page },
    });
    return data;
  } catch (err) {
    return null;
  }
}

export async function getTalentsFiltered(
  status: string,
  company: string,
  page: number
): Promise<any> {
  const params = { page } as any;
  if (status !== "all_status") {
    params.status = status;
  }
  if (company !== "all_company") {
    params.company_id = company;
  }
  try {
    const { data } = await instanceLoggedAPI.get("/talents", {
      params: params,
    });
    return data;
  } catch (err) {
    return null;
  }
}

export async function getTalent(id: string): Promise<any> {
  try {
    const { data } = await instanceLoggedAPI.get(`/talents/${id}`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getNomilineaOptions(): Promise<any> {
  try {
    const { data } = await instanceLoggedAPI.get("/nomilinea_options");
    return data;
  } catch (err) {
    return null;
  }
}

export async function modifyTalentInformation(
  id: string,
  title: string,
  curp: string,
  rfc: string,
  date_of_entry: string,
  date_of_entry_imss: string,
  bank: string,
  account: string
): Promise<any> {
  const body = {
    title,
    CURP: curp,
    RFC: rfc,
    FechaIngreso: date_of_entry,
    FechaIMSS: date_of_entry_imss,
    Banco_ID: bank,
    Clabe: account,
  };
  try {
    const { data } = await instanceLoggedAPI.put(`/talents/${id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function addBenefit(job_id: string, benefit: any) {
  const body = {
    benefit: {
      name: benefit.name,
      value: benefit.value,
      currency: "usd",
    },
  };
  try {
    const { data } = await instanceLoggedAPI.post(
      `/positions/${job_id}/benefits`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function editBenefits(job_id: string, benefit: any) {
  const body = {
    benefit: {
      name: benefit.name,
      value: benefit.value,
    },
  };
  try {
    const { data } = await instanceLoggedAPI.put(
      `/positions/${job_id}/benefits/${benefit.id}`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getNomilineaTemplate() {
  try {
    const { data } = await instanceLoggedAPI.get("/nomilinea_template");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getCompanies() {
  try {
    const { data } = await instanceLoggedAPI.get("/companies");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getJobs(id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(`/companies/${id}/jobs`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function addTalent(body: any) {
  try {
    const { data } = await instanceLoggedAPI.post("/talents", body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function addNomilineaID(user_id: string, employee_id: string) {
  const body = {
    employee_id,
  };
  try {
    const { data } = await instanceLoggedAPI.post(
      `/talents/${user_id}/add_nomilinea_id`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function syncWithNomilinea(user_id: string) {
  try {
    const { data } = await instanceLoggedAPI.post(
      `/talents/${user_id}/sync_with_nomilinea`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function modifyTalentJob(
  id: string,
  title: string,
  description: string,
  responsibilities: string,
  requirements: string
) {
  const body = {
    title,
    description,
    responsibilities,
    requirements,
  };
  try {
    const { data } = await instanceLoggedAPI.put(`/talents/${id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getPositions(company_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(
      `/companies/${company_id}/positions`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function createPosition(company_id: string, body: any) {
  try {
    const { data } = await instanceLoggedAPI.post(
      `/companies/${company_id}/positions`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function updateTalentPosition(
  talent_id: string,
  position_id: string
) {
  const body = {
    talent: {
      position_id,
    },
  };
  try {
    const { data } = await instanceLoggedAPI.put(`/talents/${talent_id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getCauses() {
  try {
    const { data } = await instanceLoggedAPI.get(
      "/nomilinea_offboarding_template"
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function offboardTalent(talent_id: string, body: any) {
  const bodyParams = {
    nomilinea_profile: {
      baja_motivo: body.cause,
      baja_fecha: body.dismissal_date,
      justification: body.justification,
    },
  };
  try {
    const { data } = await instanceLoggedAPI.delete(
      `/talents/${talent_id}/nomilinea_profiles`,
      { data: bodyParams }
    );
    createToastAxelerate("Talent successfully terminated", "", "success");
    return data;
  } catch (err: any) {
    let message = "";
    if (err.response.errors) {
      message = err.response.errors[0];
    } else {
      message = "Please try again";
    }
    createToastAxelerate("Error terminating talent", message, "danger");
    return null;
  }
}
