import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login/index.vue";
import Jobs from "../views/jobs/index.vue";
import JobDetails from "../views/jobDetails/index.vue";
import Candidates from "../views/candidates/index.vue";
import TalentEvaluation from "../views/talentEvaluation/index.vue";
import CreateTalentEvaluation from "../views/createTalentEvaluation/index.vue";
import JobCandidates from "../views/jobCandidates/index.vue";
import EditTalentEvaluation from "../views/editTalentEvaluation/index.vue";
import GetTalentEvaluation from "../views/getTalentEvaluation/index.vue";
import JobBrowser from "../views/candidateJob/index.vue";
import Talents from "../views/talents/index.vue";
import Talent from "../views/talentDetails/index.vue";
import AddTalent from "../views/addTalent/index.vue";
import CreateJob from "../components/jobStepper/index.vue";
import EditJob from "../views/editJob/index.vue";
import CandidateShow from "../views/internalProfile/index.vue";
import Payrolls from "../views/payrolls/index.vue";
import Payroll from "../views/payrollDetail/index.vue";
import PayrollPeriods from "../views/payrollPeriods/index.vue";
import Expenses from "../views/expenses/index.vue";
import Settings from "../views/settings/index.vue";
import Clients from "../views/clients/index.vue";
import Client from "../views/client/index.vue";
import HiringFeesComponent from "../views/hiringFees/index.vue";
import JobCandidateShow from "../components/jobCandidateShow/index.vue";
import Role from "../views/addRole/index.vue";
import { useCookies } from "vue3-cookies";
import store from "@/store";
import JobShow from "../views/jobShow/index.vue";
import JobEvents from "../views/jobEvents/index.vue";
import CreateCandidate from "../views/createCandidate/index.vue";
import NotificationCenterComponent from "../views/notifications/index.vue";
import CompanyCandidate from "../views/companyCandidate/index.vue";

const { cookies } = useCookies();

import CandidateDetail from "../views/candidateDetail/index.vue";
import ClientDetail from "../views/clientDetail/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/create-job",
    name: "Create Job",
    component: CreateJob,
  },
  {
    path: "/edit-job/:id",
    name: "Edit Job",
    component: EditJob,
  },
  {
    path: "/job-details",
    name: "Job Details",
    component: JobDetails,
  },
  {
    path: "/candidates",
    name: "Candidates",
    component: Candidates,
  },
  {
    path: "/create-candidate/:jobId?",
    name: "Create Candidate",
    component: CreateCandidate,
  },
  {
    path: "/talent-evaluation",
    name: "Talent Evaluation",
    component: TalentEvaluation,
  },
  {
    path: "/create-talent-evaluation",
    name: "Create Talent Evaluation",
    component: CreateTalentEvaluation,
  },
  {
    path: "/edit-talent-evaluation",
    name: "Edit Talent Evaluation",
    component: EditTalentEvaluation,
  },
  {
    path: "/get-talent-evaluation",
    name: "Get Talent Evaluation",
    component: GetTalentEvaluation,
  },
  {
    path: "/job-candidates",
    name: "Job Candidates",
    component: JobCandidates,
  },
  {
    path: "/jobs/:id",
    name: "Job Browser",
    component: JobBrowser,
    children: [
      {
        path: "details",
        name: "Job Show",
        component: JobShow,
      },
      {
        path: "job-candidates/:job_candidate_id/:tab?",
        name: "JobCandidates",
        component: JobCandidateShow,
      },
      {
        path: "events",
        name: "Job Events",
        component: JobEvents,
      },
    ],
  },
  {
    path: "/talent",
    name: "Talent",
    component: Talents,
  },
  {
    path: "/talent/:id/:subpage",
    name: "Talent Detail",
    component: Talent,
  },
  {
    path: "/add-talent",
    name: "Add Talent",
    component: AddTalent,
  },
  {
    path: "/candidates/:id",
    name: "Candidate Show",
    component: CandidateShow,
  },
  {
    path: "/company-candidates/:id",
    name: "Company Candidates",
    component: CompanyCandidate,
  },
  {
    path: "/payroll",
    name: "Payrolls",
    component: Payrolls,
  },
  {
    path: "/payroll/:company_id",
    name: "Payroll Periods",
    component: PayrollPeriods,
  },
  {
    path: "/payroll/:company_id/:payroll_id",
    name: "Payroll",
    component: Payroll,
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/candidate-detail",
    name: "Candidate Detail",
    component: CandidateDetail,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
  },
  {
    path: "/client/:id",
    name: "Client",
    component: Client,
  },
  {
    path: "/client-detail/:id",
    name: "Client Detail",
    component: ClientDetail,
  },
  {
    path: "/hiring-fees",
    name: "Hiring Fees",
    component: HiringFeesComponent,
  },
  {
    path: "/add-role/",
    name: "Role",
    component: Role,
    props: (route) => ({
      companyId: route.query.companyId,
      roleId: route.query.roleId,
    }),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationCenterComponent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = cookies.get("token");
  const route = router.resolve({ path: to.fullPath });
  const absoluteURL = new URL(route.href, window.location.origin).href;

  if (token !== "") {
    localStorage.setItem("token", token);
    store.commit("lOGIN_FULLFILED", token);
  } else {
    store.dispatch("expiredToken");
  }
  const publicPages = ["/login", "/sign-up"];
  const authRequired = !publicPages.includes(to.path);
  // trying to access a restricted page + not logged in
  // redirect to login page

  if (token && !authRequired) {
    await store.dispatch("getProfile");
  }
  if (!publicPages.includes(to.path) && !token) {
    window.location.replace(
      `${process.env.VUE_APP_GLOBAL_LOGIN_URL}?redirect_path=${absoluteURL}`
    );
  } else if (publicPages.includes(to.path) && token) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
