<template>
  <div class="home">
    <Header />
    <div class="flex-grow bg-background shadow-inner min-h-screen">
      <div class="container py-4">
        <div class="sm:flex flex-col">
          <div
            class="sm:flex flex-col lg:flex-row items-start lg:space-x-5 my-5"
          >
            <div
              class="bg-white shadow rounded-2xl p-4 w-full lg:w-3/12 h-full"
            >
              <ul>
                <div v-for="(tab, index) in tabs" :key="index">
                  <li
                    v-on:click="clickTab(index)"
                    class="cursor-pointer py-3 px-5 rounded transition"
                    :class="activeTab !== index && 'opacity-50'"
                    :style="
                      activeTab === index ? { backgroundColor: '#F0FFFF' } : {}
                    "
                  >
                    <div class="flex flex-row space-x-3 justify-bewteen">
                      <div>
                        <img
                          v-show="tab === 'Account settings' && activeTab !== 0"
                          v-bind:src="SettingsIcon"
                        />
                        <img
                          v-show="
                            tab === 'Account settings' && activeTab === index
                          "
                          v-bind:src="SettingsHover"
                        />
                      </div>
                      <span class="font-semibold">{{ tab }}</span>
                    </div>
                  </li>
                  <div
                    v-show="index !== 2"
                    class="flex flex-row items-center py-1"
                  >
                    <div class="h-px w-full bg-gray-300 my-2"></div>
                  </div>
                </div>
              </ul>
            </div>
            <div
              v-if="activeTab === 0"
              class="w-full lg:w-9/12 h-full lg:space-y-5 mt-3 lg:mt-0"
            >
              <PrincipalUserSettings />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import Header from "../../components/header/index.vue";
import SettingsIcon from "../../assets/svgs/settings.svg";
import SettingsHover from "../../assets/svgs/settings-hover.svg";
import PrincipalUserSettings from "../../components/principalUserSettings/index.vue";
import { useRoute, useRouter } from "vue-router";

const Settings = defineComponent({
  name: "CreateJob",
  components: {
    Header,
    PrincipalUserSettings,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const tabs = ["Account settings"];
    const activeTab = ref(parseInt(route.query.tab, 10));
    const clickTab = (index) => {
      activeTab.value = index;
      router.replace({
        query: { ...route.query, tab: activeTab.value },
      });
    };
    watchEffect(() => {
      if (route.query.tab) {
        activeTab.value = parseInt(route.query.tab, 10);
      }
    });
    return {
      tabs,
      activeTab,
      SettingsIcon,
      SettingsHover,
      clickTab,
    };
  },
});
export default Settings;
</script>
