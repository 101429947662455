
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  watch,
  watchEffect,
  computed,
} from "vue";
import VueTableLite from "vue3-table-lite/ts";
import CompanyCard from "@/components/companyCard/index.vue";
import { CompanyAPI } from "@/services/companies";
import ModalAddCompany from "@/components/modalAddCompany/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import { useRouter } from "vue-router";
import { ClientAPI } from "@/services/clients/index";
import { useStore } from "vuex";
import Paginator from "@/components/paginator/index.vue";
import { removeBlankProperties } from "../../utils/params";
import SearchInput from "../../components/searchInput/index.vue";
import Tooltip from "../helpTooltip/index.vue";

export default defineComponent({
  name: "Clients Table",
  components: {
    VueTableLite,
    CompanyCard,
    ModalAddCompany,
    Paginator,
    SearchInput,
    // Tooltip,
  },
  props: {
    visible: Boolean,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const pagination: Ref<any> = ref({});
    const page: Ref<number> = ref(1);
    const tableData: Ref<Array<any>> = ref([]);
    const openModal = ref(false);
    const client = ref({});
    const openDropdown = ref("");
    const filters = ref({});
    const inputValue = ref("");
    const currentDropdown = ref("");
    store.commit("SET_REFRESH", false);
    const refresh = computed(() => store.getters.getRefresh);
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Client",
          field: "name",
          isKey: true,
          width: "10%",
        },
        {
          label: "Country",
          field: "country",
          sortable: true,
          width: "10%",
        },
        {
          label: "Company status",
          field: "is_active_company",
          sortable: true,
          width: "10%",
        },
        {
          label: "Invitation status",
          field: "is_active",
          sortable: true,
          width: "10%",
        },
        {
          label: "Products",
          field: "readable_products",
          sortable: false,
          width: "10%",
          columnClasses: ["text-capitalize"],
        },
        {
          label: "",
          field: "menu",
          sortable: true,
          width: "1%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });

    function updateHandler(e: any) {
      page.value = e;
      getCompanies();
    }

    async function getCompanies() {
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      const res = (await CompanyAPI.getAllCompanies(
        _filters,
        page.value
      )) as any;
      if (res) {
        const transformCompanyProductToHumanString = (item: any) => {
          item.readable_products = item.company_products
            ? item.company_products.join(" , ")
            : "";
        };
        res.data.companies.forEach(transformCompanyProductToHumanString);
        pagination.value = res.data.meta.pagination;
        tableData.value = res.data.companies;
        setTable(tableData.value);
      }
      table.isLoading = false;
    }

    const closeMenu = () => {
      currentDropdown.value = "";
    };

    const rowClicked = (row: any) => {
      router.push(`/client-detail/${row.id}`);
    };

    async function deactivateClient(client: any) {
      ClientAPI.deactivateClient(client.id)
        .then((response) => {
          createToastAxelerate("Client", response.data.message, "success");
          store.commit("SET_REFRESH", true);
          closeMenu();
        })
        .catch(() => {
          createToastAxelerate(
            "Deactivate client",
            "An error has occurred",
            "danger"
          );
        });
    }

    const openDropDown = (data: any) => {
      client.value = data;
      currentDropdown.value = data.id;
    };

    async function resendInvitation(id: string) {
      const response = await ClientAPI.resendInvitation(id);
      createToastAxelerate("Client", response.data.message, "success");
      store.commit("SET_REFRESH", true);
      closeMenu();
    }

    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    watchEffect(() => {
      if (refresh.value) {
        getCompanies();
        store.commit("SET_REFRESH", false);
      }

      watch(
        () => refresh.value,
        () => {
          store.commit("SET_REFRESH", false);
        }
      );
    });

    const searchFilter = () => {
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      CompanyAPI.getAllCompanies(_filters, page.value).then((res: any) => {
        const transformCompanyProductToHumanString = (item: any) => {
          item.readable_products = item.company_products
            ? item.company_products.join(" , ")
            : "";
        };
        res.data.companies.forEach(transformCompanyProductToHumanString);
        pagination.value = res.data.meta.pagination;
        tableData.value = res.data.companies;
        setTable(tableData.value);
        table.isLoading = false;
      });
    };

    function searchTitle(event: any) {
      const value = event.target.value.toLowerCase();
      const data = tableData.value.filter(function (row) {
        return (
          row.name.toLowerCase().includes(value) ||
          row.name.toLowerCase().includes(value) ||
          row.id.includes(value)
        );
      });
      setTable(data);
    }

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
      });
    });

    onMounted(() => {
      getCompanies();
    });

    watch(inputValue, (newVal) => {
      if (newVal) {
        page.value = 1;
      }
    });

    return {
      table,
      closeMenu,
      searchTitle,
      openDropdown,
      currentDropdown,
      openDropDown,
      openModal,
      client,
      deactivateClient,
      resendInvitation,
      rowClicked,
      pagination,
      page,
      updateHandler,
      inputValue,
      searchFilter,
    };
  },
});
