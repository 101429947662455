
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, Ref, watch, onMounted } from "vue";
import SearchInput from "@/components/searchInput/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import Divider from "@/components/divider/index.vue";
import Filter from "@/components/filter/index.vue";
import UserCard from "@/components/userCard/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import { getTalents, getTalentsFiltered } from "@/services/talents/index";

export default defineComponent({
  components: {
    SearchInput,
    DateFormatter,
    Divider,
    Filter,
    UserCard,
    Loader,
    ErrorState,
  },
  setup() {
    const loading = ref(false);
    const error = ref(false);
    const talents = ref([]);
    const status = ref("all_status");
    const company = ref("all_company");
    const searchQuery = ref("");
    const openFilterStatus = ref(true);
    const openFilterCompany = ref(true);
    const filterStatusOptions: Ref<Array<any>> = ref([]);
    const filterCompanyOptions: Ref<Array<any>> = ref([]);
    const statuses: Ref<Array<any>> = ref([]);
    const companies: Ref<Array<any>> = ref([]);

    async function getAllTalents() {
      loading.value = true;
      const data = await getTalents(1);
      if (data) {
        talents.value = data.talents;
        statuses.value = data.meta.status;
        companies.value = data.meta.company;
        companies.value = companies.value.filter((x) => x.talents_count > 0);
        for (let i = 0; i < companies.value.length; i++) {
          filterCompanyOptions.value.push(companies.value[i].visible_name);
        }
        Object.entries(statuses.value).forEach((item) => {
          filterStatusOptions.value.push(item[0]);
        });
      } else {
        error.value = true;
      }
      loading.value = false;
    }
    async function getFilteredTalents(
      status: string,
      company: string,
      current_page = 1
    ) {
      filterCompanyOptions.value = [];
      filterStatusOptions.value = [];
      talents.value = [];
      loading.value = true;
      const data = await getTalentsFiltered(status, company, current_page);
      if (data) {
        talents.value = data.talents;
        statuses.value = data.meta.status;
        companies.value = data.meta.company as Array<any>;
        companies.value = companies.value.filter((x) => x.talents_count > 0);
        for (let i = 0; i < companies.value.length; i++) {
          filterCompanyOptions.value.push(companies.value[i].visible_name);
        }
        Object.entries(statuses.value).forEach((item) => {
          filterStatusOptions.value.push(item[0]);
        });
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    watch(status, (newVal) => {
      let newStatus = "";
      if (newVal === "all") {
        newStatus = "all_status";
      } else {
        newStatus = newVal;
      }
      getFilteredTalents(newStatus, company.value, 1);
    });
    watch(company, (newVal) => {
      const val = companies.value.find((x) => x.visible_name === newVal);
      let newCompany = "";
      if (newVal === "all") {
        newCompany = "all_company";
      } else {
        newCompany = val.id;
      }
      getFilteredTalents(status.value, newCompany, 1);
    });

    onMounted(() => {
      getAllTalents();
    });

    return {
      loading,
      error,
      talents,
      status,
      company,
      searchQuery,
      openFilterStatus,
      filterStatusOptions,
      openFilterCompany,
      filterCompanyOptions,
    };
  },
});
