/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosError } from "axios";

import { instanceLoggedAPI } from "../axiosConfig";

export async function getReimbursements(page: number) {
  try {
    const { data } = await instanceLoggedAPI.get("/reimbursements", {
      params: { page },
    });
    return data;
  } catch (err) {
    return null;
  }
}

export async function filterReimbursements(
  filter: string,
  status: string,
  page: number
) {
  const params = { page } as any;
  if (filter !== "" && filter !== "all") {
    params.detail = filter;
  }
  if (status !== "" && status !== "all") {
    params.status = status;
  }
  try {
    const { data } = await instanceLoggedAPI.get("/reimbursements", {
      params: params,
    });
    return data;
  } catch (err) {
    return null;
  }
}

export async function createReimbursement(user_id: string, body: any) {
  const formData = new FormData();
  const amountCents = String(body.amount * 100);
  formData.append("reimbursement[title]", body.title);
  formData.append("reimbursement[amount]", amountCents);
  formData.append("reimbursement[currency]", body.currency);
  formData.append("reimbursement[detail]", body.detail);
  if (body.note) {
    formData.append("reimbursement[note]", body.note);
  }
  for (let i = 0; i < body.vouchers.length; i++) {
    formData.append("reimbursement[vouchers][]", body.vouchers[i]);
  }
  try {
    const { data } = await instanceLoggedAPI.post(
      `/users/${user_id}/reimbursements`,
      formData
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function editReimbursement(reimbursment_id: string, body: any) {
  const formData = new FormData();
  const amountCents = String(body.amount * 100);
  formData.append("reimbursement[title]", body.title);
  formData.append("reimbursement[amount]", amountCents);
  formData.append("reimbursement[currency]", body.currency);
  formData.append("reimbursement[detail]", body.detail);
  if (body.note) {
    formData.append("reimbursement[note]", body.note);
  }
  if (body.vouchers) {
    for (let i = 0; i < body.vouchers.length; i++) {
      formData.append("reimbursement[vouchers][]", body.vouchers[i]);
    }
  }
  try {
    const { data } = await instanceLoggedAPI.put(
      `/reimbursements/${reimbursment_id}`,
      formData
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function updateReimbursement(
  note: string,
  status: string,
  id: string
) {
  const body = {
    note: note,
    status: status,
  };
  try {
    const { data } = await instanceLoggedAPI.put(`/reimbursements/${id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getNextPayrollDates(user_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(
      `users/${user_id}/payroll_periods`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function deleteRemibursement(reimbursement_id: string) {
  try {
    const { data } = await instanceLoggedAPI.delete(
      `/reimbursements/${reimbursement_id}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function deleteReimbursementFile(
  reimbursement_id: string,
  files: any
) {
  try {
    const { data } = await instanceLoggedAPI.delete(
      `/reimbursements/${reimbursement_id}/vouchers`,
      { data: { identifiers: files } }
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function getTalents() {
  try {
    const { data } = await instanceLoggedAPI.get("/talents");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getClients() {
  try {
    const { data } = await instanceLoggedAPI.get(
      "/x/company_list?on_workforce=true"
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getCompanyTalents(company_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(
      `/companies/${company_id}/talents`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getExtraCharges() {
  try {
    const { data } = await instanceLoggedAPI.get("/extra_charges");
    return data;
  } catch (err) {
    return null;
  }
}

export async function createExtraCharge(company_id: string, body: any) {
  try {
    const { data } = await instanceLoggedAPI.post(
      `/companies/${company_id}/extra_charges`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function editExtraCharge(
  extra_charge_id: string,
  payroll_period_id: string,
  body: any
) {
  try {
    const { data } = await instanceLoggedAPI.put(
      `/payroll_periods/${payroll_period_id}/extra_charges/${extra_charge_id}`,
      body
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}
