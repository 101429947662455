<template>
  <div class="flex flex-col">
    <div
      class="h-64 relative rounded-2xl p-8"
      :class="
        fileName
          ? 'input-file-content bg-gray-100 border-2 border-purple-400 '
          : 'input-file'
      "
    >
      <input
        class="opacity-0 z-20 absolute w-full top-0 bottom-0 -ml-8 appearance-none cursor-pointer"
        name="file"
        id="entry_value"
        ref="fileInput"
        type="file"
        :accept="fileTypes"
        :onchange="getFileName"
        @change="$emit('update:modelValue', $event.target.files[0])"
      />
      <div
        class="relative h-full w-full flex flex-col justify-center items-center animate__animated animate__fadeIn animate__delay-0.6s"
        v-if="!fileName"
      >
        <img src="@/assets/global-board/icons/upload.svg" class="mb-3" />
        <span class="text-gray-500 font-medium text-base">{{ label }}</span>
        <span class="text-gray-500 font-mediu">OR</span>
        <span
          class="bg-primary text-white px-5 py-3 rounded-3xl cursor-pointer font-medium"
          >Browse file</span
        >
      </div>
      <div
        v-else
        class="relative h-full w-full flex flex-col justify-center items-center animate__animated animate__fadeIn animate__delay-0.6s"
      >
        <div class="flex flex-col justify-center items-center p-5">
          <svg
            class="mb-2"
            width="89"
            height="88"
            viewBox="0 0 89 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.6492 58.0922H70.2053M12.0693 85.9967H77.1813C82.3185 85.9967 86.483 81.8321 86.483 76.695V11.583C86.483 6.4458 82.3185 2.28125 77.1813 2.28125H30.6727C25.5356 2.28125 21.371 6.4458 21.371 11.583V76.695C21.371 81.8321 17.2065 85.9967 12.0693 85.9967C6.93191 85.9967 2.76758 81.8321 2.76758 76.695V25.5356C2.76758 20.3983 6.93191 16.2339 12.0693 16.2339H21.371L12.0693 85.9967ZM37.6492 20.8849H70.2053H37.6492ZM37.6492 39.4883H70.2053H37.6492Z"
              stroke="#BD8EF6"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-semibold text-gray-500">{{ fileName }}</span>
          <!-- <span
            class="border-2 border-primary text-purple-500 px-5 py-2 rounded-3xl cursor-pointer mt-2 flex flex-row justify-center items-center font-medium"
          >
            <svg
              class="h-6 w-6 mr-3"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />
              <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" /></svg
            >Change file</span
          > -->
          <div class="mt-2">
            <span class="text-primary font-semibold mt-2 underline"
              >You can change your file</span
            >
            <span class="text-primary font-thin">
              or drag & drop your file here</span
            >
          </div>
        </div>
      </div>
      <span id="fileName" class="text-primary border"> </span>
    </div>
    <span class="text-base text-gray-300 mt-0.5">{{ placeholder }}</span>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { defineComponent, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

const DragAndDropFile = defineComponent({
  name: "DragAndDropFile",
  props: {
    label: {
      type: String,
      require: true,
    },
    placeholder: {
      type: String,
      require: true,
    },
    fileTypes: {
      type: String,
      require: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup(props, contex) {
    const files = ref([]);
    const fileName = ref("");
    const router = useRouter();
    const imgPath = ref("");

    const getFileName = (event) => {
      files.value = [];
      fileName.value = "";
      let maximumValue = 5;
      if (event) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (event.target.files[i].size / 1024 / 1024 <= maximumValue) {
            files.value.push(event.target.files[i]);
            fileName.value = event.target.files[i].name;
          }
        }
      }

      imgPath.value = window.URL.createObjectURL(event.target.files[0]);
    };

    return {
      files,
      router,
      fileName,
      getFileName,
      imgPath,
    };
  },
});
export default DragAndDropFile;
</script>
<style scoped>
.input-file {
  background-color: #eff0f6;
}

.input-file-content {
}

.container-text {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 170px;
  color: black;
  font-family: Arial;
  position: absolute;
  top: 10%;
  z-index: -1;
}

.drop-image {
}
</style>
