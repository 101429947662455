/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceLoggedAPI, instanceFormAPI } from "../axiosConfig";
export default class HiringFeesAPIService {
  private axiosInstance: AxiosInstance;
  private instanceForm: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.instanceForm = instanceFormAPI;
  }

  async createHiringFee(hiringFee: unknown): Promise<unknown> {
    return this.axiosInstance.post("/expenses", hiringFee);
  }

  async getCompanies(): Promise<unknown> {
    return this.axiosInstance.get(`/list_sourcing_companies`);
  }

  async editHiringFee(hiringFee: unknown, hiringFeeId: any): Promise<unknown> {
    return this.axiosInstance.put(`/expenses/${hiringFeeId}`, hiringFee);
  }

  async getHiringFees(filters: any, page: number): Promise<unknown> {
    return this.axiosInstance.get(
      `/expenses?page=${page}&${makeFilter(filters)}`
    );
  }

  async deleteHiringFee(id: string): Promise<unknown> {
    return this.axiosInstance.delete(`/expenses/${id}`);
  }
}
export const HiringFeeAPI = new HiringFeesAPIService();
