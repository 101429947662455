
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, ref, reactive } from "vue";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { useState } from "@/composables/useState";
import Avatar from "../avatar/index.vue";
import VPagination from "@hennge/vue3-pagination";
import Skeleton from "../skeleton/index.vue";
import { useRoute } from "vue-router";
import { JobsAPI } from "@/services/jobs";
import { createToastAxelerate } from "../../utils/toast";

const EvaluationJobStep = defineComponent({
  name: "EvaluationJobStep",
  emits: ["setEvaluation"],
  components: {
    Avatar,
    VPagination,
    Skeleton,
  },
  props: {
    evaluationData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const evaluationSelected = ref(false);
    const showRemove = ref(true);
    const initialState = {
      id: "",
      created_at: "",
      title: "",
      updated_at: "",
    };
    const selectedEvaluation = reactive({
      id: String,
      created_at: String,
      title: String,
      updated_at: String,
    });
    onBeforeMount(() => {
      if (props?.evaluationData?.evaluation_id !== "") {
        evaluationSelected.value = true;
        selectedEvaluation.id = props?.evaluationData?.id;
        selectedEvaluation.title = props?.evaluationData?.evaluation_title;
      }
      setIsLoading(true);
      if (route.params.id) {
        JobsAPI.getOneJob(String(route.params.id)).then((res: any) => {
          if (res.data.job_evaluation?.id) {
            showRemove.value = false;
            Object.assign(selectedEvaluation, res.data.job_evaluation);
            evaluationSelected.value = true;
          }
        });
      }
      TalentEvaluationAPI.getAllTemplates().then((res: any) => {
        setData(res.data.evaluations);
        setIsLoading(false);
      });
    });

    const selectEvaluation = async (evaluation: any, option: string) => {
      setIsLoading(true);
      if (option === "add") {
        Object.assign(selectedEvaluation, evaluation);
        evaluationSelected.value = true;
      } else {
        Object.assign(selectedEvaluation, initialState);
        evaluationSelected.value = false;
      }
      setIsLoading(false);
      context.emit("setEvaluation", selectedEvaluation);
    };
    const updateHandler = (e: number) => {
      setPage(e);
    };
    return {
      data,
      isLoading,
      updateHandler,
      page,
      selectEvaluation,
      evaluationSelected,
      selectedEvaluation,
      showRemove,
    };
  },
});

export default EvaluationJobStep;
