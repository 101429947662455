<template>
  <div
    class="relative inline-block text-left text-black"
    v-click-away="closeMenu"
  >
    <div>
      <span class="rounded-md shadow-sm">
        <button
          @click="isMenuOpen = !isMenuOpen"
          type="button"
          class="inline-flex items-center justify-between w-full rounded-md h-10 px-4 py-4 bg-primary text-white hover:text-gray-200 transition ease-in-out duration-150"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <span class="lg:text-base text-xs">
            {{ profile && `${profile.first_name} ${profile.last_name}` }}
          </span>
          <div class="pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </button>
      </span>
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="origin-top-right absolute right-0 mt-2 w-96 rounded-2xl shadow-2xl text-sm overflow-hidden z-20"
      >
        <div
          class="rounded-md bg-white w-full px-2 py-3"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div>
            <div class="p-4 flex items-center">
              <div class="w-full">
                <div class="flex flex-row">
                  <div v-if="profile?.avatar?.url">
                    <div
                      class="m-1 w-12 h-12 relative flex justify-center items-center rounded-full text-xl text-white uppercase bg-cover"
                      :style="`background-image: url(${profile.avatar?.url})`"
                    ></div>
                  </div>
                  <div
                    v-else
                    class="flex justify-center items-center rounded-full bg-primary text-white w-12 h-12 p-4"
                  >
                    {{
                      getInitials(
                        profile && `${profile.first_name} ${profile.last_name}`
                      )
                    }}
                  </div>
                  <div class="flex flex-col pl-3 py-1">
                    <span class="font-semibold text-base">{{
                      profile && `${profile.first_name} ${profile.last_name}`
                    }}</span>
                    <span class="text-sm text-gray-500">{{
                      profile && profile.email
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              profile &&
              profile.company &&
              profile.company.subscription_status === 'inactive'
            "
            class="flex flex-row justify-between bg-background-secondary rounded mt-2 p-4"
          >
            <div class="flex flex-col">
              <span class="font-medium">Professional plan</span>
              <span>Monthly renewal</span>
            </div>
            <button
              v-on:click="router.push('/subscriptions')"
              class="text-primary font-semibold"
            >
              Upgrade
            </button>
          </div>
          <div
            v-else
            class="flex flex-row justify-between bg-background-secondary rounded mt-2 p-4"
          >
            <div class="flex flex-col">
              <span class="font-medium">
                {{
                  profile &&
                  profile.company &&
                  profile.company.last_subscription?.nickname &&
                  profile.company.last_subscription?.nickname
                    ? profile.company.last_subscription?.nickname
                    : "Professional plan"
                }}
              </span>
              <span>Monthly renewal</span>
            </div>
            <span class="text-primary font-semibold p-3"> Active </span>
          </div>
          <div class="flex flex-col mt-4">
            <button
              @click="router.push('/settings?tab=0')"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center">
                <img src="../../assets/navbar-icons/settings.svg" />
                <span class="font-semibold ml-4">Account settings</span>
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button
              @click="router.push('/settings?tab=1')"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center">
                <img src="../../assets/navbar-icons/dribbble.svg" />
                <span class="font-semibold ml-4">Account members</span>
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button
              @click="router.push('/settings?tab=2')"
              class="rounded p-4 hover:bg-background"
            >
              <div class="flex flex-row items-center">
                <img src="../../assets/navbar-icons/dollar.svg" />
                <span class="font-semibold ml-4">Billing</span>
              </div>
            </button>
            <div class="h-0.5 bg-gray-200 mx-4 my-2"></div>
            <button @click="logOut" class="rounded p-4 hover:bg-background">
              <div class="flex flex-row items-center">
                <img src="../../assets/navbar-icons/logout.svg" />
                <span class="font-semibold ml-4">Log out</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getInitials } from "../../utils/functions";

const MenuDropDown = defineComponent({
  name: "MenuDropdown",
  emits: ["logOut"],
  setup(props, context) {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const store = useStore();
    const profile = computed(() => store.state.profile.data);
    const logOut = () => {
      context.emit("logOut");
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    return {
      isMenuOpen,
      closeMenu,
      router,
      logOut,
      profile,
      getInitials,
    };
  },
});

export default MenuDropDown;
</script>
