<template>
  <div class="flex flex-col mt-5">
    <div v-for="(item, index) in _notifications" :key="index" class="p-1">
      <div class="flex flex-row justify-between w-full py-2">
        <div
          class="flex flex-row justify-between items-center space-x-3 w-full py-1 px-2 rounded-lg"
          :class="getHihglightColor(item.time_line?.event_type)"
        >
          <!---------------------- NOTIFICATION TYPE ------------------------->
          <div
            class="border-l-4 h-8"
            :class="
              item.status_notification === 'read'
                ? 'border-gray-300'
                : 'border-purple-600'
            "
          ></div>
          <img
            :src="buildImageStage(item.time_line?.event_type)"
            alt="icon stage"
            class="p-2 w-10"
          />
          <!---------------------- COMMENTS ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'comment_creation'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                commented on
                <strong class="text-sm font-semibold"
                  >{{ item?.time_line?.job_candidate?.candidate?.first_name }}
                  {{
                    item?.time_line?.job_candidate?.candidate?.last_name
                  }}'s</strong
                >
                timeline
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- COMMENTS CREATION TAGGED------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_activity_comment'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                commented on

                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong
                >.</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- JOB COMMENTS TAGGED ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_comment_tagged'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                commented on
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong
                >.</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- UPDATE ------------------->
          <div class="w-full" v-if="item.time_line?.event_type === 'update'">
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                has edited the job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- CHANGE JOB PUBLISH DATE ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_update_publish_date'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                has edited the publication date of the job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong></span
              >
              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- JOB CLOSED ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_closed'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                position has been closed by
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                ></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- JOB ASSIGN RECRUITER ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'assigned_job_responsible'"
          >
            <div class="text-sm">
              <span>
                You have been assigned as the responsible recruiter for
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                position by
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                ></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- JOB REOPENED ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_reopened'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                position has been reopened by
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                ></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- MANAGED JOB CREATION ------------------->
          <div
            class="w-full mt-1"
            v-if="item.time_line?.event_type === 'managed_job_created'"
          >
            <div class="text-sm">
              <span>
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                created a managed job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- MANAGED JOB TURN ON ------------------->
          <div
            class="w-full mt-1"
            v-if="item.time_line?.event_type === 'managed_job_turned_on'"
          >
            <div class="text-sm">
              <span>
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                turned on the managed service for the
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                position
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- MANAGED JOB TURN OFF ------------------->
          <div
            class="w-full mt-1"
            v-if="item.time_line?.event_type === 'managed_job_turned_off'"
          >
            <div class="text-sm">
              <span>
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                turned off the managed service for the
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                position
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!---------------------- COMMENTS TAGGED ------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'comment_creation_tagged'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                has tagged you in a comment
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!--------------------- NEW JOB --------------------->
          <div class="w-full" v-if="item.time_line?.event_type === 'new_job'">
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                has created the job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!----------------- STAGE CHANGE ---------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_candidate_stage_change'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{
                    item.time_line?.job_candidate?.candidate?.last_name
                  }}</strong
                >
                has been moved to
                <strong class="text-sm font-semibold">{{
                  item.time_line?.sourceable?.stage_name
                }}</strong></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!------------------ MEETING--------------------------->

          <div
            class="w-full"
            v-if="
              item.time_line?.event_type === 'job_candidate_meeting_scheduled'
            "
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                created an interview meeting for
                <strong class="text-sm font-semibold"
                  >{{ item?.time_line?.job_candidate?.candidate?.first_name }}
                  {{
                    item?.time_line?.job_candidate?.candidate?.last_name
                  }}</strong
                >
                in job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div>
                <p class="text-gray-400 text-sm mt-1">
                  {{ item.time_line?.sourceable?.content }}
                  <!--  <button class="font-semibold text-purple-500 cursor-pointer">
                    Read more
                  </button> -->
                </p>
              </div>
            </div>
          </div>

          <!-------------- CODE CHALLENGE RESULTS --------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type ===
              'job_candidate_code_challenge_result'
            "
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >
                has finished the code challenge for the job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                position</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------- EVALUATION RESULTS --------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type === 'job_candidate_evaluation_result'
            "
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{
                    item.time_line?.job_candidate?.candidate?.last_name
                  }}'s</strong
                >
                evaluations for
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title ? item.time_line?.job?.title : "-"
                }}</strong>
                is ready.</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------- CODE CHALLENGE SENT --------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type === 'job_candidate_code_challenge_sent'
            "
          >
            <div class="text-sm">
              <span>
                The code challenge
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.sourceable?.job_challenge_title }}
                </strong>
                was sent to
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{ item.time_line?.job_candidate?.candidate?.last_name }}
                </strong>
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------- CODE CHALLENGE STARTED --------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type ===
              'job_candidate_code_challenge_started'
            "
          >
            <div class="text-sm">
              <span>
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{ item.time_line?.job_candidate?.candidate?.last_name }}
                </strong>
                started the code challenge
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------- CODE CHALLENGE HASNT STARTED --------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type ===
              'job_candidate_code_challenge_hasnt_started_remainder'
            "
          >
            <div class="text-sm">
              <span>
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{ item.time_line?.job_candidate?.candidate?.last_name }}
                </strong>
                has not started the code challenge, re-send the challenge
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------- CODE CHALLENGE REDSENT --------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type ===
              'job_candidate_code_challenge_resent'
            "
          >
            <div class="text-sm">
              <span>
                The code challenge
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.sourceable?.job_challenge_title }}
                </strong>
                was re-sent to
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{ item.time_line?.job_candidate?.candidate?.last_name }}
                </strong>
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------------- APPLY JOB --------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'candidate_applied_to_job'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{
                    item.time_line?.job_candidate?.candidate?.last_name
                  }}</strong
                >
                applied to the
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title ? item.time_line?.job?.title : "-"
                }}</strong>
                position via job board</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!----------------- CANDIDATE ADDED TO JOB ---------------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_candidate_added'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.job_candidate?.candidate?.first_name }}
                  {{
                    item.time_line?.job_candidate?.candidate?.last_name
                  }}</strong
                >
                has been added to the job
                <strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title ? item.time_line?.job?.title : "-"
                }}</strong>

                <strong class="text-sm font-semibold">{{
                  item.time_line?.sourceable?.stage_name
                }}</strong></span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
              <div></div>
            </div>
          </div>

          <!-------------- CANDIDATE HIRED --------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_candidate_hired'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.sourceable?.first_name }}
                  {{ item.time_line?.sourceable?.last_name }}</strong
                >
                has been hired by
                <strong class="text-sm font-semibold"
                  >{{ item.time_line?.staff?.first_name }}
                  {{ item.time_line?.staff?.last_name }}</strong
                >.</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
            </div>
          </div>

          <!------------ INACTIVITY JOB  ----------------->
          <div
            class="w-full"
            v-if="item.time_line?.event_type === 'job_with_inactivity'"
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold">{{
                  item.time_line?.job?.title
                }}</strong>
                hasn't had any activity in the last week.</span
              >

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
            </div>
          </div>

          <!------------ INACTIVITY JOB  ----------------->
          <div
            class="w-full"
            v-if="
              item.time_line?.event_type === 'job_candidate_with_inactivity'
            "
          >
            <div class="text-sm">
              <span
                ><strong class="text-sm font-semibold"
                  >{{ item.time_line?.sourceable?.candidate?.first_name }}
                  {{ item.time_line?.sourceable?.candidate?.last_name }}</strong
                >
                on the <strong>{{ item.time_line?.job.title }}</strong>
                position hasn't had any activity in the last week
              </span>

              <span class="font-light text-gray-400 text-sm italic">
                • {{ timeAgo(item?.created_at) }}</span
              >
            </div>
          </div>
          <button @click="update(item, index)">
            <img src="../../assets/svgs/share.svg" class="w-6" />
          </button>
        </div>
      </div>
      <div class="w-full border border-t-0"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { timeAgo } from "../../utils/functions";
import { useRouter } from "vue-router";
import { NotificationsAPI } from "../../services/notifications/index";
import { createToastAxelerate } from "../../utils/toast";

const NotificationList = defineComponent({
  name: "NotificationList",
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const _notifications = computed(() => props.notifications);
    const router = useRouter();
    const data = ref("");
    const buildImageStage = (option) => {
      switch (option) {
        case "comment_creation":
          return require("@/assets/svgs/comment.svg");
        case "job_activity_comment":
          return require("@/assets/svgs/comment.svg");
        case "comment_creation_tagged":
          return require("@/assets/svgs/comment.svg");
        case "new_job":
          return require("@/assets/svgs/jobs.svg");
        case "managed_job_created":
          return require("@/assets/svgs/jobs.svg");
        case "managed_job_turned_off":
          return require("@/assets/svgs/link.svg");
        case "managed_job_turned_on":
          return require("@/assets/svgs/unlink.svg");
        case "assigned_job_responsible":
          return require("@/assets/svgs/jobs.svg");
        case "job_closed":
          return require("@/assets/svgs/jobs.svg");
        case "job_reopened":
          return require("@/assets/svgs/jobs.svg");
        case "job_update_publish_date":
          return require("@/assets/svgs/jobs.svg");
        case "job_candidate_stage_change":
          return require("@/assets/svgs/candidates.svg");
        case "job_candidate_code_challenge_result":
          return require("@/assets/svgs/talent.svg");
        case "job_candidate_code_challenge_sent":
          return require("@/assets/timeline/callenge.svg");
        case "job_candidate_code_challenge_resent":
          return require("@/assets/timeline/callenge.svg");
        case "job_candidate_code_challenge_started":
          return require("@/assets/timeline/callenge.svg");
        case "job_candidate_code_challenge_hasnt_started_remainder":
          return require("@/assets/timeline/reminder.svg");
        case "job_candidate_evaluation_result":
          return require("@/assets/svgs/results.svg");
        case "job_candidate_hired":
          return require("@/assets/timeline/hiring.svg");
        case "job_with_inactivity":
          return require("@/assets/svgs/jobs.svg");
        case "job_candidate_with_inactivity":
          return require("@/assets/svgs/candidates.svg");
        case "update":
          return require("@/assets/svgs/edit.svg");
        case "job_candidate_meeting_scheduled":
          return require("@/assets/timeline/interview.svg");
        case "candidate_applied_to_job":
          return require("@/assets/svgs/candidates.svg");
        case "job_candidate_added":
          return require("@/assets/svgs/jobs.svg");
        default:
          return require("@/assets/timeline/arrow.svg");
      }
    };

    function update(event, index) {
      if (event.status_notification === "read") {
        window.open(
          `${process.env.VUE_APP_BASE_URL}${event.action_url}`,
          "_blank"
        );
      } else {
        NotificationsAPI.readNotification(event.id)
          .then(() => {
            _notifications.value[index].status_notification = "read";
            window.open(
              `${process.env.VUE_APP_BASE_URL}${event.action_url}`,
              "_blank"
            );
          })
          .catch((err) => {
            const {
              response: {
                data: { errors },
              },
            } = err;
            createToastAxelerate("Notifications center", errors[0], "danger");
          });
      }
    }

    const getHihglightColor = (event) => {
      let hihglightColor = "";
      switch (event) {
        case "managed_job_turned_on":
          hihglightColor = "bg-yellow-100";
          break;
        case "managed_job_turned_off":
          hihglightColor = "bg-red-100";
          break;
        case "managed_job_created":
          hihglightColor = "bg-green-100";
          break;
      }
      return hihglightColor;
    };

    return {
      data,
      buildImageStage,
      timeAgo,
      update,
      router,
      getHihglightColor,
      _notifications,
    };
  },
});

export default NotificationList;
</script>
