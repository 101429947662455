export const companies = [{ id: "1", name: "Axented" }];

export const companyRole = [{ id: "admin", name: "Admin" }];

export const countries = [
  { id: "usa", name: "USA" },
  { id: "mx", name: "México" },
];

export const platforms = [
  { id: "sourcing", name: "Talent Sourcing", available: false },
  { id: "workforce", name: "Workforce Solutions", available: false },
];

export const collaborator_role = [
  { id: "internal_recruiter", name: "Internal Recruiter" },
  { id: "internal_hr", name: "Internal HR" },
  { id: "recruiter_admin", name: "Recruiter Admin" },
];
