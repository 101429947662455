
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, PropType, ref, watch } from "vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import UserCard from "@/components/userCard/index.vue";
import ModalExpenses from "@/components/modalExpenses/index.vue";
import ModalReason from "@/components/modalReason/ModalReason.vue";
import ModalDecision from "@/components/modalDecideReimbursement/index.vue";
import { onClickOutside } from "@vueuse/core";
import { deleteRemibursement } from "@/services/expenses/index";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Reimbursement Card",
  components: {
    DateFormatter,
    UserCard,
    ModalExpenses,
    ModalReason,
    ModalDecision,
  },
  props: {
    reimbursement: Object as PropType<any>,
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    updateData: Boolean,
  },
  emits: ["update:updateData"],
  setup(props, { emit }) {
    const openModal = ref(false);
    const openReason = ref(false);
    const openDecision = ref(false);
    const hover = ref(false);
    const openDropdown = ref("");
    const dropdown = ref(null);
    const refreshData = ref(false);

    //functions
    function showDropdown(id: string) {
      openDropdown.value = id;
    }

    async function deleteReimbursement(id: string) {
      const data = await deleteRemibursement(id);
      if (!data.errors) {
        createToastAxelerate(
          "Reimbursement Request",
          "The reimbursement request has been deleted successfully",
          "success"
        );
        emit("update:updateData", false);
        openDropdown.value = "";
      } else {
        createToastAxelerate("Reimbursement Request", data.errors[0], "danger");
        openDropdown.value = "";
      }
    }

    onClickOutside(dropdown, () => {
      openDropdown.value = "";
    });

    /* watch(openModal, () => {
      emit("update:updateData", openModal.value);
    }); */
    watch(refreshData, () => {
      emit("update:updateData", true);
    });

    return {
      openModal,
      openReason,
      hover,
      openDropdown,
      openDecision,
      dropdown,
      showDropdown,
      deleteReimbursement,
    };
  },
});
