<template>
  <div class="flex flex-row space-x-3 justify-between">
    <div class="flex flex-row">
      <span class="text-lg font-semibold py-2">Personal details</span>
    </div>
    <button
      type="button"
      @click="showModal = !showModal"
      class="text-sm font-bold text-primary"
    >
      Edit
    </button>
  </div>
  <div class="m-2 flex flex-row leading-4 justify-start items-center">
    <div v-if="userRes.avatar?.url" class="">
      <div
        class="m-1 w-12 h-12 relative flex justify-center items-center rounded-full text-xl text-white uppercase bg-cover"
        :style="`background-image: url(${userRes.avatar?.url})`"
      ></div>
    </div>
    <div
      v-else
      class="m-1 mr-2 w-12 h-12 relative flex justify-center items-center rounded-full bg-primary text-xl text-white uppercase"
    >
      {{
        getInitials(
          `${userRes && userRes.first_name} ${userRes && userRes.last_name}`
        )
      }}
    </div>
    <div class="pl-3 flex flex-col space-y-2">
      <span class="lg:text-xl font-bold md:text-sm text-sm">{{
        `${userRes && userRes.first_name} ${userRes && userRes.last_name}`
      }}</span>
      <span class="lg:text-base md:text-xs font-light text-gray-400 text-xs">{{
        userRes && userRes.email
      }}</span>
    </div>
  </div>
  <ReusableModal :show="showModal" @close="showModal = false">
    <form class="" @submit.stop="onSubmit">
      <span class="font-medium text-lg">Edit personal details</span>
      <div class="flex flex-col justify-center items-center">
        <div class="w-full mt-2 flex flex-col justify-center items-center">
          <div class="p-2 flex flex-col justify-center items-center">
            <UploadFileButton
              :url="`${
                userRes?.avatar?.url
                  ? userRes?.avatar?.url
                  : require('@/assets/pngs/user.png')
              }`"
              v-model="profilePicture"
              name="background"
              height="h-44"
              width="w-44"
              className="absolute rounded-full flex flex-col justify-center items-center bg-purple-200 text-gray-700 relative justify-center items-center w-12 h-12 bottom-1 right-0 opacity-90 cursor-pointer  shadow-lg"
              placeholder=""
              type="profileImage"
            ></UploadFileButton>
          </div>
        </div>
        <div class="w-11/12 p-2 space-y-2">
          <div class="relative">
            <TextField
              v-model="firstName"
              label="First Name"
              typeInput="text"
              name="first_name"
            />
          </div>
          <div class="relative">
            <TextField
              v-model="lastName"
              label="Last Name"
              typeInput="text"
              name="last_name"
            />
          </div>
          <div class="flex justify-end mt-1">
            <Button
              variant="contained"
              type="submit"
              label="Update information"
              :disabled="!meta.valid || loading"
            />
          </div>
        </div>
      </div>
    </form>
  </ReusableModal>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import { getInitials } from "../../utils/functions";
import ReusableModal from "@/components/reusableModal/index.vue";
import Button from "@/components/button/index.vue";
import TextField from "@/components/textField/index.vue";
import { userAPI } from "@/services/user/index";
import { createToastAxelerate } from "../../utils/toast";
import UploadFileButton from "../../components/uploadFileButton/index.vue";
import { useState } from "@/composables/useState";

const PersonalDetails = defineComponent({
  components: {
    ReusableModal,
    TextField,
    Button,
    UploadFileButton,
  },
  name: "PersonalDetails",
  setup() {
    const store = useStore();
    const userRes = computed(() => store.state.profile.data);
    const showModal = ref(false);
    const [loading, setLoading] = useState(false);
    const profilePicture = ref();
    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
    });
    const { value: firstName } = useField("first_name");
    const { value: lastName } = useField("last_name");

    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        first_name: userRes.value?.first_name,
        last_name: userRes.value?.last_name,
      },
    });

    const onSubmit = handleSubmit((values) => {
      setLoading(true);
      const data = {
        ...values,
        avatar: profilePicture.value,
      };
      userAPI.updateUser(data, userRes.value?.id).then(() => {
        setLoading(false);
        nextUpdate();
        createToastAxelerate(
          "Personal Info",
          "The info has been updated.",
          "success"
        );
      });
    });

    const nextUpdate = () => {
      showModal.value = false;
      store.dispatch("getProfile");
    };

    return {
      firstName,
      lastName,
      meta,
      handleSubmit,
      onSubmit,
      showModal,
      userRes,
      getInitials,
      profilePicture,
      loading,
      setLoading,
    };
  },
});

export default PersonalDetails;
</script>
