import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.modelValue,
    clearable: true,
    closeOnAutoApply: true,
    format: _ctx.format,
    placeholder: _ctx.placeholder,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    autoApply: "",
    minDate: _ctx.minDate
  }, null, 8, ["modelValue", "format", "placeholder", "minDate"]))
}