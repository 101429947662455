
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from "vue";
import Avatar from "../avatar/index.vue";
import Highlighter from "vue-highlight-words";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { computed, defineComponent } from "vue";
import { formatedDateMoment, getInitials } from "../../utils/functions";
import { createToastAxelerate } from "@/utils/toast";
import EmailComposer from "@/components/emailComposer/index.vue";

const TimeLine = defineComponent({
  name: "Timeline",
  components: {
    EmailComposer,
    Avatar,
    Highlighter,
  },
  props: {
    timeline: {
      type: Array,
      default: () => [],
    },
    reloadTimeline: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emit: ["update:reloadTimeline"],
  setup(props, { emit }) {
    const refsConversations: any = ref([]);
    const refsConversationsReplyComposer: any = ref([]);
    const refsReplies: any = ref([]);
    const candidateName = computed(
      () => props.candidate.first_name + " " + props.candidate.last_name
    );

    props.timeline
      .filter((x: any) => x.event_type === "job_candidate_conversation")
      .forEach((x: any) => {
        // Adds all conversation(id) items to this ref
        refsConversations.value.push({ [x.id]: false });
        refsConversationsReplyComposer.value.push({ [x.id]: false });
        x.sourceable.replies.messages.forEach((y: any) => {
          // Adds all replies(id) items to this ref
          refsReplies.value.push({ [y.id]: false });
        });
      });

    function moreEmailReplies(
      conversationIdx: number,
      conversationId: string,
      val: boolean
    ) {
      refsConversations.value[conversationIdx][conversationId] = val;
    }

    function showFullMessage(msgIdx: number, messageId: string, val: boolean) {
      refsReplies.value[msgIdx][messageId] = val;
    }

    const buildImageStage = (option: any) => {
      switch (option) {
        case "Talent Pool":
          return require("@/assets/timeline/arrow.svg");
        case "Screening":
          return require("@/assets/timeline/call.svg");
        case "Technical Interview":
          return require("@/assets/timeline/interview.svg");
        case "Challenge":
          return require("@/assets/timeline/callenge.svg");
        case "2nd Interview":
          return require("@/assets/timeline/interview.svg");
        case "Offer":
          return require("@/assets/timeline/document.svg");
        case "Hired":
          return require("@/assets/timeline/hired.svg");
        default:
          return require("@/assets/timeline/arrow.svg");
      }
    };

    const ValidateJobComment = (event_type: any) => {
      let event_allowed = [
        "job_function_comment",
        "job_info_comment",
        "job_details_comment",
        "job_salary_comment",
      ];
      if (event_allowed.includes(event_type)) {
        return true;
      } else {
        return false;
      }
    };

    const parseContent = (content: any) => {
      const newContent = content ? JSON.parse(content) : {};
      const converter = new QuillDeltaToHtmlConverter(newContent?.ops, {});
      return converter.convert();
    };

    function getTime(date: any) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}${ampm}`;
    }

    function getDate(date: any) {
      const original = new Date(date);
      const day = original.getDate();
      const weekday = original.getDay();
      let weekdayText = "";
      const month = original.getMonth();
      let monthText = "";
      const year = original.getFullYear();

      switch (weekday) {
        case 0:
          weekdayText = "Sunday";
          break;
        case 1:
          weekdayText = "Monday";
          break;
        case 2:
          weekdayText = "Tuesday";
          break;
        case 3:
          weekdayText = "Wednesday";
          break;
        case 4:
          weekdayText = "Thursday";
          break;
        case 5:
          weekdayText = "Friday";
          break;
        case 6:
          weekdayText = "Saturday";
          break;
      }

      switch (month) {
        case 0:
          monthText = "Jan";
          break;
        case 1:
          monthText = "Feb";
          break;
        case 2:
          monthText = "Mar";
          break;
        case 3:
          monthText = "Apr";
          break;
        case 4:
          monthText = "May";
          break;
        case 5:
          monthText = "Jun";
          break;
        case 6:
          monthText = "Jul";
          break;
        case 7:
          monthText = "Aug";
          break;
        case 8:
          monthText = "Sep";
          break;
        case 9:
          monthText = "Oct";
          break;
        case 10:
          monthText = "Nov";
          break;
        case 11:
          monthText = "Dec";
          break;
      }

      return `${weekdayText} ${monthText} ${day}, ${year}`;
    }

    function copyUrlToClipboard(url: any) {
      navigator.clipboard.writeText(url);
      createToastAxelerate("Link copied to clipboard", "", "success");
    }

    function reload() {
      emit("update:reloadTimeline", true);
    }

    function createReplyComposer(
      conversationIdx: number,
      conversationId: string,
      val: boolean
    ) {
      refsConversationsReplyComposer.value[conversationIdx][conversationId] =
        val;
    }

    return {
      formatedDateMoment,
      buildImageStage,
      getInitials,
      parseContent,
      ValidateJobComment,
      getDate,
      getTime,
      copyUrlToClipboard,
      reload,
      moreEmailReplies,
      showFullMessage,
      candidateName,
      refsConversations,
      refsConversationsReplyComposer,
      refsReplies,
      createReplyComposer,
    };
  },
});
export default TimeLine;
