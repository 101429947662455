/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from 'axios'
import { instanceLoggedAPI, instanceFormAPI } from '../axiosConfig'

export default class RecruiterAPIService {
  private axiosInstance: AxiosInstance
  private instanceForm: AxiosInstance

  constructor() {
    this.axiosInstance = instanceLoggedAPI
    this.instanceForm = instanceFormAPI
  }

  async getInternals(page: number, companyId: string): Promise<unknown> {
    return this.axiosInstance.get(
      `/internal_clients?page=${page}&company_id=${companyId}`
    )
  }

  async listInternals(active?: boolean): Promise<unknown> {
    if (active) {
      return this.axiosInstance.get(`/list_internal_recruiters?active=true`)
    } else {
      return this.axiosInstance.get(`/list_internal_recruiters`)
    }
  }
}
export const RecruiterAPI = new RecruiterAPIService()
