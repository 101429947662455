
import { defineComponent, onMounted, ref, Ref } from "vue";
import { JobsAPI } from "@/services/jobs";
import ReusableModal from "@/components/reusableModal/index.vue";
import CustomCompany from "@/components/customCompany/index.vue";

export default defineComponent({
  name: "Company Job Board Section",
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  components: {
    ReusableModal,
    CustomCompany,
  },
  setup(props) {
    const openModal: Ref<boolean> = ref(false);
    const companyDomain: Ref<string> = ref("");
    const isLoading: Ref<boolean> = ref(true);
    const company: Ref<any> = ref({});

    const getCompanyBranding = () => {
      isLoading.value = true;
      JobsAPI.getCompanyById(props.companyId)
        .then((response: any) => {
          let domain = response?.data.company?.company_branding?.domain_name;
          company.value = response.data[0];
          companyDomain.value = `${process.env.VUE_APP_JOB_BOARD_URL}/${domain}`;
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const updateBranding = () => {
      getCompanyBranding();
    };

    onMounted(() => {
      getCompanyBranding();
    });

    return {
      openModal,
      company,
      isLoading,
      companyDomain,
      updateBranding,
    };
  },
});
