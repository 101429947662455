
import { defineComponent } from "vue";
import Header from "../../components/header/index.vue";
import JobDetailsComponent from "../../components/jobDetails/index.vue";

const JobDetails = defineComponent({
  name: "JobDetails",
  components: {
    Header,
    JobDetailsComponent,
  },
});

export default JobDetails;
