/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceFormAPI, instanceLoggedAPI } from "../axiosConfig";

export default class ClientAPIService {
  private axiosInstance: AxiosInstance;
  private instanceForm: AxiosInstance;
  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.instanceForm = instanceFormAPI;
  }

  async createClient(data: any): Promise<any> {
    return this.axiosInstance.post(`/users`, data);
  }

  async getClients(): Promise<any> {
    return this.axiosInstance.get(`/users`);
  }

  async updateClient(body: any, id: string): Promise<any> {
    return this.axiosInstance.put(`/users/${id}`, body);
  }

  async deactivateClient(id: string): Promise<any> {
    return this.instanceForm.put(`/companies/${id}/deactivate`);
  }

  async resendInvitation(id: string): Promise<any> {
    return this.axiosInstance.post(`/users/${id}/resend_invite`);
  }
  async getClientDetail(id: string): Promise<any> {
    return this.axiosInstance.get(`/companies/${id}`);
  }

  async getCountries(): Promise<any> {
    return this.axiosInstance.get(`/countries`);
  }
  async getClientMembers(
    company_id: any,
    page: any,
    filters: any
  ): Promise<unknown> {
    return this.axiosInstance.get(
      `/account_members?company_id=${company_id}&page=${page}&${makeFilter(
        filters
      )}`
    );
  }
}
export const ClientAPI = new ClientAPIService();
