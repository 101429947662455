
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, PropType, Ref, ref } from "vue";
import { editBenefits, addBenefit } from "@/services/talents/index";
import { createToastAxelerate } from "@/utils/toast";
import TextField from "@/components/textField/index.vue";
//import CurrencyField from "@/components/currencyField/index.vue";
import CurrencyFieldSimple from "@/components/currencyFieldSimple/index.vue";

export default defineComponent({
  name: "Payment breakdown",
  components: {
    TextField,
    //CurrencyField,
    CurrencyFieldSimple,
  },
  props: {
    talent: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    refreshPage: {
      type: Boolean,
      defatult: false,
    },
  },
  emits: ["update:refreshPage"],
  setup(props, { emit }) {
    const info = ref({
      gross_salary:
        props.talent?.meta.complete_payroll?.payment_breakdown?.gross_salary ||
        0,
      net_salary: props.talent?.talent.salary.monthly || 0,
      employee_taxes:
        props.talent?.meta.complete_payroll?.payment_breakdown?.taxes || 0,
      employer_taxes:
        props.talent?.meta.complete_payroll?.payment_breakdown?.employer_tax ||
        0,
      benefits: props.talent?.talent?.position?.benefits || ([] as Array<any>),
      benefits_total:
        props.talent?.meta.complete_payroll?.payment_breakdown
          ?.benefits_total || 0,
      total_comp:
        props.talent?.meta.complete_payroll?.payment_breakdown?.total_comp || 0,
      axelerate_percent:
        props.talent?.meta.complete_payroll?.payment_breakdown
          ?.axelerate_percent || 0,
      christmas_bonus:
        props.talent?.meta.complete_payroll?.payment_breakdown
          ?.christmas_bonus || 0,
      salary: props.talent?.talent?.salary || {},
      job_id: props.talent?.talent?.position?.id || "",
    });
    const editing = ref(false);
    const allBenefits: Ref<Array<any>> = ref([]);
    const newBenefitName = ref("");
    const newBenefitValue = ref(0);
    const updateField = ref(0);

    function saveBeneftis() {
      convertBenefitAmounts();
      for (let i = 0; i < allBenefits.value.length; i++) {
        const benefit = allBenefits.value[i];
        if (benefit.id) {
          editBenefit(benefit);
        } else {
          createBenefit(benefit);
        }
      }
    }

    async function createBenefit(benefit: any) {
      const data = await addBenefit(info.value.job_id, benefit);
      if (!data) {
        createToastAxelerate("Error", "Could not create benefit", "danger");
      }
    }

    async function editBenefit(benefit: any) {
      const data = await editBenefits(info.value.job_id, benefit);
      if (!data) {
        createToastAxelerate("Error", "Could not edit benefit", "danger");
      }
    }

    function convertBenefitAmounts() {
      for (let i = 0; i < allBenefits.value.length; i++) {
        allBenefits.value[i].value *= 100;
      }
    }

    function pushBenefit() {
      allBenefits.value.push({
        name: newBenefitName.value,
        value: newBenefitValue.value,
      });

      clearNewBenefit();
    }

    function clearNewBenefit() {
      newBenefitName.value = "";
      newBenefitValue.value = 0;
      updateField.value++;
    }

    function startEditing() {
      for (let i = 0; i < info.value.benefits.length; i++) {
        info.value.benefits[i].value /= 100;
      }
      editing.value = true;
    }
    async function finishEditing() {
      saveBeneftis();
      emit("update:refreshPage", true);
      editing.value = false;
    }

    const exchange_rate = props.talent?.meta.mxn_to_usd_exchange_rate || 0;

    onMounted(() => {
      for (let i = 0; i < info.value.benefits.length; i++) {
        allBenefits.value.push(info.value.benefits[i]);
      }
    });

    return {
      editing,
      startEditing,
      finishEditing,
      info,
      exchange_rate,
      newBenefitName,
      newBenefitValue,
      allBenefits,
      pushBenefit,
      updateField,
    };
  },
});
