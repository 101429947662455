
import { defineComponent } from "vue-demi";
import { useRouter } from "vue-router";

const ErrorPage = defineComponent({
  name: "ErrorPage",
  setup() {
    const router = useRouter();

    const reload = () => router.go(0);
    return { reload };
  },
});

export default ErrorPage;
