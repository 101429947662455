/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { instanceLoggedAPI } from "../axiosConfig";

export default class IntenalClientAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getInternalClients(
    id: string,
    page?: number,
    filters?: any
  ): Promise<any> {
    if (page) {
      return this.axiosInstance.get(
        `/internal_clients?company_id=${id}&page=${page}&${makeFilter(filters)}`
      );
    } else {
      return this.axiosInstance.get(`/internal_clients?company_id=${id}`);
    }
  }

  async getInternals(active?: boolean): Promise<any> {
    if (active) {
      return this.axiosInstance.get(`/list_internal_recruiters?active=true`);
    } else {
      return this.axiosInstance.get(`/list_internal_recruiters`);
    }
  }

  async getActiveClientMembers(company_id: string): Promise<any> {
    return this.axiosInstance.get(`list_client_members/${company_id}`);
  }

  async addInternal(data: any): Promise<any> {
    return this.axiosInstance.post(`/internal_clients`, {
      internal_client: data,
    });
  }

  async deactivateClient(id: string): Promise<any> {
    return this.axiosInstance.delete(`/internal_clients/${id}/deactivate`);
  }
}
export const InternalClientstAPI = new IntenalClientAPIService();
