
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import useClipboard from "../../composables/useClipboard";
import { createToastAxelerate } from "../../utils/toast";

const MenuShare = defineComponent({
  name: "MenuShare",
  emits: ["openShare", "deleteCandidate", "goToPublic"],
  props: {
    candidateId: {
      type: String,
      required: true,
    },
    jobId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const { toClipboard } = useClipboard();
    const openCandidateDetail = `${process.env.VUE_APP_BASE_URL}/company-candidates/${props.candidateId}`;
    const openShare = () => {
      context.emit("openShare");
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const copy = async () => {
      try {
        await toClipboard(
          `${process.env.VUE_APP_SOURCING_PLATFORM}/jobs/${props.jobId}/job-candidates/${props.candidateId}/Timeline`
        );
        createToastAxelerate(
          "Share candidate",
          "Copied to clipboard",
          "info",
          () => undefined,
          2000,
          "bottom-center",
          "#00000f"
        );
      } catch (e) {
        createToastAxelerate(
          "Share candidate",
          "An error has ocurred",
          "danger"
        );
      }
    };
    return {
      isMenuOpen,
      closeMenu,
      openShare,
      router,
      copy,
      openCandidateDetail,
    };
  },
});
export default MenuShare;
