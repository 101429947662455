
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
import Header from "@/components/header/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import Filter from "@/components/filter/index.vue";
import ReimbursementCard from "@/components/reimbursementCard/index.vue";
import CardExtraCharge from "@/components/cardExtraCharge/index.vue";
import ModalExpenses from "@/components/modalExpenses/index.vue";
import { useStore } from "vuex";
import {
  getReimbursements,
  getExtraCharges,
  filterReimbursements,
} from "@/services/expenses";
import VPagination from "@hennge/vue3-pagination";

export default defineComponent({
  name: "Expenses",
  components: {
    Header,
    Loader,
    ErrorState,
    ReimbursementCard,
    CardExtraCharge,
    ModalExpenses,
    Filter,
    VPagination,
  },
  setup() {
    //Variables
    const store = useStore();
    const loading = ref(false);
    const error = ref(false);
    const openModal = ref(false);
    const refreshData = ref(false);
    const searchQuery = ref("");
    const filter = ref("");
    const filterOptions = ref([
      "travel",
      "food_and_beverages",
      "office_supplies",
      "other",
    ]);
    const status = ref("");
    const statusOptions = ref(["pending", "approved", "denied"]);
    const typeOfExpense = ref("all");
    const expensesOptions = ref(["extra_charges", "reimbursements"]);
    const reimbursements: Ref<Array<any>> = ref([]);
    const pending: Ref<Array<any>> = ref([]);
    const approved: Ref<Array<any>> = ref([]);
    const denied: Ref<Array<any>> = ref([]);
    const paid: Ref<Array<any>> = ref([]);
    const pagination: Ref<any> = ref({});
    const currentPage = ref(1);
    const extraCharges: Ref<Array<any>> = ref([]);

    //Functions
    async function getUserReimbursements(
      filter: string,
      status: string,
      page: number
    ) {
      loading.value = true;
      let data = null;
      if (filter !== "" || status !== "") {
        data = await filterReimbursements(filter, status, page);
      } else {
        data = await getReimbursements(page);
      }
      if (data) {
        reimbursements.value = data.reimbursements;
        pagination.value = data.meta.pagination;
        sortReimbursements(reimbursements.value);
      } else {
        error.value = true;
      }
      refreshData.value = true;
      loading.value = false;
    }
    function sortReimbursements(allReimbursements: Array<any>) {
      pending.value = [];
      approved.value = [];
      denied.value = [];
      for (let i = 0; i < allReimbursements.length; i++) {
        if (allReimbursements[i].status === "pending") {
          pending.value.push(allReimbursements[i]);
        } else if (allReimbursements[i].status === "approved") {
          approved.value.push(allReimbursements[i]);
        } else if (allReimbursements[i].status === "denied") {
          denied.value.push(allReimbursements[i]);
        } else if (allReimbursements[i].status === "paid") {
          paid.value.push(allReimbursements[i]);
        }
      }
    }
    async function getUserExtraCharges() {
      loading.value = true;
      const data = await getExtraCharges();
      if (data) {
        extraCharges.value = data.extra_charges;
      } else {
        error.value = true;
      }
      loading.value = false;
    }
    function searchTitle(event: any) {
      const value = event.target.value.toLowerCase();
      const data = reimbursements.value.filter(function (reimbursement) {
        return (
          reimbursement.title
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(value) ||
          (reimbursement.original_amount / 100)
            .toString()
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(value) ||
          reimbursement.detail
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(value)
        );
      });
      sortReimbursements(data);
    }
    //Computed
    const firstName = computed(() => store.getters.getUserFirstName);
    const lastName = computed(() => store.getters.getUserLastName);
    const refresh = computed(() => store.getters.getRefreshReimbursements);

    //Hooks
    watch(openModal, (newVal) => {
      if (!newVal) {
        filter.value = "";
        status.value = "";
        refreshData.value = false;
        getUserReimbursements("", "", currentPage.value);
        getUserExtraCharges();
      }
    });
    watch(filter, (newVal) => {
      getUserReimbursements(newVal, status.value, currentPage.value);
    });
    watch(status, (newVal) => {
      getUserReimbursements(filter.value, newVal, currentPage.value);
    });
    watch(currentPage, (newVal) => {
      getUserReimbursements(filter.value, status.value, newVal);
    });
    watch(refreshData, () => {
      getUserReimbursements(filter.value, status.value, currentPage.value);
      getUserExtraCharges();
    });
    watch(refresh, (newVal) => {
      if (newVal) {
        getUserReimbursements(filter.value, status.value, currentPage.value);
        getUserExtraCharges();
        store.dispatch("setRefresh", false);
      }
    });

    onMounted(() => {
      getUserReimbursements("", "", currentPage.value);
      getUserExtraCharges();
      //refreshData.value = true;
    });

    return {
      error,
      loading,
      openModal,
      filter,
      filterOptions,
      status,
      statusOptions,
      typeOfExpense,
      expensesOptions,
      reimbursements,
      pending,
      approved,
      denied,
      paid,
      firstName,
      lastName,
      refreshData,
      searchQuery,
      searchTitle,
      pagination,
      currentPage,
      extraCharges,
      getUserExtraCharges,
    };
  },
});
