import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.route,
    class: _normalizeClass(["space-x-3", _ctx.buildStyleByVariant(_ctx.variant, _ctx.sizeLabel)])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "startIcon"),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.labelSize)
      }, _toDisplayString(_ctx.label), 3),
      _renderSlot(_ctx.$slots, "endIcon")
    ]),
    _: 3
  }, 8, ["to", "class"]))
}