
/* eslint-disable @typescript-eslint/no-explicit-any */
import Header from '@/components/header/index.vue';
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
  watchEffect,
} from 'vue';
import SearchInput from '../../components/searchInput/index.vue';
import Button from '../../components/button/index.vue';
import JobCard from '../../components/jobCard/index.vue';
import RadioButton from '../../components/radioButton/index.vue';
import CheckBoxGroup from '../../components/checkboxGroup/index.vue';
import '../../styles/pagination.css';
import { useStore } from 'vuex';
import { useState } from '@/composables/useState';
import { removeBlankProperties } from '../../utils/params';
import Loading from 'vue-loading-overlay';
import { useRouter } from 'vue-router';
import { JobsAPI } from '../../services/jobs/index';
import { RecruiterAPI } from '../../services/recruiters/index';
import { CompanyAPI } from '../../services/companies/index';
import { buildCompanies } from '../../utils/companies';
import { buildInternals } from '../../utils/internals';
import JobsEmptyState from '../../components/jobsEmptyState/index.vue';
import ReusableModal from '../../components/reusableModal/index.vue';
import AddTemplateToJob from '../../components/addTemplateToJob/index.vue';
import AddChallengeToJob from '../../components/addChallengeToJob/index.vue';
import CloseJob from '../../components/closeJob/index.vue';
import AddRecruiterToJob from '../../components/addRecruiterToJob/index.vue';
import Link from '../../components/link/index.vue';
import { createToastAxelerate } from '../../utils/toast';
import Paginator from '@/components/paginator/index.vue';
import { jobStatus, managedJobs } from '../../constants/jobs';
import Divider from '@/components/divider/index.vue';

const Jobs = defineComponent({
  name: 'Jobs',
  components: {
    Header,
    SearchInput,
    Paginator,
    Button,
    CheckBoxGroup,
    AddRecruiterToJob,
    JobCard,
    Loading,
    RadioButton,
    JobsEmptyState,
    ReusableModal,
    AddTemplateToJob,
    AddChallengeToJob,
    Link,
    CloseJob,
    Divider,
  },
  setup() {
    const inputValue = ref('');
    const store = useStore();
    const router = useRouter();
    const [page, setPage] = useState(1);
    const [locations, setLocations] = useState([]);
    const [asc, setAsc] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [internalList, setInternalList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showChallenge, setShowChallenge] = useState(false);
    const [showCloseJob, setShowCloseJob] = useState(false);
    const [showRecruiter, setShowRecruiter] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const radioLocation = ref('');
    const radioStatus = ref('');
    const managedJob = ref('');
    const isLoading = ref(false);
    const checkValues = ref([]);
    const dataJob = ref({});
    const jobsStore = computed(() => store.state.jobs.data);
    const userId = computed(() => store.state.profile.data.id);
    const roles = computed(() => store.state.profile.data.roles);
    const isInternalRecruiter = roles.value.includes('internal_recruiter')
      ? true
      : false;
    const userProfiles = computed(() => store.state.profile.data.roles);
    const isRecruiterAdmin = ref(false);
    const client = ref('');
    const internal = ref('');
    const filters = ref({});
    const onlyManagedJob = ref(false);
    const expand = reactive({
      location: false,
      status: false,
      managed: false,
    });

    const getAllResults = async () => {
      isLoading.value = true;
      store
        .dispatch('getAllJobs', {
          page: page.value,
          filters: removeBlankProperties({
            search: Object.values(filters.value).join('%26'),
            sort: asc.value ? 'last_interaction_asc' : 'last_interaction_desc',
            job_status: setArchivedJobs(checkValues.value),
            is_managed: managedJob.value === 'all' ? '' : managedJob.value,
          }),
        })
        .then((res: any) => {
          isLoading.value = res[0];
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    onBeforeMount(() => {
      userProfiles.value.map((index: any) => {
        if (index === 'recruiter_admin' || index === 'superadmin')
          isRecruiterAdmin.value = true;
      });
      getInternals();
      JobsAPI.getJobLocations(userId.value).then((res: any) => {
        res.data.unshift('Remote');
        setLocations(res.data);
      });
      CompanyAPI.getCompanies(userId.value).then((res: any) => {
        setClientList(buildCompanies(res.data));
      });
      getAllResults();
    });

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
        location:
          radioLocation.value === 'Remote'
            ? 'remote_location'
            : radioLocation.value,
        clients: client.value,
        internal: internal.value,
      });
    });

    const updateHandler = (e: any) => {
      setPage(e);
      getAllResults();
    };

    const searchFilter = (data?: any) => {
      isLoading.value = true;
      setPage(page.value);
      store
        .dispatch('getAllJobs', {
          page: page.value,
          filters: removeBlankProperties({
            search: Object.values(filters.value).join('%26'),
            sort: asc.value ? 'last_interaction_asc' : 'last_interaction_desc',
            job_status: setArchivedJobs(checkValues.value),
            is_managed: managedJob.value === 'all' ? '' : managedJob.value,
          }),
        })
        .then(() => (isLoading.value = false))
        .catch(() => (isLoading.value = false));
    };

    const clearFilters = () => {
      inputValue.value = '';
      radioLocation.value = '';
      radioStatus.value = '';
      client.value = '';
      checkValues.value = [];
      internal.value = '';
      managedJob.value = '';
      setAsc(false);
      setPage(1);
      setTimeout(() => {
        searchFilter();
      }, 200);
    };

    const openTemplate = (id: string) => {
      setShowModal(true);
      setSelectedId(id);
    };

    const openChallenge = (id: string) => {
      setShowChallenge(true);
      setSelectedId(id);
    };

    const getInternals = () => {
      RecruiterAPI.listInternals(true)
        .then((response: any) => {
          setInternalList(buildInternals(response.data.users));
        })
        .catch(() => {
          createToastAxelerate(
            'List internal',
            'An error has occured',
            'danger'
          );
        });
    };

    const setArchivedJobs = (data: Array<string>) => {
      let auxArray = [];
      if (data.includes('archived'))
        auxArray.push(
          'inactive',
          'completed',
          'on_hold_by_client',
          'changed_in_scope'
        );
      if (data.includes('draft')) {
        auxArray.push('draft');
      }
      if (data.includes('published')) auxArray.push('published');
      if (data.includes('pending_approval')) auxArray.push('pending_approval');
      return auxArray;
    };

    const assignJob = (item: any) => {
      setShowRecruiter(true);
      dataJob.value = item;
    };

    watch(managedJob, (newVal) => {
      setPage(1);
      searchFilter();
    });

    const deactivateJob = (job: any) => {
      if (
        job.job_status === 'completed' ||
        job.job_status === 'changed_in_scope' ||
        job.job_status === 'on_hold_by_client'
      ) {
        const job_data = {
          job_status: 'published',
        };
        JobsAPI.deactivateJob(job.id, job_data)
          .then(() => {
            createToastAxelerate(
              'Deactivate Job',
              'The job has been activated successfully',
              'success'
            );
            getAllResults();
          })
          .catch(() => {
            createToastAxelerate(
              'Deactivate Job',
              'An error has ocurred',
              'danger'
            );
          });
      } else {
        setShowCloseJob(true);
        setSelectedId(job.id);
      }
    };

    const downloadReport = () => {
      isLoading.value = true;
      JobsAPI.downloadReport()
        .then((res: any) => {
          forceFileDownload(res.data.file, '');
          isLoading.value = false;
        })
        .catch(() => {
          isLoading.value = false;
          createToastAxelerate(
            'Deactivate Job',
            'An error has ocurred',
            'danger'
          );
        });
    };
    const forceFileDownload = (response: any, title: string) => {
      // const url = window.URL.createObjectURL(new Blob([response.data]));
      const url = String(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    };

    watch(client, (newVal) => {
      setPage(1);
      searchFilter();
    });

    watch(internal, (newVal) => {
      setPage(1);
      searchFilter();
    });

    watch(asc, (newVal) => {
      setPage(1);
      searchFilter();
    });

    return {
      inputValue,
      jobsStore,
      searchFilter,
      updateHandler,
      isLoading,
      page,
      router,
      locations,
      radioLocation,
      expand,
      clearFilters,
      setAsc,
      asc,
      clientList,
      internalList,
      client,
      getInternals,
      openTemplate,
      setShowModal,
      showModal,
      selectedId,
      getAllResults,
      openChallenge,
      setShowChallenge,
      showChallenge,
      setShowRecruiter,
      showRecruiter,
      deactivateJob,
      assignJob,
      setSelectedCompanyId,
      selectedCompanyId,
      dataJob,
      internal,
      userProfiles,
      isRecruiterAdmin,
      jobStatus,
      radioStatus,
      checkValues,
      setShowCloseJob,
      showCloseJob,
      managedJobs,
      managedJob,
      downloadReport,
      isInternalRecruiter,
    };
  },
});
export default Jobs;
