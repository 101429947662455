
import { defineComponent, PropType, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "Filter",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "40",
    },
    options: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const dropdown = ref(null);
    const showDropdown = ref(false);
    onClickOutside(dropdown, () => {
      closeDropdown();
    });
    const selection = ref("");
    const selected = ref(false);

    function updateValue(value: string) {
      selected.value = true;
      emit("update:modelValue", value);
    }
    function closeDropdown() {
      showDropdown.value = false;
    }

    watch(selection, (newVal) => {
      closeDropdown();
      updateValue(newVal);
    });

    return {
      selection,
      selected,
      dropdown,
      showDropdown,
    };
  },
});
