
import { defineComponent, onMounted, ref, watch } from "vue";
import Button from "../button/index.vue";

const WarningModal = defineComponent({
  name: "DeleteCandidate",
  components: {
    Button,
  },
  emits: ["closeModal", "emitAction", "update:modelValue"],
  props: {
    message: {
      type: String,
      default: "",
    },
    aditionalComponent: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const error = ref(true);
    const isLoading = ref(false);
    function closeModal() {
      context.emit("closeModal");
      context.emit("update:modelValue", "");
    }
    const emitAction = () => {
      if (props.modelValue || !props.disabled) {
        context.emit("emitAction");
        isLoading.value = true;
      } else {
        error.value = true;
      }
    };

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue) {
          error.value = false;
        } else {
          error.value = true;
        }
      }
    );

    onMounted(() => {
      if (props.modelValue || !props.disabled) {
        error.value = false;
      }
    });

    return {
      closeModal,
      error,
      emitAction,
      isLoading,
    };
  },
});

export default WarningModal;
