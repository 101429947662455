
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, computed, ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ReusableModal from "../../components/reusableModal/index.vue";
import { getInitials, timeAgo } from "../../utils/functions";
import Header from "../../components/header/index.vue";
import AddCandidatesForm from "../../components/addCandidateForm/index.vue";
import CandidateInfo from "../../components/candidateInfo/index.vue";
import EditCandidateForm from "../../components/editCandidate/index.vue";
import DeleteCandidate from "../../components/deleteCandidateAlert/index.vue";
import Button from "../../components/button/index.vue";
import SearchInput from "../../components/searchInput/index.vue";
import { useState } from "../../composables/useState";
import AddCandidatesInJob from "../../components/addCandidatesToJob/index.vue";
import EmptyState from "../../components/emptyState/index.vue";

const JobCandidates = defineComponent({
  name: "JobCandidates",
  emits: ["close", "closeModal", "getCandidates"],
  components: {
    Header,
    ReusableModal,
    AddCandidatesForm,
    CandidateInfo,
    EditCandidateForm,
    DeleteCandidate,
    Loading,
    Button,
    SearchInput,
    AddCandidatesInJob,
    EmptyState,
  },
  setup() {
    const formRef = ref<InstanceType<typeof AddCandidatesForm>>();
    const editRef = ref<InstanceType<typeof EditCandidateForm>>();
    const store = useStore();
    const token = computed(() => store.state.auth);
    const jobStore = computed(() => store.state.addCanidates.data);
    const userInfo = computed(() => store.state.candidateInfo.data);
    const candidates = computed(() => store.state.jobCandidates.data);
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(true);
    const jobId = route.query.id;
    const showModal = ref(false);
    const showDelete = ref(false);
    const toggleInfo = ref(false);
    const selectedUser: any = ref(0);
    const isMenuOpen = ref(false);
    const showOption = ref("");
    const [showSearch, setShowSearch] = useState(false);
    const activeTab: any = ref(route.query.by_stage_name);
    const resetSelected = () => (selectedUser.value = 0);
    const inputValue = ref("");
    const getData = async () => {
      const data = {
        jobId,
        filters: { by_stage_name: activeTab.value, search: inputValue.value },
      };
      const getJob = store.dispatch("getJobState", jobId);
      const getCandidates = store.dispatch("getJobCandidatesState", data);
      return await Promise.all([getJob, getCandidates])
        .then((res: any) => {
          isLoading.value = res[0];
        })
        .catch((err) => {
          isLoading.value = err[0];
        });
    };
    onBeforeMount(async () => {
      store.commit("RESET_JOB_CANDIDATES");
      await getData();
    });
    const getFromForm = async () => {
      isLoading.value = true;
      showOption.value = "";
      resetSelected();
      const data = { jobId, filters: { by_stage_name: activeTab.value } };
      const getJob = store.dispatch("getJobState", jobId);
      const getCandidates = store.dispatch("getJobCandidatesState", data);
      return await Promise.all([getJob, getCandidates])
        .then((res: any) => {
          isLoading.value = res[0];
        })
        .catch((err) => {
          isLoading.value = err[0];
        });
    };
    const reloadAllCandidatesStage = () => {
      toggleInfo.value = true;
      isLoading.value = true;
      const data = { jobId, filters: { by_stage_name: activeTab.value } };
      showOption.value = "";
      store.dispatch("getJobCandidatesState", data).then((res) => {
        isLoading.value = res;
        store.dispatch("getJobState", route.query.id);
      });
    };
    const reloadCandidate = () => {
      toggleInfo.value = true;
      isLoading.value = true;
      const buildParams = {
        jobId,
        userId: userInfo.value?.id,
      };
      const data = { jobId, filters: { by_stage_name: activeTab.value } };
      showOption.value = "";
      store.dispatch("getJobCandidatesState", data);
      store
        .dispatch("getCandidateState", buildParams)
        .then((res) => {
          showOption.value = "info";
          isLoading.value = res;
        })
        .catch((err) => {
          isLoading.value = err;
        });
    };
    const addAction = (id: any) => {
      editRef?.value?.resetAll();
      selectedUser.value = id;
      toggleInfo.value = false;
      showOption.value = "";
      toggleInfo.value = true;
      isLoading.value = true;
      const buildParams = {
        jobId,
        userId: id,
      };
      store
        .dispatch("getCandidateState", buildParams)
        .then((res) => {
          showOption.value = "info";
          isLoading.value = res;
        })
        .catch((err) => {
          isLoading.value = err;
        });
    };
    const closeInForm = async () => {
      //resetFormOutside();
      showModal.value = false;
    };
    const closeEdit = () => {
      showOption.value = "info";
    };
    const openEdit = () => {
      showOption.value = "edit";
    };
    const openDelete = () => {
      showDelete.value = true;
    };
    const changeStage = (index: string) => {
      activeTab.value = index;
      router.replace({
        query: { ...route.query, by_stage_name: activeTab.value },
      });
      showOption.value = "";
      resetSelected();
      isLoading.value = true;
      const data = { jobId, filters: { by_stage_name: activeTab.value } };
      store
        .dispatch("getJobCandidatesState", data)
        .then((res) => (isLoading.value = res));
    };
    onMounted(() => {
      if (!token.value.token) {
        router.push("/log-in");
      }
    });
    return {
      userInfo,
      activeTab,
      candidates,
      jobStore,
      getInitials,
      showModal,
      showDelete,
      toggleInfo,
      formRef,
      addAction,
      route,
      closeEdit,
      reloadCandidate,
      reloadAllCandidatesStage,
      closeInForm,
      getFromForm,
      selectedUser,
      isMenuOpen,
      isLoading,
      openEdit,
      openDelete,
      showOption,
      editRef,
      changeStage,
      resetSelected,
      timeAgo,
      inputValue,
      getData,
      setShowSearch,
      showSearch,
    };
  },
});

export default JobCandidates;
