
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref, Ref, watch } from "vue";
import Header from "@/components/header/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import CompanyCard from "@/components/companyCard/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import { getPayrollPeriods, getPayrolls } from "@/services/payroll/index";
import useClickOutside from "@/composables/useClickOutside";
//import GlobalPayrollTable from "@/components/globalPayrollTable/index.vue";

export default defineComponent({
  name: "Payrolls",
  components: {
    Header,
    DateFormatter,
    CompanyCard,
    Loader,
    ErrorState,
    //GlobalPayrollTable,
  },
  setup() {
    const loading = ref(false);
    const error = ref(false);
    const showClientDropdown = ref(false);
    const clientDropdown = ref(null);
    const showPeriodDropdown = ref(false);
    const pendingInvoicesToShow = ref("");
    const periodDropdown = ref(null);
    const { onClickOutside } = useClickOutside();
    const clients: Ref<Array<any>> = ref([]);
    const meta: Ref<any> = ref({});
    const timeline: Ref<Array<any>> = ref([]);
    const dates: Ref<any> = ref({});
    const periods: Ref<Array<any>> = ref([]);
    const selectedPeriod: Ref<any> = ref({});

    onClickOutside(clientDropdown, () => {
      showClientDropdown.value = false;
    });

    onClickOutside(periodDropdown, () => {
      showPeriodDropdown.value = false;
    });

    async function getPayrollPeriodList() {
      loading.value = true;
      const list = await getPayrollPeriods();
      if (list) {
        for (let i = 0; i < list.length; i++) {
          periods.value.push({
            start_date: list[i].start_date,
            end_date: list[i].end_date,
          });
        }
        selectedPeriod.value = periods.value[0];
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    async function getAllPayrolls(start_date: string, end_date: string) {
      loading.value = true;
      const payroll = await getPayrolls(start_date, end_date);
      if (payroll) {
        clients.value = payroll.payroll_periods;
        meta.value = payroll.meta;
        timeline.value = payroll.time_line;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    function changePeriod(period: any) {
      selectedPeriod.value = period;
      showPeriodDropdown.value = false;
    }

    function showPendingInvoices(id: string) {
      pendingInvoicesToShow.value = id;
    }

    watch(selectedPeriod, (newVal) => {
      getAllPayrolls(newVal.start_date, newVal.end_date);
    });

    onMounted(() => {
      getPayrollPeriodList();
    });

    return {
      loading,
      error,
      clients,
      meta,
      timeline,
      dates,
      showClientDropdown,
      clientDropdown,
      periods,
      selectedPeriod,
      changePeriod,
      showPeriodDropdown,
      periodDropdown,
      showPendingInvoices,
      pendingInvoicesToShow,
    };
  },
});
