
import { computed, ref, defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import "@nylas/components-composer";

import IEmailMessage from "@/types/vendors/nylas/emailMessage.interface";

const EmailComposer = defineComponent({
  name: "Email Composer",
  props: {
    threadId: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      defualt: null,
    },
    cc: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const composer = ref();
    const route = useRoute();
    const store = useStore();
    const nylasAccessToken = computed(() => store.state.nylas.accessToken);
    const candidate = computed(
      () => store.state.candidateInfo?.data?.candidate
    );
    const jobTitle = computed(() => store.state.addCanidates?.data?.title);

    onMounted(() => {
      if (props.threadId) {
        composer.value.value = {
          thread_id: props?.threadId,
          subject: `Re: ${props.subject}`,
          to: [
            {
              name: `${candidate.value?.first_name} ${candidate.value?.last_name}`,
              email: candidate.value?.email,
            },
          ],
          cc: props.cc,
          body: `<body><br/><br/><div>----------------------------------------------------------<div><div style="margin-left: 15px">${props.body}</div><br/></body>`,
        };
      } else {
        composer.value.value = {
          subject: `Vacante - ${jobTitle.value}`,
          to: [
            {
              name: `${candidate.value?.first_name} ${candidate.value?.last_name}`,
              email: candidate.value?.email,
            },
          ],
        };
      }

      composer.value.to = () =>
        new Promise((resolve) => {
          resolve([
            {
              name: `${candidate.value?.first_name} ${candidate.value?.last_name}`,
              email: candidate.value?.email,
            },
          ]);
        });

      const afterSendSuccess = (response: IEmailMessage) => {
        const job_candidate_id = route.params.job_candidate_id,
          { thread_id, id, snippet, body, date } = response,
          payload = {
            email_message: {
              thread_id,
              id,
              snippet,
              body,
              date,
              job_candidate_id,
            },
          };
        store.dispatch("saveEmailTimelineEvent", payload);
      };

      if (!nylasAccessToken.value) {
        store.dispatch("retrieveAccessToken", {}).then(() => {
          composer.value.afterSendSuccess = afterSendSuccess;
        });
      } else {
        composer.value.afterSendSuccess = afterSendSuccess;
      }
    });

    return { nylasAccessToken, composer };
  },
});

export default EmailComposer;
