
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, Ref, ref, onMounted, computed } from "vue";
import Header from "@/components/header/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import VPagination from "@hennge/vue3-pagination";
import { useRoute } from "vue-router";
import { getCompanyPayrolls } from "@/services/payroll";

export default defineComponent({
  name: "Payroll Periods",
  components: {
    Header,
    Loader,
    ErrorState,
    DateFormatter,
    VPagination,
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const error = ref(false);
    const payrolls: Ref<Array<any>> = ref([]);
    const pagination: Ref<any> = ref({});
    const currentPage = ref(1);
    const company_id: Ref<string> = ref(route.params.company_id as string);

    async function refreshPayrolls() {
      loading.value = true;
      const data = await getCompanyPayrolls(
        company_id.value,
        currentPage.value
      );
      if (data) {
        payrolls.value = data.payroll_periods;
        pagination.value = data.meta;
        currentPage.value = data.meta.current_page;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    function isActive(endDate: string): boolean {
      const now = new Date();
      const then = new Date(endDate);

      return then.getTime() > now.getTime();
    }

    const activePayrolls = computed(() => {
      //return payrolls.value.filter(payroll => payroll.stripe_invoice_status !== "not_created");
      return payrolls.value;
    });

    onMounted(() => {
      refreshPayrolls();
    });

    return {
      loading,
      error,
      activePayrolls,
      payrolls,
      pagination,
      currentPage,
      company_id,
      isActive,
    };
  },
});
