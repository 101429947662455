/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";
import { instanceLoggedAPI, instanceAuthAPI } from "../axiosConfig";
export default class AccountMembersAPIService {
  private axiosInstance: AxiosInstance;
  private publicInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
    this.publicInstance = instanceAuthAPI;
  }

  async sendInvite(members: any): Promise<unknown> {
    return this.axiosInstance.post("/account_members", members);
  }
  async getMembers(page = 1): Promise<unknown> {
    return this.axiosInstance.get(`/account_members?page=${page}`);
  }
  async getTaggableMembers(id: any): Promise<unknown> {
    return this.axiosInstance.get(`/list_tag_users/${id}`);
  }
  async getCompleteAccount(token: string): Promise<unknown> {
    return this.publicInstance.get(`/get_user/${token}`);
  }
  async uploadAccount(userId: string, user: any): Promise<unknown> {
    return this.publicInstance.put(`/update_user/${userId}`, user);
  }
  async deleteMember(userId: string): Promise<unknown> {
    return this.axiosInstance.delete(`/account_members/${userId}`);
  }
  async editMember(id: string, data: any): Promise<unknown> {
    return this.axiosInstance.put(`/account_members/${id}`, data);
  }
  async createMember(data: any): Promise<unknown> {
    return this.axiosInstance.post("/account_members", data);
  }
  async switchMember(id: string, data: any): Promise<unknown> {
    return this.axiosInstance.put(`/account_members/${id}`, data);
  }
  async resendMemberInvitation(id: string): Promise<unknown> {
    return this.axiosInstance.post(`/account_members/${id}/resend_invite`);
  }
}
export const MembersAPI = new AccountMembersAPIService();
