
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { JobsAPI } from "../../services/jobs/index";
import { createToastAxelerate } from "../../utils/toast";
import { useState } from "../../composables/useState";
import { useStore } from "vuex";
import { CommentsAPI } from "../../services/comments/index";
import { optionsRejected } from "../../constants/rejectedOptions";
import ModalRejectCandidate from "@/components/modalRejectCandidate/index.vue";
import BaseModal from "@/components/baseModal/index.vue";
import { usePromisedModal } from "../../composables/usePromisedModal";

const DropDownStage = defineComponent({
  name: "DropDownStage",
  emits: ["reloadCandidates", "setLoadingStage"],
  components: {
    ModalRejectCandidate,
    BaseModal,
  },
  props: {
    stages: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const isMenuOpen = ref(false);
    const reason = ref("");
    const [showDrop, setShowDrop] = useState(false);
    const [showRejected, setShowRejected] = useState(false);
    const [lastStage, setLastStage] = useState("");
    const confirmationModal = usePromisedModal<boolean>();
    const showConfirmationModal = ref(false);
    const candidateId = computed(() => store.state.candidateInfo.data?.id);
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const openModal = async (id: string) => {
      showConfirmationModal.value = true;
      const confirmed = await confirmationModal.ask();
      if (confirmed) {
        showConfirmationModal.value = false;
        changeStage(id);
      } else {
        showConfirmationModal.value = false;
      }
    };
    const openModalRejected = (id: string) => {
      isMenuOpen.value = false;
      setShowRejected(true);
      setLastStage(id);
    };
    const validateLastStage = () => {
      context.emit("setLoadingStage");
      JobsAPI.updateCandidateStage(lastStage.value)
        .then(() => {
          createToastAxelerate(
            "Stage Candidate",
            "The candidate has been moved to stage successfully",
            "success"
          );
          context.emit("setLoadingStage");
          context.emit("reloadCandidates");
        })
        .catch((err) => {
          context.emit("setLoadingStage");
          setShowDrop(false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Change Stage",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const reloadCandidates = () => {
      context.emit("reloadCandidates");
    };

    const changeStage = (id: string) => {
      context.emit("setLoadingStage");
      JobsAPI.updateCandidateStage(id)
        .then(() => {
          createToastAxelerate(
            "Stage Candidate",
            "The candidate has been moved to stage successfully",
            "success"
          );
          context.emit("setLoadingStage");
          context.emit("reloadCandidates");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Change Stage",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };
    const handleRejected = async () => {
      const _reason: any = reason.value;
      const payload = {
        comment: {
          content: `Reason for rejection: ${_reason.name}`,
          mentions: null,
        },
      };

      JobsAPI.updateCandidateStage(lastStage.value, _reason.out_of_process)
        .then(async () => {
          await createToastAxelerate(
            "Stage Candidate",
            "The candidate has been moved to stage successfully",
            "success"
          );
          await context.emit("reloadCandidates");
          await CommentsAPI.postComment(candidateId.value, payload);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Stage Candidate",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
      setShowRejected(false);
    };
    const handleWatchStage = (current: string, id: string) => {
      isMenuOpen.value = false;
      switch (current) {
        case "Hired":
          return openModal(id);
        case "Rejected":
          return openModalRejected(id);
        default:
          return changeStage(id);
      }
    };

    return {
      isMenuOpen,
      closeMenu,
      changeStage,
      router,
      openModal,
      showDrop,
      setShowDrop,
      validateLastStage,
      handleRejected,
      setShowRejected,
      showRejected,
      handleWatchStage,
      optionsRejected,
      reason,
      lastStage,
      setLastStage,
      candidateId,
      reloadCandidates,
      showConfirmationModal,
      confirmationModal,
    };
  },
});
export default DropDownStage;
