import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-background lg:py-8 py-4" }
const _hoisted_2 = { class: "lg:container p-4" }
const _hoisted_3 = { class: "flex flex-col rounded-2xl shadow p-3 lg:p-8 space-x-8 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HiringFees = _resolveComponent("HiringFees")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_HiringFees)
        ])
      ])
    ])
  ], 64))
}