
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, PropType, ref } from "vue";
import TextField from "@/components/textField/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import Spinner from "@/components/spinner/index.vue";
import DropDown from "@/components/selectDropdown/index.vue";
import { HiringFeeAPI } from "@/services/hiringFees";
import { createToastAxelerate } from "../../utils/toast";
import { useStore } from "vuex";
import NewCurrencyField from "@/components/newCurrencyField/index.vue";

export default defineComponent({
  name: "Modal Add Hirig Fees",
  components: {
    TextField,
    Spinner,
    DropDown,
    NewCurrencyField,
  },
  props: {
    openModal: Boolean,
    hiringFee: Object as PropType<any>,
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    const isEditing = ref(false);
    const displayConfirm = ref(false);
    const hiringFeeId = ref("");
    const loadingField = ref(false);
    const hiringFeeData = ref();
    const store = useStore();
    const companies = ref([]);
    const currencies = [
      { name: "Mexican Peso (MXN)", id: "mxn" },
      { name: "US Dollar (USD)", id: "usd" },
    ];
    //Validation
    const schema = yup.object({
      title: yup.string().label("Title").required(),
      amount: yup
        .number()
        .min(0)
        .label("Amount")
        .required()
        .typeError("Amount is a required field"),
      currency: yup.string().label("Currency").required(),
      company_id: yup.string().label("Company").required(),
      first_name: yup.string().label("First name").required(),
      last_name: yup.string().label("Last name").required(),
    });

    const { handleSubmit, meta, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        title: "",
        amount: 0,
        currency: "",
        first_name: "",
        last_name: "",
        company_id: "",
      },
    });

    const fieldErrors = ref([]);
    const { value: title } = useField("title");
    const { value: amount } = useField("amount");
    const { value: currency } = useField("currency");
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: company_id } = useField("company_id");

    const onSubmit = handleSubmit((values) => {
      hiringFeeData.value = values;
      if (!isEditing.value) {
        displayConfirm.value = true;
      } else {
        updateHiringFee(values);
      }
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    function confirmHiringFee() {
      displayConfirm.value = false;
      loading.value = true;
      const amountAux = getDecimalPart(
        parseFloat(hiringFeeData.value.amount).toFixed(2)
      );
      let data = {
        expense: {
          original_amount: amountAux,
          currency: hiringFeeData.value.currency,
          company_id: hiringFeeData.value.company_id,
          first_name: hiringFeeData.value.first_name,
          last_name: hiringFeeData.value.last_name,
          expense_type: "hiring_fee",
          title: hiringFeeData.value.title,
        },
      };
      HiringFeeAPI.createHiringFee(data)
        .then(() => {
          closeModal();
          store.commit("SET_REFRESH", true);
          loading.value = false;
          createToastAxelerate(
            "Hiring Fees",
            "The hiring fee has been created successfully",
            "success"
          );
        })
        .catch((error) => {
          const {
            response: {
              data: { errors },
            },
          } = error;
          createToastAxelerate("Hiring Fees", errors[0], "danger");
          loading.value = false;
        });
    }

    function updateHiringFee(values: any) {
      loading.value = true;
      const amountAux = getDecimalPart(parseFloat(values.amount).toFixed(2));
      const data = {
        expense: {
          original_amount: amountAux,
          first_name: values.first_name,
          currency: values.currency,
          last_name: values.last_name,
          expense_type: "hiring_fee",
          title: values.title,
        },
      };
      HiringFeeAPI.editHiringFee(data, hiringFeeId.value)
        .then(() => {
          loading.value = false;
          closeModal();
          store.commit("SET_REFRESH", true);
          createToastAxelerate(
            "Hiring Fee",
            "The hiring fee has been updated successfully",
            "success"
          );
        })
        .catch((error) => {
          loading.value = false;
          const {
            response: {
              data: { errors },
            },
          } = error;
          createToastAxelerate("Hiring Fees", errors[0], "danger");
        });
    }

    function closeModal() {
      resetForm();
      emit("update:openModal", false);
    }

    function getDecimalPart(num: string) {
      const result = parseFloat(num) - Math.floor(parseFloat(num)) !== 0;
      if (result) {
        var numericPart = num.toString().split(".")[0];
        const decimalStr = num.toString().split(".")[1];
        numericPart = numericPart + decimalStr;
        return Number(numericPart);
      } else {
        return parseFloat(num) * 100;
      }
    }

    //Vars and functions
    const loading = ref(false);
    const error = ref(false);

    function getListClient() {
      HiringFeeAPI.getCompanies()
        .then((res: any) => {
          companies.value = res.data;
        })
        .catch(() => {
          createToastAxelerate("Hiring Fee", "An error has ocurred", "danger");
        });
    }

    onMounted(async () => {
      getListClient();
      if (props.hiringFee.id) {
        let amountAux = props.hiringFee.original_amount / 100;
        isEditing.value = true;
        title.value = props.hiringFee.title;
        currency.value = props.hiringFee.currency;
        amount.value = amountAux;
        first_name.value = props.hiringFee.first_name;
        last_name.value = props.hiringFee.last_name;
        hiringFeeId.value = props.hiringFee.id;
        company_id.value = props.hiringFee.company.id;
      } else {
        isEditing.value = false;
        title.value = "";
        currency.value = "";
        first_name.value = "";
        last_name.value = "";
      }
      loadingField.value = true;
    });

    return {
      loading,
      error,
      closeModal,
      onSubmit,
      confirmHiringFee,
      updateHiringFee,
      getDecimalPart,
      getListClient,
      hiringFeeId,
      hiringFeeData,
      fieldErrors,
      displayConfirm,
      meta,
      title,
      currency,
      currencies,
      company_id,
      amount,
      first_name,
      last_name,
      isEditing,
      loadingField,
      companies,
    };
  },
});
