
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CreateJobForm from "../../components/createJobForm/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import { createToastAxelerate } from "../../utils/toast";
// import Button from "../../components/button/index.vue";
// import InputUniversalCopy from "../../components/universalCopyInput/index.vue";
import { useState } from "../../composables/useState";
import { isValidURL, buildWorkableId, onlyPaste } from "../../utils/functions";
import JobStepper from "@/components/jobStepper/index.vue";

const EditJob = defineComponent({
  name: "EditJob",
  components: {
    JobStepper,
  },
  setup() {
    const store = useStore();
    const token = computed(() => store.state.auth);
    const formRef: any = ref<InstanceType<typeof CreateJobForm>>();
    const stages = computed(() => store.state.jobStages.data);
    const router = useRouter();
    const route = useRoute();
    const [input, setInput] = useState("");
    const [errUrl, setErrUrl] = useState(false);
    onMounted(() => {
      if (!token.value.token) {
        router.push("/log-in");
      }
      setTimeout(() => {
        setInput(formRef.value?.values?.workable_id);
      }, 1000);
    });
    const updateValue = (e: any) => {
      setInput(e);
    };
    const onPaste = async (e: any) => {
      const text = e.target.textContent;
      if (isValidURL(text)) {
        const { error, id } = buildWorkableId(text);
        if (error) {
          setErrUrl(true);
          createToastAxelerate(
            "Workbale id",
            "The text copied is not a workable valid url",
            "danger"
          );
          await setInput(null);
        } else {
          setErrUrl(false);
          setInput(id);
          createToastAxelerate(
            "Workbale id",
            "The url has been copied to clipboard",
            "info",
            () => undefined,
            3000,
            null,
            "#00000f"
          );
        }
      } else {
        createToastAxelerate(
          "Workbale id",
          "The text copied is not a valid url with a valid id",
          "danger"
        );
      }
    };
    const submitForm = async (jobStatus: number) => {
      const res: any = await formRef.value?.onSubmit();

      const newResult = {
        job: {
          ...res,
          workable_id: input.value,
          job_status: jobStatus,
          stages: stages.value,
          payroll_id: null,
        },
      };
      await JobsAPI.editJob(newResult, route.params.id)
        .then(() => {
          createToastAxelerate(
            "Edit Job",
            "The job has been saved.",
            "success"
          );
          router.push(`/jobs/${route.params.id}/details`);
        })
        .catch((err) => {
          const {
            respose: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Edit Job",
            errors ? errors[0] : "An error has ocucurred. please try again.",
            "danger"
          );
        });
    };
    const openWorkFlow = async () => {
      await formRef.value?.openModal();
    };
    return {
      formRef,
      submitForm,
      stages,
      errUrl,
      updateValue,
      onPaste,
      input,
      onlyPaste,
      openWorkFlow,
    };
  },
});

export default EditJob;
