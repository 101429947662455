
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { OnboardingTask } from "@/types/onboardingTask.interface";
import {
  getOnboardingList,
  changeOnboardingTaskStatus,
  addOnboardingTask,
  finishOnboardingProcess,
} from "@/services/onboarding/index";

export default defineComponent({
  name: "Onboarding",
  props: {
    user_id: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "not_started",
    },
  },
  setup(props) {
    const tasks: Ref<Array<OnboardingTask>> = ref([]);
    const newTask = ref("");

    async function changeStatus(index: number) {
      const description = tasks.value[index].description;
      const completed = tasks.value[index].completed;
      const id = tasks.value[index].id;
      const user_id = props.user_id;
      const data = await changeOnboardingTaskStatus(
        description,
        !completed,
        id,
        user_id
      );
      if (data) {
        getTasks();
      }
    }
    async function addTask() {
      const user_id = props.user_id;
      const data = await addOnboardingTask(newTask.value, user_id);
      if (data) {
        getTasks();
        newTask.value = "";
      }
    }
    async function getTasks() {
      const id = props.user_id;
      const data = await getOnboardingList(id);
      if (data) {
        tasks.value = data;
      }
    }
    async function finishOnboarding() {
      const id = props.user_id;
      const data = await finishOnboardingProcess(id);
      if (data) {
        window.location.reload();
      }
    }

    const pending = computed(
      () => tasks.value.filter((elem) => elem.completed === true).length
    );

    onMounted(() => {
      getTasks();
    });

    return {
      pending,
      tasks,
      changeStatus,
      finishOnboarding,
      newTask,
      addTask,
    };
  },
});
