
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import TextField from "../../components/textField/index.vue";
import { useRoute } from "vue-router";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import CheckBoxGroup from "../checkboxGroup/index.vue";
import { MembersAPI } from "@/services/accountMembers";

const CreateMemberForm = defineComponent({
  name: "CreateMemberForm",
  emits: ["reloadData", "closeModal"],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    keyOption: {
      type: String,
      default: "create",
    },
    products: {
      type: Array,
    },
  },
  components: {
    TextField,
    Button,
    CheckBoxGroup,
  },
  setup(props, context) {
    const route = useRoute();
    const path = route.path;
    const roles = ref([""]);
    const checkValues = ref<any>([]);
    const normalSchema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
      email: yup.string().email().required().label("Email"),
    });

    const { meta, handleSubmit, resetForm, setValues } = useForm({
      validationSchema: normalSchema,
    });
    const fields = [
      {
        name: "first_name",
        label: "First name*",
        type: "text",
      },
      { name: "last_name", label: "Last name*", type: "text" },
      { name: "email", label: "Email", type: "email" },
    ];
    const resetFormOutside = () => {
      resetForm();
    };
    const closeModal = () => {
      context.emit("closeModal");
    };
    const getData = () => {
      context.emit("reloadData");
    };
    const onSubmit = handleSubmit((values) => {
      const buildResponse = {
        ...values,
        roles: checkValues.value,
        company_id: route.params.id,
      };
      if (props.keyOption !== "edit") {
        MembersAPI.createMember(buildResponse)
          .then(() => {
            createToastAxelerate(
              "Add member",
              "The member has been added.",
              "success"
            );
            resetForm();
            closeModal();
            getData();
          })
          .catch((err) => {
            const {
              response: {
                data: { errors },
              },
            } = err;
            createToastAxelerate(
              "Add Member",
              errors ? errors[0] : "An error has ocurred.",
              "danger"
            );
            closeModal();
            getData();
          });
      } else {
        MembersAPI.editMember(props.data?.id, buildResponse)
          .then(() => {
            createToastAxelerate(
              "Edit member",
              "The member has been edited",
              "success"
            );
            resetForm();
            closeModal();
            getData();
          })
          .catch((err) => {
            const {
              response: {
                data: { errors },
              },
            } = err;
            createToastAxelerate(
              "Edit member",
              errors ? errors[0] : "An error has ocurred.",
              "danger"
            );
            closeModal();
            getData();
          });
      }
    });

    onBeforeMount(() => {
      roles.value = props.products?.map(
        (product_name) => `${product_name}_admin`
      ) as Array<string>;
      roles.value.push("sourcing_recruiter");

      if (props.keyOption === "edit") {
        checkValues.value = props.data?.roles;
        setValues({
          first_name: props.data?.first_name,
          last_name: props.data?.last_name,
          email: props.data?.email,
        });
      }
    });

    return {
      onSubmit,
      fields,
      meta,
      resetFormOutside,
      path,
      roles,
      checkValues,
    };
  },
});

export default CreateMemberForm;
