
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  PropType,
  ref,
  Ref,
  watch,
  onMounted,
  watchEffect,
} from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { createToastAxelerate } from "@/utils/toast";
import {
  getPositions,
  getJobs,
  createPosition,
  updateTalentPosition,
} from "@/services/talents";
import TextField from "@/components/textField/index.vue";
import NewCurrencyField from "@/components/newCurrencyField/index.vue";
//import TextArea from "@/components/textArea/index.vue";
import Bullets from "@/components/bullets/index.vue";
import SelectDropdown from "@/components/selectDropdown/index.vue";

export default defineComponent({
  name: "Position",
  components: {
    TextField,
    NewCurrencyField,
    //TextArea,
    Bullets,
    SelectDropdown,
  },
  props: {
    talent: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
  },
  setup(props) {
    //Validations
    const schema = yup.object({
      name: yup.string().required().label("Name"),
      pto_days: yup.number().required().min(0).label("PTO Days"),
      requirements: yup.string().label("Requirements"),
      responsibilities: yup.string().label("Responsibilities"),
      department: yup.string().label("Department"),
      position_id: yup.string().label("Position"),
      job_id: yup.string().label("Job"),
    });
    const fieldErrors = ref([]);

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: name } = useField("name");
    const { value: pto_days } = useField("pto_days");
    const { value: requirements } = useField("requirements");
    const { value: responsibilities } = useField("responsibilities");
    const { value: department } = useField("department");
    const { value: position_id } = useField("position_id");
    const { value: job_id } = useField("job_id");

    const onSubmit = handleSubmit((values) => {
      const bodyBenefits = [] as Array<any>;
      for (let i = 0; i < benefits.value.length; i++) {
        bodyBenefits.push({
          name: benefits.value[i].name,
          value: benefits.value[i].value * 100,
          currency: benefits.value[i].currency,
        });
      }
      const body = {
        position: {
          name: values.name,
          requirements: values.requirements,
          responsibilities: values.responsibilities,
          department: values.department,
          benefits: bodyBenefits,
        },
      };
      if (isNew.value) {
        createPositionAndAddToTalent(body);
      } else {
        const id = position_id.value as string;
        addPositionToTalent(id);
      }
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    //Vars and functions
    const loading = ref(false);
    const error = ref(false);
    const positionOptions: Ref<Array<any>> = ref([
      { id: "", name: "New role" },
    ]);
    const departmentOptions: Ref<Array<any>> = ref([
      { id: "engineering", name: "Engineering" },
      { id: "design", name: "Design" },
      { id: "marketing", name: "Marketing" },
      { id: "sales", name: "Sales" },
    ]);
    const jobs: Ref<Array<any>> = ref([]);
    const allJobs: Ref<Array<any>> = ref([]);
    const benefits: Ref<Array<any>> = ref([]);
    const positions: Ref<Array<any>> = ref([]);
    const isNew = ref(true);
    const hasRole = ref(false);
    const newBenefitName = ref("");
    const newBenefitAmount = ref(0);
    const newBenefitCurrency = ref("usd");
    const refreshCounterN = ref(0);
    const refreshCounterV = ref(0);
    const refreshCounterC = ref(0);
    const refreshCounterChange = ref(0);
    const currencyOptions = ref([
      { id: "mxn", name: "Mexican Peso (MXN)" },
      { id: "usd", name: "US Dollar (USD)" },
    ]);

    function addBenefit() {
      benefits.value.push({
        name: newBenefitName.value,
        value: newBenefitAmount.value,
        currency: newBenefitCurrency.value,
      });
      newBenefitName.value = "";
      newBenefitAmount.value = 0;
      newBenefitCurrency.value = "usd";
      refreshCounterN.value++;
      refreshCounterV.value++;
      refreshCounterC.value++;
    }

    async function createPositionAndAddToTalent(body: any) {
      loading.value = true;
      const data = await createPosition(props.talent.company.id, body);
      if (data) {
        const id = data.position.id;
        const talent = await updateTalentPosition(props.talent.id, id);
        if (talent) {
          window.location.reload();
        } else {
          createToastAxelerate(
            "Could not add role to talent",
            "There was an error adding the role to the talent",
            "danger"
          );
        }
      } else {
        createToastAxelerate(
          "Could not create role",
          "There was an error creating the role",
          "danger"
        );
      }
      loading.value = false;
    }

    async function addPositionToTalent(id: string) {
      loading.value = true;
      const data = await updateTalentPosition(props.talent.id, id);
      if (data) {
        window.location.reload();
      } else {
        createToastAxelerate(
          "Could not add role to talent",
          "There was an error adding the role to the talent",
          "danger"
        );
      }
      loading.value = false;
    }

    async function getAllPositions(id: string) {
      const data = await getPositions(id);
      if (data) {
        positions.value = data.positions;
        for (let i = 0; i < positions.value.length; i++) {
          positionOptions.value.push({
            id: positions.value[i].id,
            name: positions.value[i].name,
          });
        }
      } else {
        createToastAxelerate(
          "Could not load roles",
          "There was an error loading the roles",
          "danger"
        );
      }
    }

    async function getAllJobs(id: string) {
      jobs.value = [];
      const data = await getJobs(id);
      if (data) {
        allJobs.value = data.jobs;
        for (let i = 0; i < allJobs.value.length; i++) {
          jobs.value.push({
            id: allJobs.value[i].id,
            name: allJobs.value[i].title,
          });
        }
        jobs.value.push({
          name: "Roles",
          disabled: true,
        });
        for (let i = 0; i < positions.value.length; i++) {
          jobs.value.push({
            id: positions.value[i].id,
            name: positions.value[i].name,
          });
        }
      } else {
        createToastAxelerate(
          "Could not load jobs",
          "There was an error loading the jobs",
          "danger"
        );
      }
    }

    function setTemplate(id: any) {
      const job = allJobs.value.find((x) => id === x.id);
      const position = positions.value.find((x) => id === x.id);
      if (job) {
        name.value = job.title || "";
        department.value = job.department || "";
        pto_days.value = job.pto_days || 20;
        responsibilities.value = job.responsabilities || "";
        requirements.value = job.requirements || "";
      } else if (position) {
        name.value = position.title || "";
        department.value = position.name || "";
        pto_days.value = position.pto_days || 20;
        responsibilities.value = position.responsibilities || "";
        requirements.value = position.requirements || "";
      }
    }

    function setPosition(pos: any) {
      if (pos.id) {
        isNew.value = false;
      } else {
        isNew.value = true;
      }
      name.value = pos.name || "";
      department.value = pos.department || "";
      pto_days.value = pos.pto_days || 20;
      responsibilities.value = pos.responsibilities || "";
      requirements.value = pos.requirements || "";
      benefits.value = [];
      for (let i = 0; i < pos.benefits?.length; i++) {
        benefits.value.push({
          name: pos.benefits[i].name,
          value: pos.benefits[i].value / 100,
          currency: "usd",
        });
      }
      refreshCounterChange.value++;
    }

    watchEffect(() => {
      if (responsibilities.value === "" || !responsibilities.value) {
        responsibilities.value = "<li>&#160;&#160;</li>";
      }
      if (requirements.value === "" || !requirements.value) {
        requirements.value = "<li>&#160;&#160;</li>";
      }
    });

    watch(job_id, (newVal) => {
      setTemplate(newVal);
    });

    watch(position_id, (newVal) => {
      const id = newVal as string;
      if (id) {
        setPosition(positions.value.find((x) => x.id === id));
      } else {
        setPosition({});
      }
    });

    watch(name, (newVal) => {
      const newName = newVal as string;
      const pos = positions.value.find(
        (x) => newName.toLowerCase() === x.name.toLowerCase()
      );
      if (pos && isNew.value) {
        name.value = "";
        createToastAxelerate(
          "This role exists",
          "There is a role already with the same name",
          "danger"
        );
      }
    });

    onMounted(() => {
      if (props.talent.position) {
        isNew.value = false;
        hasRole.value = true;
        setPosition(props.talent.position);
      }
      const id = props.talent.company.id;
      getAllPositions(id);
      getAllJobs(id);
      pto_days.value = 20;
    });

    return {
      loading,
      error,
      name,
      responsibilities,
      requirements,
      pto_days,
      department,
      departmentOptions,
      onSubmit,
      fieldErrors,
      positions,
      positionOptions,
      position_id,
      benefits,
      isNew,
      newBenefitName,
      newBenefitAmount,
      newBenefitCurrency,
      addBenefit,
      refreshCounterN,
      refreshCounterV,
      refreshCounterC,
      refreshCounterChange,
      job_id,
      jobs,
      hasRole,
      currencyOptions,
    };
  },
});
