
import { defineComponent } from "vue";
import Divider from "@/components/divider/index.vue";

const ClientInformation = defineComponent({
  name: "ClientInformation",
  props: {
    data: {
      type: Object,
      default: () => {
        return { hello: "doa" };
      },
    },
  },
  components: {
    Divider,
  },
  setup(props) {
    const harcoded = [
      {
        label: "Visible name",
        info: props?.data?.visible_name,
        type: "normal",
      },
      {
        label: "Plan name",
        info: props?.data?.last_subscription?.nickname,
        type: "normal",
      },
      {
        label: "End date",
        info: props?.data?.last_subscription?.end_date,
        type: "normal",
      },
      {
        label: "Products",
        info: props?.data?.company_products?.join(", "),
        type: "normal",
      },
      {
        label: "Country",
        info: props?.data?.country === "usa" ? "USA" : "MX",
        type: "normal",
      },
      {
        label: "Subscription status",
        info: props?.data?.subscription_status,
        type: "status",
      },
      { label: "Email", info: props?.data?.email, type: "normal" },
      { label: "Phone number", info: props?.data?.phone, type: "normal" },
      { label: "Web page", info: props?.data?.website, type: "link" },
      { label: "Location", info: props?.data?.location, type: "normal" },
    ];
    return { harcoded };
  },
});
export default ClientInformation;
