
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  watch,
  watchEffect,
} from "vue";
import { JobsAPI } from "../../services/jobs/index";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { createToastAxelerate } from "../../utils/toast";
import Button from "../button/index.vue";
import JobWorkFlowStep from "../jobWorkFlowStep/index.vue";
import CreateJobForm from "../createJobForm/index.vue";
import EvaluationJobStep from "../evaluationJobStep/index.vue";
// import MemberJobStep from "../memberJobStep/index.vue";
import Loading from "vue-loading-overlay";
import SwitchButton from "../switchButton/index.vue";
import { usePromisedModal } from "../../composables/usePromisedModal";
import BaseModal from "@/components/baseModal/index.vue";
import Header from "@/components/header/index.vue";
import SimpleDatePicker from "../simpleDatePicker/index.vue";
import { getFormatStringDate } from "@/utils/functions";
import { useState } from "@/composables/useState";
import TextField from "@/components/textField/index.vue";
import { ChallengesAPI } from "../../services/challenges/index";

const JobStepper = defineComponent({
  name: "JobStepper",
  emits: ["getEvaluation", "getmembers"],
  components: {
    Button,
    Loading,
    SwitchButton,
    CreateJobForm,
    EvaluationJobStep,
    SimpleDatePicker,
    JobWorkFlowStep,
    BaseModal,
    Header,
    TextField,
  },
  setup() {
    const step = ref(1);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref<InstanceType<typeof CreateJobForm>>();
    const isLoading = ref(false);
    const isEditing = ref(false);
    const formValues = ref({});
    const isManaged = ref(false);
    const jobDate = ref("");
    const publish_date = ref();
    const oldManagedValue = ref(false);
    const isConfidential = ref(false);
    const matchCandidate = ref(false);
    const jobMember = ref();
    const memberRole = ref("");
    const jobMembers: any = ref([]);
    const [jobMemebersList, setJobMemebersList] = useState([]);
    const showTooltipManaged = ref(false);
    const showTooltipPublic = ref(false);
    const showConfirmationModal = ref(false);
    const showConfirmationSkillsModal = ref(false);
    const evaluationModified = ref(false);
    const hasEvaluation = ref(false);
    const showUpgradeModal = ref(false);
    const confirmationModal = usePromisedModal<boolean>();
    const confirmationSkillsModal = usePromisedModal<boolean>();
    const layoutId = document.getElementById("layout-scroll-container")!;
    const evaluationValues = reactive({
      old: "",
      new: "",
    });
    const profile = computed(() => store.state.profile.data);
    const companyIndustryId = computed(
      () => store.state.profile.data?.company?.company_industry?.id
    );
    const textHelper = ref("");
    const isEditingInfo = ref(false); // change the name of thev variable, this var is used to avoid pre-set values on editing.
    const reRenderComponents = ref(0);
    const newFormValues = ref({
      id: "",
      title: "",
      department: "",
      department_code: "",
      location: "",
      created_at: "",
      job_benefits: "",
      company_industry_id: companyIndustryId.value,
      job_function_id: "",
      employment_type: "",
      experience: "",
      education: "",
      salary_max: 0,
      respry_min: 0,
      salaonsabilities: "",
      required_skills: [],
      optional_skills: [],
      job_status: 0,
      payroll_id: null,
      has_challenge_assigned: false,
      evaluation_id: "",
      evaluation_title: "",
      test: {
        id: "",
        title: "",
        internalDescription: "",
        duration: 0,
        test_id: "",
      },
      job_members: [],
      is_managed: false,
      is_confidential: false,
      match_candidates: false,
      job_evaluation: "",
      company_id: "",
      currency_id: "",
      preferred_work: "",
      work_type: "",
      preferred_work_type: {},
    });
    const stepperProgress = computed(() => {
      return (100 / 3) * (step.value - 1) + "%";
    });

    const challengeValues = ref({
      id: "",
      title: "",
      challenge_url: "",
    });

    const stepStages = ref([
      {
        number: 1,
        title: "Job details and skills",
        description: "Tell applicants why it's great to work at your company",
      },
      {
        number: 2,
        title: "Stages & assessments",
        description: "Select the mandatory workflow stages and assessments",
      },
      {
        number: 3,
        title: "Configuration",
        description: "Choose the configuration for this job",
      },
    ]);

    const changeStep = async (value: string) => {
      window.scrollTo(0, 0);
      if (value === "next") {
        switch (step.value) {
          case 1:
            try {
              await jobFormValues(1);
              step.value++;
            } catch (error) {
              createToastAxelerate(
                "Required fields",
                "Fill the required fileds",
                "danger"
              );
              step.value == 1;
            }
            break;
          case 2:
            step.value++;
            break;
        }
      } else {
        step.value--;
      }
    };

    const setRequiredSkills = (skills: any) => {
      newFormValues.value.required_skills = skills;
    };

    const setNiceToHaveSkills = (skills: any) => {
      newFormValues.value.optional_skills = skills;
    };

    const jobFormValues = async (jobStatus: number) => {
      const res: any = await formRef.value?.onSubmit();

      if (formRef.value?.meta.valid) {
        Object.assign(newFormValues.value, res);
        newFormValues.value.job_status = jobStatus;
      } else {
        throw new Error("Fill the required fields");
      }
    };

    const getEvaluation = async (evaluation: any) => {
      if (evaluation.id === "") {
        hasEvaluation.value = false;
      }
      if (hasEvaluation.value === true) {
        newFormValues.value.evaluation_id = evaluation.evaluation_id;
      } else {
        newFormValues.value.evaluation_id = evaluation.id;
      }
    };

    const getMembers = (members: any) => {
      let members_ids = members.map((x: any) => x.id);
      newFormValues.value.job_members = members_ids;
    };

    const setJobValues = async (jobId: string) => {
      const response = (await JobsAPI.getOneJob(jobId)) as any,
        job = response.data;
      delete job.workable_id;
      // newFormValues.value = job;
      await Object.assign(newFormValues.value, job);
      hasEvaluation.value = response.data.has_evaluation;
      if (hasEvaluation.value) {
        await getEvaluation(response.data.job_evaluation);
      }

      isConfidential.value = response.data.is_confidential;
      oldManagedValue.value = response.data.is_managed;
      isManaged.value = response.data.is_managed;
      matchCandidate.value = response.data.match_candidates;
      newFormValues.value.company_id = response.data.company.id;
      newFormValues.value.currency_id = response.data.currency_id;
      newFormValues.value.work_type = response.data.work_type;
      jobDate.value = response.data.created_at;
      isLoading.value = false;

      setTimeout(() => {
        isEditingInfo.value = false;
      }, 3000);
    };

    const validateData = async (data: string) => {
      let jobId = route.params.id as string;
      const response = (await JobsAPI.getOneJob(jobId)) as any;

      let old_evaluation = response.data.job_evaluation
        ? response.data.job_evaluation.evaluation_id
        : "";

      switch (data) {
        case "evaluation":
          evaluationValues.old = old_evaluation;
          if (old_evaluation !== newFormValues.value.evaluation_id) {
            evaluationModified.value = true;
          }
          break;
      }
    };

    const editJob = async () => {
      if (matchCandidate.value) {
        if (newFormValues.value.required_skills.length <= 0) {
          addSkillsInfoModal();
          return false;
        }
      }
      isLoading.value = true;
      newFormValues.value.is_managed = isManaged.value;
      newFormValues.value.is_confidential = isConfidential.value;
      newFormValues.value.match_candidates = matchCandidate.value;
      if (newFormValues.value.required_skills.some((item: any) => item.name)) {
        newFormValues.value.required_skills = getSkillsName(
          newFormValues.value.required_skills
        );
      }
      if (newFormValues.value.optional_skills.some((item: any) => item.name)) {
        newFormValues.value.optional_skills = getSkillsName(
          newFormValues.value.optional_skills
        );
      }

      switch (newFormValues.value.preferred_work) {
        case "hybrid":
          newFormValues.value.preferred_work_type = {
            hybrid: true,
            remote: false,
            on_site: false,
          };
          break;

        case "remote":
          newFormValues.value.preferred_work_type = {
            hybrid: false,
            remote: true,
            on_site: false,
          };

          break;
        case "on_site":
          newFormValues.value.preferred_work_type = {
            hybrid: false,
            remote: false,
            on_site: true,
          };
          break;
      }

      const newResult = {
        job: {
          ...newFormValues.value,
          job_status: 1,
          publish_date: publish_date.value ? publish_date.value : null,
          payroll_id: null,
        },
      };

      const payload = {
        job_id: String(route.params.id),
      };

      await validateData("evaluation");
      if (evaluationModified.value) {
        if (evaluationValues.old !== "") {
          await JobsAPI.removeJobEvaluation(String(route.params.id)).catch(
            () => {
              createToastAxelerate(
                "Cannot remove talent evaluation",
                "Talent evaluation cannot be removed if there are candidates in any stage other than talent pool",
                "danger"
              );
            }
          );
        }
        if (newFormValues.value.evaluation_id !== "") {
          await JobsAPI.assignTemplate(
            newFormValues.value.evaluation_id,
            payload
          ).catch((err) => {
            const {
              response: {
                data: { errors },
              },
            } = err;
            createToastAxelerate(
              "Apply Job",
              errors ? errors[0] : "An error has ocurred.",
              "danger"
            );
          });
        }
      }

      await JobsAPI.editJob(newResult, route.params.id)
        .then(() => {
          createToastAxelerate(
            "Edit Job",
            "The job has been saved.",
            "success"
          );

          isLoading.value = false;
          router.push(`/jobs/${route.params.id}/details`);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Edit Job",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
          if (errors[0].slice(0, 12) === "Stripe Error") {
            createToastAxelerate(
              "Managed job",
              "This client does't have a default payment method",
              "danger"
            );
          }
          isLoading.value = false;
        });
    };

    const confirmationJobSend = async (option: string) => {
      switch (option) {
        case "create":
          publishJob();
          break;

        case "edit":
          editJob();
          break;
      }
    };

    const getChallengeStage = (challenge: any) => {
      newFormValues.value.has_challenge_assigned = challenge;
    };

    const publishJob = async () => {
      if (matchCandidate.value) {
        if (newFormValues.value.required_skills.length <= 0) {
          addSkillsInfoModal();
          return false;
        }
      }

      switch (newFormValues.value.preferred_work) {
        case "hybrid":
          newFormValues.value.preferred_work_type = {
            hybrid: true,
          };
          break;

        case "remote":
          newFormValues.value.preferred_work_type = {
            remote: true,
          };

          break;
        case "on_site":
          newFormValues.value.preferred_work_type = {
            on_site: true,
          };
          break;
      }
      newFormValues.value.is_managed = isManaged.value;
      newFormValues.value.is_confidential = isConfidential.value;
      newFormValues.value.match_candidates = matchCandidate.value;
      newFormValues.value.job_status = 1;
      isLoading.value = true;
      const payload = {
        job: {
          ...newFormValues.value,
        },
      };

      await JobsAPI.createJob(payload)
        .then(async (res: any) => {
          createToastAxelerate(
            "Create Job",
            "The job has been saved.",
            "success"
          );
          store.commit("FORM_FULLFILED", res.data);

          /**
           * Add job members to published job
           */

          if (newFormValues.value.has_challenge_assigned) {
            await assignChallengeToJob(res.data.id);
          }

          const payload = {
            job_member: {
              users: jobMembers.value,
            },
          };

          JobsAPI.addJobMembers(payload, res.data.id)
            .then((response) => {
              router.push(`/jobs/${res.data.id}/details`);
              isLoading.value = false;
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate(
                "Apply Job",
                errors ? errors[0] : "An error has ocurred.",
                "danger"
              );
            });
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create Job",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
          if (errors[0] === "stripe_payment_method not found") {
            showUpgradeModal.value = true;
          }
          isLoading.value = false;
        });
    };

    const addSkillsInfoModal = async () => {
      showConfirmationSkillsModal.value = true;
      const confirmed = await confirmationModal.ask();
      if (confirmed) {
        step.value = 1;
        showConfirmationSkillsModal.value = false;
      } else {
        showConfirmationSkillsModal.value = false;
      }
    };

    const attachPaymentMethod = async () => {
      newFormValues.value.is_managed = false;
      newFormValues.value.is_confidential = isConfidential.value;
      newFormValues.value.match_candidates = matchCandidate.value;
      newFormValues.value.job_status = 0;

      const payload = {
        job: {
          ...newFormValues.value,
        },
      };

      await JobsAPI.createJob(payload)
        .then((res: any) => {
          createToastAxelerate(
            "Create Job",
            "The job has been saved.",
            "success"
          );
          store.commit("FORM_FULLFILED", res.data);
          let response_job_id = res.data.id;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create Job",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
          isLoading.value = false;
        });
    };

    const getSkillsName = (skills: any) => {
      let newArray: [] = [];
      skills.map((skill: any) => {
        skill.name
          ? newArray.push(skill.name as never)
          : newArray.push(skill as never);
      });
      return newArray;
    };

    watch(isManaged, async (newVal) => {
      textHelper.value = "a Managed Job";
      if (isEditingInfo.value === false) {
        if (newVal) {
          showConfirmationModal.value = true;
          const confirmed = await confirmationModal.ask();
          isManaged.value = confirmed ? true : false;
          showConfirmationModal.value = false;
        } else {
          isManaged.value = false;
          showConfirmationModal.value = false;
        }
      }
    });

    watch(matchCandidate, async (newVal) => {
      textHelper.value = "AI Talent Matching";
      if (isEditingInfo.value === false) {
        if (newVal) {
          if (newFormValues.value.required_skills.length <= 0) {
            addSkillsInfoModal();
            return false;
          } else {
            showConfirmationModal.value = true;
            const confirmed = await confirmationModal.ask();
            matchCandidate.value = confirmed ? true : false;
            showConfirmationModal.value = false;
          }
        } else {
          matchCandidate.value = false;
          showConfirmationModal.value = false;
        }
      }
    });

    watchEffect(() => {
      if (
        newFormValues.value.company_id !== "" ||
        newFormValues.value.company_id
      ) {
        getMembersList();
      }
    });

    const getMembersList = async () => {
      JobsAPI.jobMemberList(newFormValues.value.company_id)
        .then((response: any) => {
          setJobMemebersList(response.data.users);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate("Get members list", errors[0], "danger");
        });
    };

    onMounted(() => {
      if (route.name === "Edit Job") {
        isEditingInfo.value = true;
        isEditing.value = true;
        isLoading.value = true;
      }
      const jobId = route.params.id as string;
      if (jobId) setJobValues(jobId);
    });

    const assignChallengeToJob = (job_id: any) => {
      let job_challenge = {
        title: challengeValues.value.title,
        challenge_url: challengeValues.value.challenge_url,
      };

      ChallengesAPI.assignChallengeToJob(job_id, job_challenge).catch(
        (err: any) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Assign challenge",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        }
      );
    };

    const addMember = () => {
      let payload = {
        id: jobMember.value.id,
        name: jobMember.value.user_name,
        email: jobMember.value.email,
        role: memberRole.value,
      };
      jobMembers.value.push(payload);
      jobMembers.value = jobMembers.value.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      jobMember.value = "";
      memberRole.value = "";
    };

    const onSelectChange = (e: any, index: number) => {
      jobMembers.value[index].role = e.target.value;
    };

    const deleteJobMember = (index: number) => {
      jobMembers.value.splice(index, 1);
    };

    const forceRerender = () => {
      reRenderComponents.value += 1;
    };

    return {
      jobDate,
      step,
      stepperProgress,
      stepStages,
      changeStep,
      jobFormValues,
      formValues,
      newFormValues,
      setRequiredSkills,
      setNiceToHaveSkills,
      getEvaluation,
      getMembers,
      publishJob,
      isLoading,
      isManaged,
      isConfidential,
      matchCandidate,
      showTooltipManaged,
      showTooltipPublic,
      profile,
      companyIndustryId,
      editJob,
      evaluationValues,
      showConfirmationModal,
      confirmationJobSend,
      showUpgradeModal,
      formRef,
      isEditing,
      confirmationModal,
      showConfirmationSkillsModal,
      confirmationSkillsModal,
      publish_date,
      getFormatStringDate,
      textHelper,
      jobMembers,
      jobMember,
      memberRole,
      addMember,
      jobMemebersList,
      setJobMemebersList,
      onSelectChange,
      deleteJobMember,
      challengeValues,
      getChallengeStage,
      reRenderComponents,
    };
  },
});

export default JobStepper;
