/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-character-class */

import { formatDistanceStrict } from "date-fns";
import moment from "moment";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getInitials = (name: any): any => {
  let initials = name.split(" ");
  if (initials.length > 1) {
    initials = initials?.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }
  return initials.toUpperCase();
};

export const buildColorStep = (option: string) => {
  switch (option) {
    case "Talent Pool":
      return {
        backgroundColor: "#E5F8FF",
        color: "#00A7E5",
        border: "1px solid #00A7E5",
      };
    case "Offer":
      return {
        backgroundColor: "#FFF8F2",
        color: "#E56900",
        border: "1px solid #E56900",
      };
    case "Hired":
      return {
        backgroundColor: "#F2FFF9",
        color: "#00E574",
        border: "1px solid #00E574",
      };
    case "2nd Interview":
      return {
        backgroundColor: "#FEFAF1",
        color: "#CB911A",
        border: "1px solid #CB911A",
      };
    case "Screening":
      return {
        backgroundColor: "#FBF2FF",
        color: "#9D04E1",
        border: "1px solid #9D04E1",
      };
    case "Technical Interview":
      return {
        backgroundColor: "#FFF2FC",
        color: "#E500B5",
        border: "1px solid #E500B5",
      };
    case "Challenge":
      return {
        backgroundColor: "#FFF2F2",
        color: "#E50000",
        border: "1px solid #E50000",
      };
    case "Rejected":
      return {
        backgroundColor: "#FEE2E2",
        color: "#F87171",
        border: "1px solid #EF4444",
      };
    default:
      return {
        backgroundColor: "#E5F8FF",
        color: "#00A7E5",
        border: "1px solid #00A7E5",
      };
  }
};

export const getCompanyName = (str: string) => {
  return str.substring(str.lastIndexOf("@") + 1, str.lastIndexOf("."));
};

export const validateEmail = (value: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const numberWithCommas = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const numberFormattedWithCommas = (x: number) =>
  x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const timeAgo = (date: string) => {
  return formatDistanceStrict(new Date(date), new Date(), {
    addSuffix: true,
  });
};

export const getFormatStringDate = (d: string) => {
  const date = new Date(d);

  return date.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
};

export const formatedDateMoment = (date: string) => {
  return date ? moment(date).format("MMM Do YYYY, h:mm a") : "";
};

export const isValidURL = (str: string) => {
  const res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
};

export const workableValidURL = (str: string) => {
  const valid = /^(http(s)?(:\/\/))?(www\.)?apply.workable\.com(\/.*)?$/;
  return valid.test(str);
};

export const buildWorkableId = (url: string) => {
  if (workableValidURL(url)) {
    const parsedUrl = new URL(url);
    if (parsedUrl?.pathname?.split("/")[3] !== undefined) {
      return {
        error: false,
        id: parsedUrl?.pathname?.split("/")[3],
      };
    }
    return {
      error: true,
      id: parsedUrl?.pathname?.split("/")[3],
    };
  }
  return {
    error: true,
    id: null,
  };
};

export const onlyPaste = (evt: any) => {
  // Note this could be a bit of overkill but I found some
  // problems in Firefox and decided to go the distance
  // including old windows keyboard short cut keys.
  // Enumerate all supported clipboard, undo and redo keys
  const clipboardKeys = {
    winInsert: 45,
    winDelete: 46,
    SelectAll: 97,
    macCopy: 99,
    macPaste: 118,
    macCut: 120,
    redo: 121,
    undo: 122,
  };
  // Simulate readonly but allow all clipboard, undo and redo action keys
  const charCode = evt.which;
  //alert(charCode);
  // Accept ctrl+v, ctrl+c, ctrl+z, ctrl+insert, shift+insert, shift+del and ctrl+a
  if (
    (evt.ctrlKey && charCode == clipboardKeys.redo) /* ctrl+y redo			*/ ||
    (evt.ctrlKey && charCode == clipboardKeys.undo) /* ctrl+z undo			*/ ||
    (evt.ctrlKey && charCode == clipboardKeys.macCut) /* ctrl+x mac cut		*/ ||
    (evt.ctrlKey && charCode == clipboardKeys.macPaste) /* ctrl+v mac paste		*/ ||
    (evt.ctrlKey && charCode == clipboardKeys.macCopy) /* ctrl+c mac copy		*/ ||
    (evt.shiftKey &&
      evt.keyCode == clipboardKeys.winInsert) /* shift+ins windows paste	*/ ||
    (evt.shiftKey &&
      evt.keyCode == clipboardKeys.winDelete) /* shift+del windows cut	*/ ||
    (evt.ctrlKey &&
      evt.keyCode == clipboardKeys.winInsert) /* ctrl+ins windows copy	*/ ||
    (evt.ctrlKey && charCode == clipboardKeys.SelectAll) /* ctrl+a select all		*/
  ) {
    return 0;
  }
  // Shun all remaining keys simulating readonly textbox
  const theEvent = evt || window.event;
  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  const regex = /[]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    theEvent.preventDefault();
  }
};
