
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, Ref, ref, watch } from "vue";
import Header from "@/components/header/index.vue";
import UserCard from "@/components/userCard/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import Divider from "@/components/divider/index.vue";
import Loader from "@/components/loader/index.vue";
import ErrorState from "@/components/errorState/index.vue";
import Onboarding from "@/components/onboarding/index.vue";
import PaymentBreakdown from "@/components/paymentBreakdown/index.vue";
import PaymentInformation from "@/components/paymentInformation/index.vue";
//import Job from "@/components/talentJob/index.vue";
import Position from "@/components/talentPosition/index.vue";
import TalentDetailSidebar from "@/components/talentDetailSidebar/index.vue";
import AddNomilineaId from "@/components/addNomilineaID/index.vue";
import ModalOffboarding from "@/components/modalOffboarding/index.vue";
import { useRoute } from "vue-router";
import { getTalent, syncWithNomilinea } from "@/services/talents/index";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "Talent",
  components: {
    Header,
    UserCard,
    DateFormatter,
    Divider,
    Loader,
    ErrorState,
    Onboarding,
    PaymentBreakdown,
    PaymentInformation,
    TalentDetailSidebar,
    AddNomilineaId,
    //Job,
    Position,
    ModalOffboarding,
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const error = ref(false);
    const openModal = ref(false);
    const showConfirmation = ref(false);
    const openModalOffboarding = ref(false);
    const openMenu = ref(null);
    const showMenu = ref(false);
    const refreshPage = ref(false);
    const subpage = ref("timeline");
    const menu: Ref<Array<any>> = ref([]);
    const talent: Ref<any> = ref({});
    const talentAndMeta = ref({});

    onClickOutside(openMenu, () => {
      openMenu.value = null;
      showMenu.value = false;
      showConfirmation.value = false;
    });

    function selectTab(item: any) {
      for (let i = 0; i < menu.value.length; i++) {
        if (item.name === menu.value[i].name) {
          menu.value[i].selected = true;
          subpage.value = item.name;
        } else {
          menu.value[i].selected = false;
        }
      }
    }
    async function getTalentDetails() {
      loading.value = true;
      menu.value = [];
      const id = route.params.id as string;
      const data = await getTalent(id);
      if (data) {
        talentAndMeta.value = data;
        talent.value = data.talent;
        menu.value.push({ name: "timeline", selected: true });
        if (talent.value.status !== "inactive") {
          menu.value.push({ name: "role", selected: false });
        }
        menu.value.push({ name: "payment-breakdown", selected: false });
        if (talent.value.status !== "inactive") {
          menu.value.push({ name: "payment-information", selected: false });
        }
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    async function syncTalentWithNomilinea() {
      loading.value = true;
      const id = route.params.id as string;
      const data = await syncWithNomilinea(id);
      if (data) {
        refreshPage.value = true;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    watch(
      () => route.params.id,
      () => {
        getTalentDetails();
      }
    );
    watch(
      () => route.params.subpage,
      (newVal) => {
        selectTab({ name: newVal, selected: true });
      }
    );

    watch(refreshPage, (newVal) => {
      if (newVal) {
        getTalentDetails();
        refreshPage.value = false;
      }
    });

    onMounted(() => {
      getTalentDetails();
      const page = route.params.subpage as string;
      selectTab({ name: page, selected: true });
    });

    return {
      loading,
      error,
      talent,
      talentAndMeta,
      menu,
      subpage,
      selectTab,
      syncTalentWithNomilinea,
      openModal,
      openModalOffboarding,
      showConfirmation,
      openMenu,
      showMenu,
      refreshPage,
    };
  },
});
