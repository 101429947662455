
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, Ref, ref, watch } from "vue";
import Header from "@/components/header/index.vue";
import Loader from "@/components/loader/index.vue";
import CompanyCard from "@/components/companyCard/index.vue";
import { useRoute } from "vue-router";
import ModalAddMember from "@/components/modalAddMember/index.vue";
import CollaboratorsTable from "@/components/collaboratorsTable/index.vue";
//import { CompanyAPI } from "@/services/companies";

export default defineComponent({
  name: "Client",
  components: {
    Header,
    Loader,
    CompanyCard,
    ModalAddMember,
    CollaboratorsTable,
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const error = ref(false);
    const openModal = ref(false);
    const company_id = route.params.id as string;
    const company: Ref<any> = ref({
      visible_name: "Left Field Labs",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      email: "test@test.com",
      phone: "123456789",
      website: "http://axented.com",
      location: "Monterrey",
      country: "Mexico",
      size: "200 talent",
    });
    const subpage = ref("about");
    const menu = ref([
      { name: "about", selected: true },
      { name: "benefits", subpath: false },
    ]);

    function selectTab(item: any) {
      for (let i = 0; i < menu.value.length; i++) {
        if (item.name === menu.value[i].name) {
          menu.value[i].selected = true;
          subpage.value = item.name;
        } else {
          menu.value[i].selected = false;
        }
      }
    }

    /*     async function getCompany() {
      loading.value = true;
      const res = await CompanyAPI.getCompany(company_id);
      if (res) {
        company.value = res.data;
      } else {
        error.value = true;
      }
      loading.value = false;
    } */

    onMounted(() => {
      //getCompany();
    });

    watch(
      () => route.params.id,
      () => {
        //getCompany();
      }
    );
    watch(
      () => route.params.subpage,
      (newVal) => {
        selectTab({ name: newVal, selected: true });
      }
    );

    return {
      loading,
      error,
      company_id,
      company,
      menu,
      selectTab,
      openModal,
    };
  },
});
