
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onBeforeMount,
  watchEffect,
  watch,
} from "vue";
import VueTableLite from "vue3-table-lite/ts";
import { createToastAxelerate } from "../../utils/toast";
import { ClientAPI } from "@/services/clients/index";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Button from "../button/index.vue";
import CreateMemberForm from "../createMemberForm/index.vue";
import ReusableModal from "../reusableModal/index.vue";
import Paginator from "@/components/paginator/index.vue";
import { useState } from "../../composables/useState";
import { MembersAPI } from "../../services/accountMembers/index";
import SearchInput from "../../components/searchInput/index.vue";
import { removeBlankProperties } from "@/utils/params";

const MembersTable = defineComponent({
  name: "MembersTable",
  components: {
    VueTableLite,
    CreateMemberForm,
    Button,
    ReusableModal,
    Paginator,
    SearchInput,
  },
  props: {
    visible: Boolean,
    /** TODO(Rodolfo Islas): Add specific data type */
    company: Object,
  },
  setup(props) {
    const store = useStore();
    const tableData: Ref<Array<any>> = ref([]);
    const page: Ref<number> = ref(1);
    const pagination: Ref<any> = ref({});
    const openModal = ref(false);
    const client = ref({});
    const route = useRoute();
    const filters = ref({});
    const inputValue = ref("");
    const openDropdown = ref("");
    const currentDropdown = ref("");
    const products = ref([""]);
    const [key, setKey] = useState("");
    store.commit("SET_REFRESH", false);
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          isKey: true,
          width: "10%",
        },
        {
          label: "Email",
          field: "email",
          isKey: true,
          width: "8%",
        },
        {
          label: "Roles",
          field: "roles",
          sortable: true,
          width: "1%",
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          width: "1%",
        },
        {
          label: "",
          field: "menu",
          sortable: false,
          width: "1%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });

    const getMembers = async () => {
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      const res = (await ClientAPI.getClientMembers(
        route?.params?.id,
        page.value,
        _filters
      )) as any;
      if (res) {
        tableData.value = res.data.users;
        pagination.value = res.data.meta.pagination;
        setTable(tableData.value);
      }
      table.isLoading = false;
    };

    const closeMenu = () => {
      currentDropdown.value = "";
    };

    const deactivateMember = async (id: string) => {
      const payload = {
        account_member: {
          status: "deactivated",
        },
      };
      MembersAPI.switchMember(id, payload).then(async () => {
        await createToastAxelerate(
          "Account members",
          "The member has been deactivated",
          "success"
        );
        await getMembers();
        await closeMenu();
      });
    };

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
      });
    });

    const openDropDown = (data: any) => {
      client.value = data;
      currentDropdown.value = data.id;
    };

    const resendInvitation = async (id: string) => {
      MembersAPI.resendMemberInvitation(id).then(async (res: any) => {
        await createToastAxelerate(
          "Resend invitation",
          res.data.message,
          "success"
        );
        await getMembers();
        await closeMenu();
      });
    };

    const setTable = (rows: Array<any>) => {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    };

    const searchTitle = (event: any) => {
      const value = event.target.value.toLowerCase();
      const data = tableData.value.filter((row) => {
        return (
          row.first_name.toLowerCase().includes(value) ||
          row.last_name.toLowerCase().includes(value) ||
          row.id.includes(value)
        );
      });
      setTable(data);
    };

    function updateHandler(e: any) {
      page.value = e;
      getMembers();
    }

    onBeforeMount(() => {
      getMembers();
      products.value = props.company?.company_products || [];
    });

    watch(inputValue, (newVal) => {
      if (newVal) {
        page.value = 1;
      }
    });

    return {
      table,
      closeMenu,
      searchTitle,
      openDropdown,
      currentDropdown,
      openDropDown,
      openModal,
      client,
      deactivateMember,
      resendInvitation,
      setKey,
      key,
      getMembers,
      products,
      page,
      pagination,
      updateHandler,
      inputValue,
      filters,
    };
  },
});
export default MembersTable;
