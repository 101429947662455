
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, PropType, ref } from "vue";
import DateFormatter from "@/components/dateFormatter/index.vue";
import ModalExpenses from "@/components/modalExpenses/index.vue";

export default defineComponent({
  name: "Card Extra Charge",
  components: {
    DateFormatter,
    ModalExpenses,
  },
  props: {
    extraCharge: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const showTooltip = ref(false);
    const openModal = ref(false);
    const hasTooltip = ref(false);

    if (props.extraCharge.description?.length > 18) {
      hasTooltip.value = true;
    }

    return { showTooltip, hasTooltip, openModal };
  },
});
