<template>
  <div class="flex flex-col justify-center items-center p-5 rounded-lg">
    <div
      :class="`bg-cover bg-center  ${height} ${width} small-hero flex flex-col justify-end items-end ${
        type === 'fileImage'
          ? 'rounded-lg border'
          : 'rounded-full border-4 border-purple-300'
      }`"
      :style="`background-image: url(${imgPath})`"
    >
      <div class="flex flex-row space-x-2">
        <div class="flex flex-col justify-end items-end cursor-pointer">
          <div :class="`${className}`">
            <input
              class="w-14 h-full absolute cursor-pointer hidden opacity-0 -mt-12"
              :name="name"
              :id="name"
              ref="fileInput"
              type="file"
              accept="image/*"
              :onchange="getFileName"
              @change="$emit('update:modelValue', $event.target.files[0])"
            />
            <label class="relative -z-50" :for="name">
              <svg
                v-if="type === 'fileImage'"
                class="h-6 w-6 cursor-pointer"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                <circle cx="8.5" cy="8.5" r="1.5" />
                <polyline points="21 15 16 10 5 21" />
              </svg>
              <svg
                v-else
                class="h-6 w-6 cursor-pointer text-primary"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx="12" cy="13" r="3" />
                <path
                  d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h2m9 7v7a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"
                />
                <line x1="15" y1="6" x2="21" y2="6" />
                <line x1="18" y1="3" x2="18" y2="9" />
              </svg>
            </label>
          </div>
        </div>
      </div>
    </div>
    <span class="text-sm mt-2 text-gray-400">{{ placeholder }}</span>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { defineComponent, ref, watchEffect, onMounted } from "vue";
import { useRouter } from "vue-router";

const DragAndDropFile = defineComponent({
  name: "DragAndDropFile",
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    fileTypes: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "w-72",
    },
    height: {
      type: String,
      default: "h-72",
    },
    type: {
      type: String,
      default: "fileImage",
    },
    name: {
      type: String,
      default: "file",
    },
    className: {
      type: String,
      default:
        "absolute rounded-full flex flex-col bg-gray-400 text-gray-700 relative justify-center items-center w-14 h-14 bottom-2 right-2 opacity-40 cursor-pointer",
    },
  },
  setup(props, contex) {
    const files = ref([]);
    const imgPath = ref("");
    const fileName = ref("");
    const router = useRouter();

    const getFileName = (event) => {
      imgPath.value = window.URL.createObjectURL(event.target.files[0]);
    };

    onMounted(() => {
      imgPath.value = props.url;
    });

    watchEffect(() => {
      if (!props.modelValue) {
        imgPath.value = props.url;
      }
    });

    return {
      files,
      router,
      fileName,
      getFileName,
      imgPath,
    };
  },
});
export default DragAndDropFile;
</script>
<style scoped>
.hero {
  height: 580px;
}
</style>
