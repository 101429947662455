/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { Reimbursement } from "./types";

export const actions: ActionTree<Reimbursement, RootState> = {
  setRefresh({ commit }, data) {
    commit("SET_REFRESH", data);
  },
};
