/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";

import { RegisterNylas } from "./types";
import { instanceLoggedAPI } from "../axiosConfig";

export default class NylasAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = instanceLoggedAPI;
  }

  async getAccessToken(): Promise<unknown> {
    return this.axiosInstance.get("/vendors/nylas");
  }

  async registerNylas(data: RegisterNylas): Promise<unknown> {
    return this.axiosInstance.post("/vendors/nylas", data);
  }

  async createEvent(data: any): Promise<any> {
    return this.axiosInstance.post("/vendors/nylas/events", data);
  }

  async getLinkStatus(): Promise<any> {
    return this.axiosInstance.get("/vendors/nylas/connections/microsoft_teams");
  }

  async getLinkUrl(): Promise<any> {
    return this.axiosInstance.post(
      "/vendors/nylas/connections/microsoft_teams",
      { platform: 'internal' }
    );
  }

  async saveTeamsInfo(data: any): Promise<any> {
    return this.axiosInstance.put(
      "/vendors/nylas/connections/microsoft_teams",
      data
    );
  }

  async getAvailability(body: any): Promise<any> {
    let query = "";
    for (let i = 0; i < body.user_emails.length; i++) {
      const email = body.user_emails[i];
      query += `availability[user_emails][]=${email}&`;
    }
    query += `availability[start_time]=${body.start_time}&`;
    query += `availability[duration_minutes]=${body.duration_minutes}`;
    const searchParams = new URLSearchParams(query);
    return this.axiosInstance.get("/calendar_availabilities", {
      params: searchParams,
    });
  }
}

export const nylasAPI = new NylasAPIService();
