import { ActionTree } from "vuex";
import { RootState } from "../types";
import { AuthState } from "./types";
import { AuthAPI } from "../../services/auth";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const actions: ActionTree<AuthState, RootState> = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      AuthAPI.login(user)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((result: any) => {
          commit("lOGIN_FULLFILED", result.data.session.token);
          commit("SET_USERROLE", result.data.session.user.role, { root: true });
          localStorage.setItem(
            "token",
            JSON.stringify(result.data.session.token)
          );
          resolve("/");
        })
        .catch((e) => {
          commit("LOGIN_REJECTED", e);
          reject(e);
        });
    });
  },
  logout({ commit }, token) {
    return new Promise((resolve, reject) => {
      AuthAPI.logout(token)
        .then(() => {
          commit("LOGOUT");
          cookies.remove("token", "/", ".axelerate.io");
          localStorage.removeItem("token");
          resolve(true);
        })
        .catch((err) => {
          commit("LOGOUT_REJECTED", err);
          reject(err.message);
        });
    });
  },
  expiredToken({ commit }) {
    cookies.remove("token", "/", ".axelerate.io");
    //cookies.remove("token");
    localStorage.removeItem("token");
    commit("lOGIN_FULLFILED", "");
  },
};
