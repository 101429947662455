<template>
  <input
    @input="(event) => $emit('update:checked', event.target.checked)"
    type="checkbox"
    :checked="checked"
    :id="fieldId"
    :disabled="disabled"
    class="mr-2 hidden"
    v-bind="$attrs"
  />
  <label
    :for="fieldId"
    class="flex flex-row items-center text-sm cursor-pointer select-none overflow-auto"
  >
    <i
      class="fa mr-2"
      :class="{
        [`${size} fa-check-circle text-primary`]: checked,
        [`${size} fa-circle text-gray-300`]: !checked,
      }"
    />
    {{ label }}
  </label>
</template>

<script>
import { defineComponent } from "vue";

const CheckBox = defineComponent({
  name: "CheckBox",
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "fa-lg",
    },
    fieldId: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

export default CheckBox;
</script>
