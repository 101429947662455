
import { useState } from "@/composables/useState";
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import CandidateInfo from "../../components/candidateInfo/index.vue";
import { useStore } from "vuex";
// import Loader from "../../components/loader/index.vue";
import CandidateForm from "../../components/addCandidateForm/index.vue";
import DeleteCandidate from "../../components/deleteCandidateAlert/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";

const JobCandidateShow = defineComponent({
  name: "JobCandidateShow",
  components: {
    CandidateInfo,
    CandidateForm,
    DeleteCandidate,
    ReusableModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const candidate = computed(() => store.state.candidateInfo.data);
    const [showDelete, setShowDelete] = useState(false);
    const formOption = ref(false);
    const isLoading = ref(true);

    const reloadCandidate = async (option: string) => {
      await store.dispatch("getJobCandidatesState", {
        jobId: route.params.id,
        filters: {},
      });

      if (option === "edit" || option === "changeState") {
        reloadCandidateState();
      } else if (option === "delete") {
        router.push({ name: "Job Show", params: { id: route.params.id } });
      }
    };

    const reloadCandidateState = () => {
      store.dispatch("getCandidateState", {
        jobId: route.params.id,
        userId: route.params.job_candidate_id,
      });
    };

    const onShowInfo = () => {
      formOption.value = false;
      reloadCandidate("edit");
    };

    onMounted(() => {
      reloadCandidateState();
      if (!route.params.tab) {
        router.replace({ params: { tab: "Timeline" } });
      }
    });

    watch(
      () => route.params.job_candidate_id,
      () => {
        // reseting the candidate to show loading otherwise the id persists
        if (route.name === "JobCandidates") {
          store.commit("RESET_CANDIDATE");
          reloadCandidateState();
        }
      }
    );

    return {
      candidate,
      formOption,
      reloadCandidate,
      setShowDelete,
      showDelete,
      reloadCandidateState,
      isLoading,
      onShowInfo,
    };
  },
});
export default JobCandidateShow;
