import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "space-y-1 pt-3" }
const _hoisted_2 = { class: "sm:flex sm:justify-center items-center sm:w-max select-none" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (tab, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("li", {
            class: _normalizeClass(["cursor-pointer py-1 px-4 transition text-black font-medium border-b-2 ml-4", 
            _ctx.modelValue !== index
              ? ' text-black opacity-30 border-gray-600'
              : 'border-purple-700'
          ]),
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', index))
          }, _toDisplayString(tab), 11, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}