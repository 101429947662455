
import { defineComponent } from "vue";
import Avatar from "../avatar/index.vue";
import { buildColorStep } from "../../utils/functions";

const JobCandidateCard = defineComponent({
  name: "JobCandidateCard",
  emits: ["clickCard"],
  components: {
    Avatar,
  },
  props: {
    candidate: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selected: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const clickCard = () => context.emit("clickCard");

    const uploadedBy = (application: string, staff: any) => {
      let message;
      if (application) {
        switch (application) {
          case "jse":
            message = `Applied through <strong>Job Search Engine</strong>`;
            break;
          case "careers":
            message = `Applied through <strong>Company Job Board</strong>`;
            break;
          case "ai_sourcing":
            message = `Uploaded by <strong>Axelerate AI</strong>`;
            break;
        }
        return message;
      } else {
        return staff
          ? `Uploaded by <strong> ${staff.first_name} ${staff.last_name}</strong>`
          : "Uploaded by <strong>HR Personal</strong>";
      }
    };

    return { buildColorStep, clickCard, uploadedBy };
  },
});
export default JobCandidateCard;
