<template>
  <FieldArray name="evaluation_categories" v-slot="{ fields }">
    <div
      class="flex flex-col py-2 mb-3"
      v-for="(field, idx) in fields"
      :key="field.key"
    >
      <div class="flex flex-row space-x-4 w-full bg-primary px-3 rounded-t-2xl">
        <div class="w-full py-3">
          <Field
            as="textarea"
            disabled
            class="bg-primary text-white w-full border-gray-400 text-sm resize-none"
            :name="`evaluation_categories[${idx}].name`"
            :rows="2"
          />
        </div>
      </div>

      <AnswerArrayField
        :name="`evaluation_categories[${idx}].evaluation_answers`"
        :errors="errors"
        :isAnswered="isAnswered"
      />
      <hr />
    </div>
  </FieldArray>
</template>

<script>
import { defineComponent } from "vue";
import { FieldArray, Field } from "vee-validate";
import AnswerArrayField from "../answersArrayField/index.vue";
import { useState } from "../../composables/useState";

const CategoryAnswerArrayField = defineComponent({
  name: "CategoryAnswerArrayField",
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isAnswered: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FieldArray,
    Field,
    AnswerArrayField,
  },
  setup(props) {
    const [expand, setExpand] = useState(false);
    const [current, setCurrent] = useState(false);
    const existError =
      props.errors &&
      props.errors.categories &&
      Object.keys(props.errors.categories);
    const clickExpand = (index) => {
      setCurrent(index);
    };
    return { expand, setExpand, clickExpand, current, existError };
  },
});

export default CategoryAnswerArrayField;
</script>
