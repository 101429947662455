
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, ref } from "vue";
import Header from "@/components/header/index.vue";
import Button from "@/components/button/index.vue";
import CandidateForm from "@/components/addCandidateForm/index.vue";
import { useRoute } from "vue-router";

const CreateCandidate = defineComponent({
  name: "Create Candidate",
  components: {
    Button,
    Header,
    CandidateForm,
  },
  setup() {
    const saveCandidate = ref(false);
    const isValidForm = ref(false);
    const isLoadingButton = ref(false);
    const route = useRoute();
    const jobId = computed(() => route?.params?.jobId);
    const typeForm = jobId.value ? "createJobCandidate" : "createCandidate";

    const onSaveCandidate = () => {
      saveCandidate.value = true;
    };

    const onError = () => {
      saveCandidate.value = false;
    };
    const onValidForm = (validForm: boolean) => {
      isValidForm.value = validForm;
    };

    const isLoadingAction = (state: boolean) => {
      isLoadingButton.value = state;
    };

    return {
      isValidForm,
      saveCandidate,
      onSaveCandidate,
      isLoadingButton,
      isLoadingAction,
      onValidForm,
      onError,
      jobId,
      typeForm,
    };
  },
});

export default CreateCandidate;
