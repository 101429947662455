
import { defineComponent } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default defineComponent({
  name: "Currency Field",
  props: {
    modelValue: Number,
  },
  setup() {
    const { inputRef } = useCurrencyInput({
      currency: "USD",
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
    });

    return { inputRef };
  },
});
