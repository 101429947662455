import { Module } from "vuex";
import { RootState } from "../types";
import { ClientsState } from "./types";
import { getters } from "./getter";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: ClientsState = {
  refresh: false,
};

export const clients: Module<ClientsState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
