
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";
import Datepicker from "vue3-date-time-picker";
import "./styles.css";

const SimpleDatePicker = defineComponent({
  name: "SimpleDatePicker",
  emits: ["update:modelValue"],
  inheritAttrs: false,
  components: {
    Datepicker,
  },
  props: {
    modelValue: {
      type: [String, Date],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup() {
    const format = (date: any) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    return {
      format,
    };
  },
});
export default SimpleDatePicker;
