
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import CheckBoxGroup from "../checkboxGroup/index.vue";
import { JobsAPI } from "../../services/jobs/index";
import Loading from "vue-loading-overlay";
import { useStore } from "vuex";
import { createToastAxelerate } from "../../utils/toast";
import "vue-loading-overlay/dist/vue-loading.css";

const WorkFlowFields = defineComponent({
  name: "WorkFlowFields",
  emits: ["closeWorkflow"],
  components: {
    CheckBoxGroup,
    Loading,
  },
  props: {
    countSubmit: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    const store = useStore();
    const stagesRequired = ref([]);
    const stagesNotRequired = ref([]);
    const isLoading = ref(false);
    const stages = computed(() => store.state.jobStages.data);
    const stageValues: any = ref([...stages.value]);
    const firstJoin = ["Talent Pool*", "Screening*"];
    const secondJoin = ["Technical Interview*", "2nd Interview*"];
    const thirdJoin = ["Offer*", "Hired*"];
    onBeforeMount(() => {
      isLoading.value = true;
      JobsAPI.getJobStages()
        .then((res: any) => {
          isLoading.value = false;
          stagesRequired.value = res?.data?.stages.filter(
            (item: any) => item?.is_required === false
          );
          stagesNotRequired.value = res?.data?.stages.filter(
            (item: any) => item?.is_required === true
          );
        })
        .catch(() =>
          createToastAxelerate(
            "Job Stages",
            "an error occurred while getting the stages",
            "danger"
          )
        );
    });
    const saveWorkflow = () => {
      const unique = [...new Set(stageValues.value)];
      store.commit("JOB_STAGES_FULLFILED", unique);
      context.emit("closeWorkflow");
    };
    return {
      saveWorkflow,
      stageValues,
      stagesRequired,
      isLoading,
      stages,
      firstJoin,
      secondJoin,
      thirdJoin,
    };
  },
});

export default WorkFlowFields;
