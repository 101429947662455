
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, onMounted, ref, Ref } from "vue";
import SelectDropdown from "@/components/selectDropdown/index.vue";
import TextArea from "@/components/textArea/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import CurrencyFieldSimple from "@/components/currencyFieldSimple/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { offboardTalent, getCauses } from "@/services/talents";
import { getPayrollPeriods } from "@/services/payroll";
import { createExtraCharge } from "@/services/expenses";

export default defineComponent({
  name: "Offboarding Modal",
  components: {
    SelectDropdown,
    TextArea,
    DatePicker,
    CurrencyFieldSimple,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    talent_id: {
      type: String,
      default: "",
    },
    company_id: {
      type: String,
      default: "",
    },
    talent_name: {
      type: String,
      default: "",
    },
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    const schema = yup.object({
      cause: yup.string().required().label("Cause"),
      dismissal_date: yup.date().required().nullable().label("Dismissal date"),
      justification: yup.string().required().label("Justification"),
      amount: yup.number().required().min(0).label("Amount"),
      currency: yup.string().required().label("Currency"),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        cause: "",
        dismissal_date: null,
        justification: "",
        amount: 0,
        currency: "mxn",
      },
    });

    const onSubmit = handleSubmit((values) => {
      let dismissal = values.dismissal_date?.toISOString().substr(0, 10);
      let submitAmount = 0;
      if (values.amount) {
        submitAmount = values.amount * 100;
      }
      const body = {
        cause: values.cause,
        dismissal_date: dismissal,
        justification: values.justification,
      };
      const extraChargeBody = {
        extra_charge: {
          title: "Severance for " + props.talent_name,
          description: values.justification,
          amount: submitAmount,
          currency: values.currency,
        },
      };
      terminateTalent(body, extraChargeBody);
    }, onInvalidSubmit);

    function onInvalidSubmit({ errors }: any) {
      //
    }

    const { value: cause } = useField("cause");
    const { value: dismissal_date } = useField("dismissal_date");
    const { value: justification } = useField("justification");
    const { value: amount } = useField("amount");
    const { value: currency } = useField("currency");
    const loading: Ref<boolean> = ref(false);
    const causes: Ref<Array<any>> = ref([]);
    const canSubmit = computed(
      () =>
        cause.value === "" ||
        dismissal_date.value === "" ||
        justification.value === ""
    );
    const minDate: Ref<any> = ref(null);
    const currencyOptions: Ref<Array<any>> = ref([
      { id: "mxn", name: "Mexican Peso (MXN)" },
      { id: "usd", name: "US Dollar (USD)" },
    ]);

    async function terminateTalent(body: any, extraChargeBody: any) {
      loading.value = true;
      const data = await offboardTalent(props.talent_id, body);
      if (data) {
        if (amount.value === 0 || !amount.value) {
          closeModal();
          window.location.reload();
        } else {
          createSeverance(extraChargeBody);
        }
      }
      loading.value = false;
    }

    async function createSeverance(body: any) {
      const data = await createExtraCharge(props.company_id, body);
      if (data) {
        closeModal();
        window.location.reload();
      }
    }

    async function getTerminationCauses() {
      causes.value = [];
      const data = await getCauses();
      if (data) {
        for (const key in data) {
          causes.value.push({
            id: key,
            name: data[key],
          });
        }
      }
    }

    async function getMinimumDate() {
      const data = await getPayrollPeriods();
      if (data) {
        minDate.value = new Date(
          new Date(data[0].start_date).setDate(
            new Date(data[0].start_date).getDate() + 1
          )
        );
      }
    }

    function closeModal() {
      emit("update:openModal", false);
    }

    onMounted(() => {
      getTerminationCauses();
      getMinimumDate();
    });

    return {
      loading,
      closeModal,
      causes,
      cause,
      dismissal_date,
      justification,
      amount,
      currency,
      currencyOptions,
      onSubmit,
      canSubmit,
      minDate,
    };
  },
});
