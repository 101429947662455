<template>
  <VueTribute :options="options">
    <ContentEditable
      tag="div"
      class="w-full h-40 pt-3 px-3 pb-6 sticky-div focus:outline-transparent focus:ring-black rounded-lg"
      :placeholder="placeholder"
      :contenteditable="true"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event ? $event : '')"
      :noNL="false"
      :noHTML="true"
    />
  </VueTribute>
</template>
<script>
import { VueTribute } from "vue-tribute";
import ContentEditable from "vue-contenteditable";
import { ref, defineComponent } from "vue";

const Mentionable = defineComponent({
  name: "Mentionable",
  inheritAttrs: false,
  emits: ["update:modelValue"],
  components: {
    VueTribute,
    ContentEditable,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },
  setup(props) {
    const menuContainer = ref(null);
    const options = ref({
      collection: [
        {
          trigger: "@",
          values: props.users,
          positionMenu: false,
          menuContainer: document.body,
          selectTemplate: (item) => {
            return `<span contenteditable="false" class="mention font-semibold">${item.original.key} <span  class="none">(${item.original.value})</span>
            </span>`;
          },
          noMatchTemplate: () => {
            return "<li>No matches found</li>";
          },
        },
      ],
    });
    return {
      options,
      menuContainer,
    };
  },
});
export default Mentionable;
</script>

<style sccoped>
.mention {
  color: #8908f9;
  background: transparent;
  padding: 0 4px;
}

.hash {
  color: #e70fb8;
  background: transparent;
  padding: 0 4px;
}

.menu-container {
  color: #fff;
  position: relative;
  margin: 100px auto 0;
  padding: 20px;
  background-color: #6638f0;
  width: 400px;
}

.tribute-container {
  position: absolute;
  top: 100%;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(#000, 0.13);
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #ffff;
  border-radius: 4px;
  border: 1px solid rgba(#000, 0.13);
  background-clip: padding-box;
  overflow: hidden;
}
.tribute-container li {
  color: #6638f0;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}
.tribute-container li.highlight,
.tribute-container li:hover {
  background: #6638f0;
  color: #ffff;
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}

.sticky-div {
  padding: 0.3em 0.5em 0 0.5em;
  text-align: left;
  overflow-y: auto;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: relative;
  color: gray;
}
[contenteditable]:focus {
  background-color: transparent;
}
[contenteditable] {
  background-color: #eff0f6;
}

.none {
  border: solid 1px;
  width: 0px;
  height: 0px;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
  color: transparent;
}
</style>
