/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { instanceLoggedAPI } from "../axiosConfig";

export async function getOnboardingList(user_id: string) {
  try {
    const { data } = await instanceLoggedAPI.get(`/users/${user_id}/tasks`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function addOnboardingTask(description: string, user_id: string) {
  const body = {
    task: {
      description: description,
    },
  };
  try {
    const { data } = await instanceLoggedAPI.post(
      `/users/${user_id}/tasks`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function changeOnboardingTaskStatus(
  description: string,
  completed: boolean,
  task_id: string,
  user_id: string
) {
  const body = {
    description: description,
    completed: completed,
  };
  try {
    const { data } = await instanceLoggedAPI.put(
      `/users/${user_id}/tasks/${task_id}`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function finishOnboardingProcess(user_id: string) {
  try {
    const data = await instanceLoggedAPI.post(
      `/talents/${user_id}/finish_onboarding_process`
    );
    return data;
  } catch (err) {
    return null;
  }
}
