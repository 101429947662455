<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-col bg-white shadow rounded-2xl px-5 py-3">
      <div class="flex flex-row justify-between">
        <span class="text-lg font-semibold py-2">My Calendar</span>
        <button
          v-if="!isCalendarLinked"
          @click="connectCalendar"
          class="btn-text"
        >
          <div class="flex flex-row">
            <span class="mr-4">Connect my calendar</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
        </button>
        <button v-else @click="viewSchedulingPages" class="btn-text">
          <div class="flex flex-row">
            <span class="mr-4">Scheduling pages</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          </div>
        </button>
      </div>
      <div
        class="flex flex-row justify-end w-full space-x-3 items-center mt-4 mb-2"
      >
        <span class="text-gray-400 text-tiny font-light">Status: </span>
        <span
          class="text-sm font-light px-2 py-1 rounded"
          :class="
            isCalendarLinked
              ? 'bg-green-100 text-green-500'
              : 'bg-red-200 text-red-500'
          "
        >
          {{ isCalendarLinked ? "Connected" : "Not connected" }}
        </span>
      </div>
    </div>
    <div
      v-if="isCalendarLinked"
      class="flex flex-col bg-white shadow rounded-2xl px-5 py-3 mt-4"
    >
      <div class="flex flex-row items-center justify-between">
        <span class="text-lg font-semibold py-2">Microsoft Teams</span>
        <button v-if="!teams.is_linked" @click="getLinkedURL" class="btn-text">
          <div class="flex flex-row items-center">
            <span class="mr-4">Link teams</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
        </button>
      </div>
      <div
        class="flex flex-row justify-end w-full space-x-3 items-center mt-4 mb-2"
      >
        <span class="text-gray-400 text-tiny font-light">
          Linked to teams:
        </span>
        <span
          class="text-sm font-light px-2 py-1 rounded"
          :class="
            teams.is_linked
              ? 'bg-green-100 text-green-500'
              : 'bg-red-200 text-red-500'
          "
        >
          {{ teams.is_linked ? "Connected" : "Not connected" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { loadScript } from "vue-plugin-load-script";
import { useRoute } from "vue-router";

import { createToastAxelerate } from "../../utils/toast";
import { nylasAPI } from "../../services/nylas/index";

const CalendarDetails = defineComponent({
  name: "CalendarDetails",
  setup() {
    const teams = ref({
      is_linked: false,
      acccount_id: null,
    });
    const route = useRoute(),
      store = useStore(),
      nylasAccessToken = computed(() => store.state.nylas.accessToken),
      isCalendarLinked = computed(() => store.state.nylas.isCalendarLinked),
      connectCalendar = () => {
        const oauthURL = new URL("https://api.nylas.com/oauth/authorize"),
          queryParams = oauthURL.searchParams;

        queryParams.set("client_id", process.env.VUE_APP_NYLAS_CLIENT_ID);
        queryParams.set(
          "redirect_uri",
          process.env.VUE_APP_NYLAS_AUTHORIZATION_REDIRECT_URL
        );
        queryParams.set("response_type", "token");

        oauthURL.search = queryParams.toString();
        window.location.href = oauthURL.toString();
      },
      viewSchedulingPages = async () => {
        await loadScript(
          "https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js"
        );

        window.nylas.scheduler.show({
          auth: {
            accessToken: store.state.nylas.accessToken,
          },
          style: {
            tintColor: "#32325d",
            backgroundColor: "white",
          },
          defaults: {
            event: {
              title: "Screening with Axented",
              duration: 30,
            },
          },
        });
      };

    async function getLinkedStatus() {
      const data = await nylasAPI.getLinkStatus();
      teams.value = data.data.teams;
    }

    async function linkTeams(body) {
      nylasAPI.saveTeamsInfo(body).then(() => {
        store.state.nylas.isTeamsLinked = true;
      });
    }

    async function getLinkedURL() {
      const data = await nylasAPI.getLinkUrl();
      window.open(data.data.url);
    }

    onBeforeMount(() => {
      const urlNylasAccessToken = route.query.access_token;
      const provider = route.query.provider;

      if (urlNylasAccessToken) {
        /** TODO(Rodolfo Islas): Delete sensitive data from URL */
        const payload = {
          nylas: {
            access_token: urlNylasAccessToken,
            provider: provider,
          },
        };

        /** TODO(Rodolfo Islas): Change to use store.dispatch() */
        nylasAPI
          .registerNylas(payload)
          .then(() => {
            store.state.nylas.accessToken = urlNylasAccessToken;
            store.state.nylas.isCalendarLinked = true;
          })
          .catch(() => {
            createToastAxelerate(
              "Link calendar",
              "An error has ocurred",
              "danger"
            );
          });
      } else if (provider) {
        const isLinked = route.query.success;
        const grant = route.query.grant_id;
        teams.value.is_linked = isLinked;
        teams.value.account_id = grant;
        const body = {
          teams: teams.value,
        };
        linkTeams(body);
      }
    });

    onMounted(() => {
      const urlNylasAccessToken = route.query.access_token;
      getLinkedStatus();
      if (!urlNylasAccessToken) {
        store.dispatch("retrieveAccessToken", {});
      }
    });

    return {
      connectCalendar,
      isCalendarLinked,
      nylasAccessToken,
      viewSchedulingPages,
      getLinkedURL,
      teams,
      linkTeams,
    };
  },
});

export default CalendarDetails;
</script>
