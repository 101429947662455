
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useForm } from "vee-validate";
import Button from "../button/index.vue";
import * as yup from "yup";
import PdfView from "../pdfViewer/index.vue";
import CategoryAnswerArrayField from "../categoryAnswersFieldArray/index.vue";
import WarningModal from "../warningModal/index.vue";
import ReusableModal from "../reusableModal/index.vue";
import { TalentEvaluationAPI } from "../../services/talentEvaluations/index";
import { useStore } from "vuex";
import Loader from "@/components/loader/index.vue";
import ScoreBar from "../progressBarScore/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import { useState } from "../../composables/useState";

const EvaluationsAnswerForm = defineComponent({
  name: "EvaluationsAnswerForm",
  components: {
    CategoryAnswerArrayField,
    Button,
    ScoreBar,
    WarningModal,
    ReusableModal,
    Loader,
    PdfView,
  },
  setup(props, context) {
    const store = useStore();
    const [showModal, setShowModal] = useState(false);
    const candidateId = computed(() => store.state.candidateInfo?.data.id);
    const currentStage = computed(
      () => store.state.candidateInfo?.data.current_stage
    );
    const score = computed(() => store.state.answers.score);
    const title = computed(() => store.state.answers.title);
    const isAnswered = computed(() => store.state.answers.isAnswered);
    const isEditing = ref(false);
    const [file, setFile] = useState("");
    const [preSave, setPreSave] = useState(true);
    const tabs = ["current", "previous"];
    let latestEvaluation: any;
    const message = ref("");
    const latestEvaluationAnswered = ref(false);
    const talentEvaluation = ref({});
    const question_answered = ref(0);
    const activeTab = ref(0);
    const loading = ref(false);
    const schema = yup.object({
      evaluation_categories: yup.array().of(
        yup.object().shape({
          name: yup.string().nullable().label("Category title"),
          evaluation_answers: yup.array().of(
            yup.object().shape({
              question_content: yup.string().nullable().label("Question"),
              answer_value: yup
                .string()
                .required("required")
                .label("Answer")
                .nullable(),
              question_id: yup.string().nullable().label("Id"),
            })
          ),
        })
      ),
    });
    const { handleSubmit, values, meta, errors, setValues } = useForm({
      validationSchema: schema,
    });
    const submitData = () => context.emit("submitData");
    const onSubmit = handleSubmit((values: any) => {
      question_answered.value = 0;

      const payload = {
        categories: values.evaluation_categories,
        is_finished: preSave.value,
      };
      talentEvaluation.value = payload;
      getAnswers(values);
      if (question_answered.value > 0) {
        if (preSave.value) {
          message.value = `There are ${question_answered.value} question(s) not answered, are you sure you want to submit this evaluation result to the client?`;
        } else {
          message.value = "Are you sure you want to save this evaluation?";
        }
      } else {
        if (preSave.value) {
          message.value =
            "Are you sure you want to submit this evaluation result to the client?";
        } else {
          message.value = "Are you sure you want to save this evaluation?";
        }
      }
      setShowModal(true);
    });

    async function getAnswerTalent() {
      await setValues({ evaluation_categories: [] });
      loading.value = true;
      if (activeTab.value === 0) {
        TalentEvaluationAPI.getAswersByTalent(candidateId.value).then(
          async (res: any) => {
            const { data } = res;
            latestEvaluationAnswered.value = data.latest_evaluation_answered
              ? true
              : false;
            latestEvaluation = data.latest_evaluation_answered;
            store.commit("SCORE_FULLFILED", data.global_score);
            store.commit("TITLE_FULLFILED", data.evaluation_title);
            store.commit("ANSWERED_FULLFILED", data.evaluation_answered);
            const cleanArray = data?.evaluation_categories?.map((item: any) => {
              delete item.global_score;
              delete item.id;
              item &&
                item?.evaluation_answers.map((ans: any) => {
                  return ans && ans.answer_value === null
                    ? (ans.answer_value = "na")
                    : ans.answer_value;
                });
              return item;
            });
            await setValues({
              evaluation_categories: cleanArray,
            });
            loading.value = false;
          }
        );
      } else {
        await setValues({ evaluation_categories: [] });
        store.commit("SCORE_FULLFILED", latestEvaluation.global_score);
        store.commit("TITLE_FULLFILED", latestEvaluation.evaluation_title);
        store.commit("ANSWERED_FULLFILED", true);
        const cleanArray = latestEvaluation.evaluation_categories?.map(
          (item: any) => {
            delete item.global_score;
            delete item.id;
            item &&
              item?.evaluation_answers.map((ans: any) => {
                return ans && ans.answer_value === null
                  ? (ans.answer_value = "na")
                  : ans.answer_value;
              });
            return item;
          }
        );
        await setValues({
          evaluation_categories: cleanArray,
        });
        loading.value = false;
      }
    }

    function editForm() {
      isEditing.value = !isEditing.value;
      if (!isEditing.value) {
        getAnswerTalent();
      }
    }

    function updateAnswerQuestions() {
      setShowModal(false);
      TalentEvaluationAPI.updateAswersByTalent(
        candidateId.value,
        talentEvaluation.value
      )
        .then((res: any) => {
          const { data } = res;
          setShowModal(false);
          store.commit("SCORE_FULLFILED", data.global_score);
          store.commit("ANSWERED_FULLFILED", data.evaluation_answered);
          window.scrollTo(0, 0);
          isEditing.value = false;
          createToastAxelerate(
            "Talent Evaluation",
            "The answers has been saved",
            "success"
          );
        })
        .catch(() => {
          setShowModal(false);
          createToastAxelerate(
            "Talent Evaluation",
            "An error has ocurred",
            "danger"
          );
        });
    }

    function getAnswers(categories: any) {
      categories.evaluation_categories.map((value: any) => {
        if (value.evaluation_answers.length > 0) {
          value.evaluation_answers.map((answer: any) => {
            if (answer.answer_value === "na") {
              question_answered.value++;
            }
          });
        }
      });
    }

    function saveChanges() {
      updateAnswerQuestions();
    }

    onMounted(() => {
      getAnswerTalent();
    });

    watch(activeTab, () => {
      getAnswerTalent();
    });

    watchEffect(() => {
      if (candidateId.value) {
        setFile(store.state.candidateInfo.data?.candidate.resume?.url);
      }
    });

    return {
      onSubmit,
      values,
      loading,
      getAnswerTalent,
      meta,
      submitData,
      errors,
      candidateId,
      score,
      title,
      isAnswered,
      setShowModal,
      showModal,
      tabs,
      activeTab,
      latestEvaluation,
      latestEvaluationAnswered,
      file,
      question_answered,
      editForm,
      updateAnswerQuestions,
      currentStage,
      isEditing,
      getAnswers,
      talentEvaluation,
      saveChanges,
      message,
      setPreSave,
      preSave,
    };
  },
});
export default EvaluationsAnswerForm;
