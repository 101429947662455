
import { computed, defineComponent, onMounted, PropType, ref, Ref } from "vue";
//import TextField from "@/components/textField/index.vue";
import { useField } from "vee-validate";
import * as yup from "yup";
import { CompanyAPI } from "@/services/companies";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Payment Contacts",
  /*components: {
    TextField,
  },*/
  props: {
    company_id: {
      type: String,
      default: "",
    },
    payment_contacts: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    default_contact: {
      type: Object as PropType<any>,
      default: () => ({
        email: "",
        show_email: false,
      }),
    },
    default_email: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const loadingSave: Ref<boolean> = ref(false);
    const updateField: Ref<number> = ref(0);
    const contacts: Ref<Array<string>> = ref([]);
    const {
      value: email,
      errorMessage,
      handleChange,
    } = useField("email", yup.string().email(), {
      validateOnValueUpdate: false,
    });
    const isError: Ref<boolean> = computed(
      () => errorMessage.value !== undefined
    );

    const validationListeners = computed(() => {
      // If the field is valid or have not been validated yet
      // lazy
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          // disable `shouldValidate` to avoid validating on input
          input: (e: any) => handleChange(e, false),
        };
      }

      // Aggressive
      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange, // only switched this
      };
    });

    function removeContact(index: number) {
      contacts.value.splice(index, 1);
      saveChanges();
      updateField.value++;
    }

    function addMail() {
      contacts.value.push(email.value as string);
      email.value = "";
      saveChanges();
      updateField.value++;
    }

    async function saveChanges() {
      loadingSave.value = true;
      const body = {
        company: {
          payment_contacts: contacts.value,
        },
      };
      const data = await CompanyAPI.savePaymentContacts(props.company_id, body);
      if (data) {
        createToastAxelerate("Contacts saved", "", "success");
      } else {
        createToastAxelerate(
          "Error saving changes",
          "Please try again",
          "danger"
        );
      }
      loadingSave.value = false;
    }

    onMounted(() => {
      email.value = "";
      contacts.value = props.payment_contacts;
    });

    return {
      loadingSave,
      removeContact,
      email,
      addMail,
      updateField,
      errorMessage,
      saveChanges,
      contacts,
      isError,
      validationListeners,
      handleChange,
    };
  },
});
