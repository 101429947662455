
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";

const DropDown = defineComponent({
  name: "DropDown",
  inheritAttrs: false,
  emits: ["update:modelValue"],
  components: {
    ErrorMessage,
    Field,
  },
  props: {
    typeInput: {
      type: String,
      default: "text",
    },
    value: {
      type: String || Number,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    rule: {
      type: String,
      default: "",
    },
    helperErrorText: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        [{ id: 1, name: "Mexico" }];
      },
    },
    className: {
      type: String,
      default:
        "pt-5 pb-2 pl-4 pr-10 h-16 block w-full px-0 mt-0 bg-transparent border-2 rounded-lg border-solid appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200 custom-select disabled:cursor-not-allowed",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
});
export default DropDown;
