
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import * as yup from "yup";
import { JobsAPI } from "@/services/jobs/index";
import Chips from "@/components/chips/index.vue";
import { useField, useForm } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import Button from "@/components/button/index.vue";
import { createToastAxelerate } from "@/utils/toast";
import TextArea from "@/components/textArea/index.vue";
import TextField from "@/components/textField/index.vue";
import { optionCurrencies } from "@/constants/candidates";
import { CandidatesAPI } from "@/services/candidates/index";
import DropDown from "@/components/selectDropdown/index.vue";
import DragAndDropFile from "@/components/dragFile/index.vue";
import SwitchButton from "@/components/switchButton/index.vue";
import CheckBoxGroup from "@/components/checkboxGroup/index.vue";
import CurrencyField from "@/components/currencyField/index.vue";
import EducationArrayField from "@/components/educationFieldArray/index.vue";
import WorkExperienceArrayField from "@/components/workExperienceFieldArray/index.vue";
import { usePromisedModal } from "../../composables/usePromisedModal";
import BaseModal from "@/components/baseModal/index.vue";
import AutocompleteLocation from "../../components/autocompleteLocation/index.vue";
import CheckBox from "../simpleCheckbox/index.vue";
import { numberFormattedWithCommas } from "@/utils/functions";

const AddCandidateForm = defineComponent({
  name: "AddCandidateForm",
  emits: [
    "saveCandidate",
    "onError",
    "onValidForm",
    "isLoadingAction",
    "onShowInfo",
  ],
  components: {
    Chips,
    Button,
    DropDown,
    TextArea,
    TextField,
    SwitchButton,
    CheckBoxGroup,
    CurrencyField,
    DragAndDropFile,
    EducationArrayField,
    WorkExperienceArrayField,
    BaseModal,
    AutocompleteLocation,
    CheckBox,
  },
  props: {
    saveCandidate: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: () => Object,
    },
    typeForm: {
      type: String,
      default: "",
    },
    jobIdCandidate: {
      type: String,
      default: "",
    },
    jobCandidateId: {
      type: String,
      default: "",
    },
    toggle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const skills = ref([]);
    const router = useRouter();
    const route = useRoute();
    const preferred_work_types = ref([]);
    const preferred_work_types_Aux = ref();
    const cvFile = ref();
    const files: any = ref([]);
    const candidateDocuments: any = ref([]);
    const filesToDelete: any = ref([]);
    const otherFile = ref();
    const cvLoaded = ref(false);
    const validForm = ref(false);
    const isLoadingButton = ref(false);
    const textButton = ref("");
    const textButtonSaving = ref("");
    const jobId = computed(() => route?.params?.jobId);
    const companyCandidateId = computed(() => props.candidateInfo.id);
    const isVisible = ref(false);
    const overrideCandidate = ref(false);
    const industries = ref();
    const cvFileAux = ref();
    const otherFileAux = ref();
    const showConfirmationModal = ref(false);
    const locationName = ref("");
    const place_id = ref("");
    const salaryMonthly = ref(false);
    const currentSalaryCurrency = ref();
    const salaryCurrency = ref();
    const confirmationModal = usePromisedModal<boolean>();
    const workTypes = [
      { label: "Remote", value: "remote" },
      { label: "Hybrid", value: "hybrid" },
      { label: "On-site", value: "on_site" },
      { label: "Open to relocation", value: "open_to_relocation" },
    ];
    preferred_work_types_Aux.value = {
      remote: false,
      hybrid: false,
      on_site: false,
      open_to_relocation: false,
    };

    //YUP object constructor
    const schema = yup.object({
      first_name: yup.string().label("First Name").required(),
      last_name: yup.string().label("Last Name").required(),
      email: yup.string().label("Email").required().email(),
      phone: yup.string().label("Phone"),
      position: yup.string().label("Position").nullable(),
      candidate_work_histories: yup.array().of(
        yup.object().shape({
          start_date: yup.date().nullable().label("Start date"),
          end_date: yup.date().nullable().label("End date"),
          job_title: yup.string().nullable().label("Job title"),
          company_name: yup.string().label("Company").nullable(),
          is_current: yup.boolean().nullable(),
          industry: yup.string().nullable().label("Industry"),
        })
      ),
      candidate_education_histories: yup.array().of(
        yup.object().shape({
          start_date: yup.date().nullable().label("Start date").nullable(),
          end_date: yup.date().nullable().label("End date"),
          learning_center: yup.string().nullable().label("School"),
          degree: yup.string().nullable().label("Education"),
          is_current: yup.boolean().nullable(),
        })
      ),
      summary: yup.string().label("Summary").nullable(),
      link: yup.string().label("Link").nullable(),
      current_salary: yup.string().label("Current Salary").nullable(),
      salary_currency: yup.string().label("Currency Salary").nullable(),
      current_salary_currency: yup.string().label("Currency Salary").nullable(),
      salary_expectation: yup.string().label("Current Salary").nullable(),
    });

    //Variables
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: email } = useField("email");
    const { value: phone } = useField("phone");
    const { value: position } = useField("position");
    const { value: summary } = useField("summary");
    const { value: link } = useField("link");
    const { value: salaryExpectation } = useField(
      "salary_expectation",
      yup.string().required().label("Expectation").nullable(),
      {
        initialValue: "0",
      }
    );
    const { value: currentSalary } = useField(
      "current_salary",
      yup.string().required().label("Current").nullable(),
      {
        initialValue: "0",
      }
    );

    //Yup initilaization
    const { values, meta, handleSubmit, errors, validate, setValues } = useForm(
      {
        validationSchema: schema,
        initialValues: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          position: "",
          candidate_work_histories: [
            {
              start_date: null,
              end_date: null,
              job_title: "",
              company_name: "",
              industry: "",
              is_current: false,
            } as any,
          ],
          candidate_education_histories: [
            {
              start_date: null,
              end_date: null,
              degree: "",
              learning_center: "",
              is_current: false,
            } as any,
          ],
          summary: "",
          link: "",
          current_salary: null,
          salary_expectation: null,
          salary_currency: "",
          current_salary_currency: "",
        },
      }
    );

    const onSubmit = handleSubmit(async (values: any) => {
      isLoadingButton.value = true;
      context.emit("isLoadingAction", true);
      let arrayPromise: any = [];
      if (filesToDelete.value.length > 0) {
        filesToDelete.value.map((file: string) => {
          arrayPromise.push(JobsAPI.deleteFile(file));
        });

        await Promise.all(arrayPromise);
      }
      //Filter valid array
      const workHistories = values.candidate_work_histories.filter(function (
        element: any
      ) {
        return element.job_title !== "";
      });
      //Filter valid array
      const educationHistories = values.candidate_education_histories.filter(
        function (element: any) {
          return element.degree !== "";
        }
      );
      //Filter worktype

      if (preferred_work_types.value.length > 0) {
        preferred_work_types.value.map((item) => {
          Object.keys(preferred_work_types_Aux.value).forEach((data) => {
            if (item && item === data) {
              preferred_work_types_Aux.value[data] = true;
            }
          });
        });
      } else {
        preferred_work_types_Aux.value = {
          remote: false,
          hybrid: false,
          on_site: false,
          open_to_relocation: false,
        };
      }
      //Format current salary
      values.show_monthly_salary = salaryMonthly.value;
      values.current_salary = values.current_salary?.replace(/,/g, "") || "";
      values.salary_expectation =
        values.salary_expectation?.replace(/,/g, "") || "";
      //Adding files to current candidate data
      values.resume = cvFile.value;
      values.documents = otherFile.value;
      values.place_id = place_id.value;

      //Object to update candidate info
      const candidateUpdate = {
        candidate: {
          preferred_work_type: preferred_work_types_Aux.value,
          candidate_education_histories: educationHistories,
          candidate_work_histories: workHistories,
          skills: skills.value,
          place_id: place_id.value,
        },
      };
      const JobCandidateUpdate = {
        job_candidate: {
          preferred_work_type: preferred_work_types_Aux.value,
          candidate_education_histories: educationHistories,
          candidate_work_histories: workHistories,
          skills: skills.value,
          override_candidate: overrideCandidate.value,
          is_visible: isVisible.value,
          place_id: place_id.value,
        },
      };

      switch (props.typeForm) {
        case "createCandidate":
          createCandidate(values, candidateUpdate);

          break;
        case "createJobCandidate":
          values.is_visible = isVisible.value;
          createJobCandidate(values, JobCandidateUpdate);

          break;
        case "editCandidate":
          updateCandidate(values, candidateUpdate);
          break;

        case "editJobCandidate":
          values.is_visible = isVisible.value;
          values.override_candidate = overrideCandidate.value;
          updateCandidateJob(values, JobCandidateUpdate);
          break;
      }
    });

    const createCandidate = (createData: any, updateData: any) => {
      updateData.candidate.last_edit = false;
      CandidatesAPI.createCandidate(createData)
        .then((response: any) => {
          let candidateId = response.data.id;
          CandidatesAPI.updateCandidateHistories(candidateId, updateData)
            .then((response) => {
              isLoadingButton.value = false;
              context.emit("isLoadingAction", false);
              router.push("/candidates");
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate(
                "Create candidate",
                errors && errors[0] ? errors[0] : "An error has ocurred",
                "danger"
              );
            });
        })
        .catch((err) => {
          isLoadingButton.value = false;
          context.emit("isLoadingAction", false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create candidate",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const updateHandler = (data: string) => {
      place_id.value = data;
    };

    const updateCandidate = (updateData: any, updateHistoriesData: any) => {
      updateHistoriesData.candidate.last_edit = true;
      CandidatesAPI.updateCandidate(props.candidateInfo.id, updateData)
        .then((response: any) => {
          CandidatesAPI.updateCandidateHistories(
            props.candidateInfo.id,
            updateHistoriesData
          )
            .then((response) => {
              isLoadingButton.value = false;
              context.emit("isLoadingAction", false);
              context.emit("onShowInfo");
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate(
                "Create candidate",
                errors && errors[0] ? errors[0] : "An error has ocurred",
                "danger"
              );
            });
        })
        .catch((err) => {
          isLoadingButton.value = false;
          context.emit("isLoadingAction", false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create candidate",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const createJobCandidate = (createData: any, updateData: any) => {
      updateData.job_candidate.last_edit = false;
      JobsAPI.postJobCandidate(createData, jobId.value.toString())
        .then((response: any) => {
          let candidateId = response.data.id;
          let companyCandidate = response.data.candidate.id;
          JobsAPI.updateJobCandidateHistory(
            jobId.value,
            candidateId,
            updateData
          )
            .then((response) => {
              if (files.value.length > 0) {
                JobsAPI.uploadFile(companyCandidate, files.value)
                  .then((response) => {
                    isLoadingButton.value = false;
                    context.emit("isLoadingAction", false);
                    router.back();
                  })
                  .catch((err) => {
                    const {
                      response: {
                        data: { errors },
                      },
                    } = err;
                    createToastAxelerate(
                      "Create candidate",
                      errors && errors[0] ? errors[0] : "An error has ocurred",
                      "danger"
                    );
                  });
              } else {
                isLoadingButton.value = false;
                context.emit("isLoadingAction", false);
                router.back();
              }
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate(
                "Create candidate",
                errors && errors[0] ? errors[0] : "An error has ocurred",
                "danger"
              );
            });
        })
        .catch((err) => {
          isLoadingButton.value = false;
          context.emit("isLoadingAction", false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create candidate",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const updateCandidateJob = async (
      jobCanddiateData: any,
      jobCandidateHistories: any
    ) => {
      jobCandidateHistories.job_candidate.last_edit = true;
      JobsAPI.updateJobCandidate(
        props.jobIdCandidate,
        props.jobCandidateId,
        jobCanddiateData
      )
        .then((response: any) => {
          JobsAPI.updateJobCandidateHistory(
            props.jobIdCandidate,
            props.jobCandidateId,
            jobCandidateHistories
          )
            .then((response) => {
              if (files.value.length > 0) {
                JobsAPI.uploadFile(companyCandidateId.value, files.value)
                  .then((response) => {
                    isLoadingButton.value = false;
                    context.emit("isLoadingAction", false);
                    context.emit("onShowInfo");
                  })
                  .catch((err) => {
                    const {
                      response: {
                        data: { errors },
                      },
                    } = err;
                    createToastAxelerate(
                      "Create candidate",
                      errors && errors[0] ? errors[0] : "An error has ocurred",
                      "danger"
                    );
                  });
              } else {
                isLoadingButton.value = false;
                context.emit("isLoadingAction", false);
                context.emit("onShowInfo");
              }
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate(
                "Create candidate",
                errors && errors[0] ? errors[0] : "An error has ocurred",
                "danger"
              );
            });
        })
        .catch((err) => {
          isLoadingButton.value = false;
          context.emit("isLoadingAction", false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Create candidate",
            errors && errors[0] ? errors[0] : "An error has ocurred",
            "danger"
          );
        });
    };

    const addFile = (event: any) => {
      files.value.push(event.target.files[0]);
    };

    watchEffect(() => {
      validForm.value =
        (cvLoaded.value || cvFileAux.value) && meta.value.valid ? true : false;
      context.emit("onValidForm", validForm.value);
    });

    watch(cvFile, (newVal) => {
      cvLoaded.value = newVal ? true : false;
      validForm.value = cvLoaded.value && meta.value.valid ? true : false;
      context.emit("onValidForm", validForm.value);
    });

    watch(overrideCandidate, async (value) => {
      if (value) {
        showConfirmationModal.value = true;
        const confirmed = await confirmationModal.ask();
        if (confirmed) {
          overrideCandidate.value = true;
        } else {
          overrideCandidate.value = false;
        }
        showConfirmationModal.value = false;
      }
    });

    watchEffect(() => {
      if (props.saveCandidate) {
        onSubmit();
        context.emit("onError");
      } else {
        context.emit("onError");
      }
    });

    const getFileName = (file: any) => {
      let auxFileName = file.substring(file.lastIndexOf("/") + 1);
      return auxFileName.substr(0, auxFileName.lastIndexOf("."));
    };

    const getFileExtension = (file: any) => {
      let auxFileName = file.substring(file.lastIndexOf("/") + 1);
      return auxFileName.split(".").pop();
    };

    const deleteFile = (index: number) => {
      files.value.splice(index, 1);
    };

    const getFileIcon = (type: string) => {
      switch (type) {
        case "pdf":
          return require("@/assets/svgs/pdf.png");
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
          return require("@/assets/svgs/img.png");
        case "xls":
        case "xlsx":
          return require("@/assets/svgs/xls.png");
        case "doc":
        case "docx":
          return require("@/assets/svgs/doc.png");
        default:
          return require("@/assets/svgs/file.png");
      }
    };

    const onCancelButton = () => {
      switch (props.typeForm) {
        case "createCandidate":
          router.back();

          break;
        case "createJobCandidate":
          router.back();

          break;
        case "editCandidate":
          context.emit("onShowInfo");
          break;

        case "editJobCandidate":
          context.emit("onShowInfo");
          break;
      }
    };

    onMounted(async () => {
      let aux: any = await JobsAPI.getCompanyIndustries();
      industries.value = aux.data;
      // set value from props

      if (
        props.typeForm === "editJobCandidate" ||
        props.typeForm === "editCandidate"
      ) {
        candidateDocuments.value = props.candidateInfo?.candidate_documents;
        locationName.value =
          props.candidateInfo.candidate_location?.location?.street_address;
        place_id.value =
          props.candidateInfo.candidate_location?.location?.place_id;
        isVisible.value = props.toggle;
        skills.value = props.candidateInfo.skills;
        cvFileAux.value = props.candidateInfo.resume?.url
          ? props.candidateInfo.resume?.url
          : null;
        otherFileAux.value = props.candidateInfo.documents?.url
          ? props.candidateInfo.documents?.url
          : null;
        currentSalary.value = props.candidateInfo.current_salary.annual;
        salaryExpectation.value = props.candidateInfo.salary_expectation.annual;
        setValues({
          first_name: props.candidateInfo.first_name,
          last_name: props.candidateInfo.last_name,
          email: props.candidateInfo.email,
          phone: props.candidateInfo.phone,
          position: props.candidateInfo.position,
          summary: props.candidateInfo.summary,
          link: props.candidateInfo.link,
          salary_currency: props.candidateInfo.salary_currency,
          current_salary_currency: props.candidateInfo.current_salary_currency,
          candidate_work_histories:
            props.candidateInfo.candidate_work_histories.length > 0
              ? props.candidateInfo.candidate_work_histories
              : [
                  {
                    start_date: null,
                    end_date: null,
                    job_title: "",
                    company_name: "",
                    industry: "",
                    is_current: false,
                  } as any,
                ],
          candidate_education_histories:
            props.candidateInfo.candidate_education_histories.length > 0
              ? props.candidateInfo.candidate_education_histories
              : [
                  {
                    start_date: null,
                    end_date: null,
                    degree: "",
                    learning_center: "",
                    is_current: false,
                  } as any,
                ],
        });
        salaryMonthly.value = props.candidateInfo.show_monthly_salaries;
        currentSalaryCurrency.value =
          props.candidateInfo.current_salary_currency;
        salaryCurrency.value = props.candidateInfo.salary_currency;

        preferred_work_types_Aux.value =
          props.candidateInfo.preferred_work_type;
        for (const [type, value] of Object.entries(
          props.candidateInfo.preferred_work_type
        )) {
          if (value === true) {
            preferred_work_types.value.push(type as never);
          }
        }
        preferred_work_types.value = preferred_work_types.value.filter(
          (item) => item
        );
      }
      switch (props.typeForm) {
        case "createCandidate":
        case "createJobCandidate":
          textButton.value = "Create candidate";
          textButtonSaving.value = "Creating candidate";

          break;
        case "editCandidate":
        case "editJobCandidate":
          textButton.value = "Update candidate";
          textButtonSaving.value = "Updating candidate";

          break;
      }
    });

    const deleteServerFile = (id: string) => {
      filesToDelete.value.push(id);
      candidateDocuments.value.splice(
        candidateDocuments.value.findIndex((el: any) => el.id === id),
        1
      );
    };

    return {
      meta,
      link,
      getFileName,
      getFileExtension,
      jobId,
      email,
      phone,
      skills,
      cvFile,
      cvFileAux,
      currentSalary,
      summary,
      cvLoaded,
      onSubmit,
      validForm,
      workTypes,
      last_name,
      otherFile,
      textButton,
      textButtonSaving,
      otherFileAux,
      isVisible,
      position,
      first_name,
      isLoadingButton,
      optionCurrencies,
      preferred_work_types,
      industries,
      salaryExpectation,
      onCancelButton,
      overrideCandidate,
      showConfirmationModal,
      confirmationModal,
      updateHandler,
      locationName,
      place_id,
      numberFormattedWithCommas,
      salaryMonthly,
      currentSalaryCurrency,
      salaryCurrency,
      files,
      candidateDocuments,
      getFileIcon,
      deleteServerFile,
      addFile,
      deleteFile,
    };
  },
});
export default AddCandidateForm;
