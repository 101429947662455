
import { defineComponent } from "vue";

export default defineComponent({
  name: "Close",
  props: {
    color: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      defualt: "6",
    },
    width: {
      type: String,
      default: "6",
    },
  },
});
