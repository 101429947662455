/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildJobs = (data: any) =>
  data &&
  data.map((obj: any) => ({
    id: obj.id,
    name: obj.title,
  }));

export const buildCompany = (data: any) =>
  data &&
  data.map((obj: any) => ({
    id: obj.company_id,
    name: obj.visible_name,
  }));
