
import { defineComponent, PropType } from "vue";
import { Talent } from "@/types/talent.interface";
import UserCard from "@/components/userCard/index.vue";
import DateFormatter from "@/components/dateFormatter/index.vue";

export default defineComponent({
  name: "Talent Card",
  components: {
    UserCard,
    DateFormatter,
  },
  props: {
    talent: {
      type: Object as PropType<Talent>,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
});
