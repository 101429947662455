import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CandidateInfo = _resolveComponent("CandidateInfo")!
  const _component_CandidateForm = _resolveComponent("CandidateForm")!
  const _component_DeleteCandidate = _resolveComponent("DeleteCandidate")!
  const _component_ReusableModal = _resolveComponent("ReusableModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.candidate.id && !_ctx.formOption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CandidateInfo, {
            userInfo: _ctx.candidate,
            onDeleteCandidate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShowDelete(true))),
            onOpenEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formOption = true)),
            onReloadCandidates: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reloadCandidate('changeState'))),
            candidateId: _ctx.candidate.id,
            key: "create",
            onReloadOne: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reloadCandidate('changeState')))
          }, null, 8, ["userInfo", "candidateId"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.formOption && _ctx.candidate.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CandidateForm, {
            typeForm: "editJobCandidate",
            jobCandidateId: _ctx.candidate.id,
            toggle: _ctx.candidate.is_visible,
            jobIdCandidate: _ctx.candidate.job_id,
            candidateInfo: _ctx.candidate.candidate,
            saveCandidate: _ctx.saveCandidate,
            onOnValidForm: _ctx.onValidForm,
            onOnError: _ctx.onError,
            onIsLoadingAction: _ctx.isLoadingAction,
            onOnShowInfo: _ctx.onShowInfo
          }, null, 8, ["jobCandidateId", "toggle", "jobIdCandidate", "candidateInfo", "saveCandidate", "onOnValidForm", "onOnError", "onIsLoadingAction", "onOnShowInfo"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ReusableModal, {
      show: _ctx.showDelete,
      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setShowDelete(false) && _ctx.candidate.id)),
      size: "w-1/3"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DeleteCandidate, {
          onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setShowDelete(false))),
          onGetCandidates: _cache[5] || (_cache[5] = ($event: any) => (_ctx.reloadCandidate('delete'))),
          candidateId: _ctx.candidate.id
        }, null, 8, ["candidateId"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}