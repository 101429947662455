<template>
  <div class="flex flex-col text-sm border-primary relative">
    <input
      :id="value"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="$emit('update:modelValue', $event.target.value)"
      class="hidden"
    />
    <label
      :for="value"
      class="flex items-center cursor-pointer flex flex-row relative"
      :class="fontSize"
    >
      <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey" />
      {{ label }}
    </label>
    <label
      v-if="hasCounter"
      class="absolute right-0 px-2 py-1 bg-gray-200 rounded-lg -mt-1 w-10 text-right"
      >{{ counter }}</label
    >
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "RadioButtonCount",
  inheritAttrs: false,
  props: {
    label: { type: String, default: "", required: true },
    modelValue: { default: "", required: true },
    value: { type: String, default: undefined, required: true },
    fontSize: { type: String, default: "text-base" },
    counter: { type: Number, default: 0 },
    hasCounter: { type: Boolean, default: false },
  },
  setup(props) {
    const isChecked = computed(() => props.modelValue === props.value);
    return {
      isChecked,
    };
  },
});
</script>

<style scoped>
input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
  background-color: #d9dbe9;
}
input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}
input[type="radio"]:checked + label span {
  background-color: white;
  box-shadow: 0px 0px 0px 4px #8908f9 inset;
}
input[type="radio"]:checked + label {
  color: black;
}
</style>
